import { get, deleteReq, patchFormData, postFormData } from "./general.services";

export const createTestimonials = async (args: any) => {
  try {
    const res = await postFormData({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "testimonials"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const editTestimonials = async (args: any) => {

  try {
    const res = await patchFormData({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `testimonials/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getTestimonials = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `testimonials${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    return [];
  }
}

export const getOneTestimonials = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `testimonials/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}

export const deleteTestimonials = async (args: any) => {

  try {
    const res = await deleteReq({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `testimonials/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}