import React, { useState, useContext, useEffect } from "react";

import * as uik from "../../../@uik";

import GeneralContext from "../../../context/general.context";
import { RouteComponentProps, withRouter } from "react-router";
import { iCustomers } from "../../../models/customers.models";
import {
  getOneCustomer,
  getCustomerRanking
} from "../../../services/customer.services";
import { iRow, iKeyRows } from "../../../models/general.model";
import Table from "../../../components/Table/Table";
import CardComparator from "../../../components/CardComparator/CardComparator";
import CardsResult from "../../../components/CardResult/CardResult";
import CardPosition from "../../../components/CardPosition/CardPosition";
import { Line } from "react-chartjs-2";
import CardInfo from "../../../components/CardInfo/CardInfo";
import {
  getDateFormat,
  addMonths,
  addWeeks,
  addYears,
  compareDate,
  addDays,
  differenceDate,
  differenceWeek,
  differenceMonth
} from "../../../utils/time";
import {
  getReportsTrafficsUsers,
  getInterestLinks
} from "../../../services/reports.services";
import {
  getRankingTraffic,
  getRankingPageViews,
  getRankingSession
} from "../../../services/reportCustom.services";
import Pagination from "../../../components/Pagination/Pagination";
import { TOTAL_ITEMS_COMPARATIVE } from "../../../constants/general";
import Select from "../../../components/Select/Select";

interface RouteParams {
  id: string;
}

interface iCustomerDetailProps extends RouteComponentProps<RouteParams> {}

const POSITION: any = {
  position: "",
  status: ""
};
const TYPES: any = {
  traffic: JSON.parse(JSON.stringify(POSITION)),
  session: JSON.parse(JSON.stringify(POSITION)),
  pageview: JSON.parse(JSON.stringify(POSITION)),
  global: JSON.parse(JSON.stringify(POSITION))
};

const CustomerDetail: React.FC<iCustomerDetailProps> = ({ match }) => {
  const [item, setItem] = useState<iCustomers>({
    username: "",
    town: {},
    profile: {},
    contact: {},
    subscription: {},
    description: "",
    history: "",
    visits: [],
    places: [],
    reportMails: [],
    multimedia: [],
    id: 0,
    updatedAt: ""
  });
  const [reloadItem, setReloadItem] = useState<boolean>(true);

  const [type, setType] = useState<any>({ label: "Global", value: "" });

  const [positions, setPositions] = useState<any>({
    ranking_town: JSON.parse(JSON.stringify(TYPES)),
    ranking_province: JSON.parse(JSON.stringify(TYPES)),
    ranking_community: JSON.parse(JSON.stringify(TYPES))
  });
  const [town, setTown] = useState<any>({
    name: "",
    positionUser: "-",
    positionTime: "-",
    positionPage: "-"
  });
  const [userTime, setUserTime] = useState<any>({ label: "Dia", value: "day" });
  const [pageTime, setPageTime] = useState<any>({ label: "Dia", value: "day" });
  const [pageVisit, setPageVisit] = useState<any>({
    label: "Dia",
    value: "day"
  });

  const [pageTraffic, setPageTraffic] = useState<number>(1);
  const [totalPageTraffic, setTotalPageTraffic] = useState<number>(1);

  const [pageSession, setPageSession] = useState<number>(1);
  const [totalPageSession, setTotalPageSession] = useState<number>(1);

  const [pageViews, setPageViews] = useState<number>(1);
  const [totalPageViews, setTotalPageViews] = useState<number>(1);

  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [users, setUsers] = useState<any[]>([]);
  const [time, setTime] = useState<any[]>([]);
  const [links, setLinks] = useState<any[]>([]);

  const [compareUsers, setCompareUsers] = useState<any>({});
  const [compareTime, setCompareTime] = useState<any>({});
  const [compareLinks, setCompareLinks] = useState<any[]>([]);
  const [compareVisits, setCompareVisits] = useState<any>([]);

  const [reloadUsers, setReloadUsers] = useState<boolean>(false);
  const [reloadTime, setReloadTime] = useState<boolean>(false);
  const [reloadLink, setReloadLink] = useState<boolean>(false);

  const general = useContext(GeneralContext);

  useEffect(() => {
    if (reloadItem && match.params.id) {
      getItem();
      getPositionsCustomer();
      setReloadItem(false);
    }
  }, [reloadItem]);

  useEffect(() => {
    if (item.id) {
      getRankingTrafficItems();
      getRankingSessionItems();
      getRankingPageViewsItems();
    }
  }, [item, type]);

  useEffect(() => {
    changeUsers();
  }, [userTime]);

  useEffect(() => {
    changeTime();
  }, [pageTime]);

  useEffect(() => {
    changeVisit();
  }, [pageVisit]);

  useEffect(() => {
    if (reloadUsers) {
      getRankingTrafficItems();
      setReloadUsers(false);
    }
    if (reloadTime) {
      getRankingSessionItems();
      setReloadTime(false);
    }
    if (reloadLink) {
      getRankingPageViewsItems();
      setReloadLink(false);
    }
  }, [reloadUsers, reloadTime, reloadLink]);

  const getItem = async () => {
    const res: any = await getOneCustomer({
      id: match.params.id,
      loader: true,
      setLoading: general.setLoading
    });

    setItem({ ...res });
  };

  const getPositionsCustomer = async () => {
    const res: any = await getCustomerRanking({
      id: match.params.id,
      loader: true,
      setLoading: general.setLoading
    });

    setPositions({ ...res });
  };

  const getRankingTrafficItems = async () => {
    const params = [`limit=${TOTAL_ITEMS_COMPARATIVE}`];
    const offset = TOTAL_ITEMS_COMPARATIVE * (pageTraffic - 1);
    params.push(`offset=${offset}`);

    if (type.value) {
      params.push(`ranking=${type.value}`);
      params.push(
        `code=${
          type.value === "comunidad"
            ? item.town.communityCode
            : item.town.provinceCode
        }`
      );
    }

    const res = await getRankingTraffic({
      params,
      loader: true,
      setLoading: general.setLoading
    });

    const users: iRow[] =
      res && res.results
        ? res.results.map((i: any, index: number) => ({
            town: {
              value: i.town,
              type: "text",
              mark: item.town.name === i.town
            },
            position: {
              value: i.count ? i.position : "-",
              type: "text",
              mark: item.town.name === i.town
            }
          }))
        : [];

    setUsers(users);
    setTotalPageTraffic(Math.ceil(res.total / TOTAL_ITEMS_COMPARATIVE) || 1);
  };

  const getRankingSessionItems = async () => {
    const params = [`limit=${TOTAL_ITEMS_COMPARATIVE}`];
    const offset = TOTAL_ITEMS_COMPARATIVE * (pageSession - 1);
    params.push(`offset=${offset}`);

    if (type.value) {
      params.push(`ranking=${type.value}`);
      params.push(
        `code=${
          type.value === "comunidad"
            ? item.town.communityCode
            : item.town.provinceCode
        }`
      );
    }

    const res = await getRankingSession({
      params,
      loader: true,
      setLoading: general.setLoading
    });

    const time: iRow[] =
      res && res.results
        ? res.results.map((i: any, index: number) => ({
            town: {
              value: i.town,
              type: "text",
              mark: item.town.name === i.town
            },
            position: {
              value: i.count ? i.position : "-",
              type: "text",
              mark: item.town.name === i.town
            }
          }))
        : [];

    setTime(time);
    setTotalPageSession(Math.ceil(res.total / TOTAL_ITEMS_COMPARATIVE) || 1);
  };

  const getRankingPageViewsItems = async () => {
    const params = [`limit=${TOTAL_ITEMS_COMPARATIVE}`];
    const offset = TOTAL_ITEMS_COMPARATIVE * (pageViews - 1);
    params.push(`offset=${offset}`);

    if (type.value) {
      params.push(`ranking=${type.value}`);
      params.push(
        `code=${
          type.value === "comunidad"
            ? item.town.communityCode
            : item.town.provinceCode
        }`
      );
    }

    const res = await getRankingPageViews({
      params,
      loader: true,
      setLoading: general.setLoading
    });

    const links: iRow[] =
      res && res.results
        ? res.results.map((i: any, index: number) => ({
            town: {
              value: i.town,
              type: "text",
              mark: item.town.name === i.town
            },
            position: {
              value: i.count ? i.position : "-",
              type: "text",
              mark: item.town.name === i.town
            }
          }))
        : [];

    setLinks(links);
    setTotalPageViews(Math.ceil(res.total / TOTAL_ITEMS_COMPARATIVE) || 1);
  };

  const changePageTraffic = (page: number) => {
    setPageTraffic(page);
    setReloadUsers(true);
  };
  const changePageSession = (page: number) => {
    setPageSession(page);
    setReloadTime(true);
  };
  const changePageViews = (page: number) => {
    setPageViews(page);
    setReloadLink(true);
  };

  const getDate = () => {
    let startDate: string = "";
    let endDate: string = "";
    endDate = getDateFormat(new Date(), "yyyy-MM-dd");
    startDate = getDateFormat(addYears(new Date(), -1), "yyyy-MM-dd");

    return { startDate, endDate };
  };

  const changeUsers = async () => {
    const dateUser = getDate();
    const paramsUsers: string[] = [
      `startDate=${dateUser.startDate}`,
      `endDate=${dateUser.endDate}`,
      `type=${userTime.value}`
    ];

    if (town.name) {
      const townsParam = `towns=${item.town.name},${town.name}`;
      paramsUsers.push(townsParam);
      const resTrafficsUsers: any = await getReportsTrafficsUsers({
        params: paramsUsers,
        loader: true,
        setLoading: general.setLoading
      });

      formatUsers(resTrafficsUsers);
    }
  };

  const changeTime = async () => {
    const datePage = getDate();
    const paramsUsers: string[] = [
      `startDate=${datePage.startDate}`,
      `endDate=${datePage.endDate}`,
      `type=${pageTime.value}`
    ];

    if (town.name) {
      const townsParam = `towns=${item.town.name},${town.name}`;
      paramsUsers.push(townsParam);
      const resTrafficsTime: any = await getReportsTrafficsUsers({
        params: paramsUsers,
        loader: true,
        setLoading: general.setLoading
      });

      formatPages(resTrafficsTime);
    }
  };

  const changeVisit = async () => {
    const datePage = getDate();
    const paramsUsers: string[] = [
      `startDate=${datePage.startDate}`,
      `endDate=${datePage.endDate}`,
      `type=${pageVisit.value}`
    ];

    if (town.name) {
      const townsParam = `towns=${item.town.name},${town.name}`;
      paramsUsers.push(townsParam);
      const resTrafficsTime: any = await getReportsTrafficsUsers({
        params: paramsUsers,
        loader: true,
        setLoading: general.setLoading
      });

      formatVisits(resTrafficsTime);
    }
  };

  const changeLinks = async (page: number) => {
    const paramsVisit: string[] = [
      `startDate=2019-12-01`,
      `endDate=${getDateFormat(new Date(), "yyyy-MM-dd")}`
    ];
    if (town.name) {
      const townsParam = `towns=${item.town.name},${town.name}`;
      paramsVisit.push(townsParam);
      paramsVisit.push(`nextPage=${(page - 1) * 8}`);
      const resInterestPages: any = await getInterestLinks({
        params: paramsVisit,
        loader: true,
        setLoading: general.setLoading
      });

      formatLinks(resInterestPages);
      setPage(page);
    }
  };

  const handleComparator = async (value: any) => {
    const dateUser = getDate();
    const datePage = getDate();

    const paramsUsers: string[] = [
      `startDate=${dateUser.startDate}`,
      `endDate=${dateUser.endDate}`,
      `type=${userTime.value}`
    ];
    const paramsPage: string[] = [
      `startDate=${datePage.startDate}`,
      `endDate=${datePage.endDate}`,
      `type=${pageTime.value}`
    ];
    const paramsVisitChart: string[] = [
      `startDate=${datePage.startDate}`,
      `endDate=${datePage.endDate}`,
      `type=${pageTime.value}`
    ];
    const paramsVisit: string[] = [
      `startDate=2019-12-01`,
      `endDate=${getDateFormat(new Date(), "yyyy-MM-dd")}`
    ];
    let resPos: any = {
      ranking_town: JSON.parse(JSON.stringify(TYPES)),
      ranking_province: JSON.parse(JSON.stringify(TYPES)),
      ranking_community: JSON.parse(JSON.stringify(TYPES))
    };

    if (value) {
      const townsParam = `towns=${item.town.name},${value.label}`;
      paramsUsers.push(townsParam);
      paramsPage.push(townsParam);
      paramsVisit.push(townsParam);

      const resTrafficsUsers: any = await getReportsTrafficsUsers({
        params: paramsUsers,
        loader: true,
        setLoading: general.setLoading
      });

      const resTimePages: any = await getReportsTrafficsUsers({
        params: paramsPage,
        loader: true,
        setLoading: general.setLoading
      });

      const resVisitPages: any = await getReportsTrafficsUsers({
        params: paramsPage,
        loader: true,
        setLoading: general.setLoading
      });

      const resInterestPages: any = await getInterestLinks({
        params: paramsVisit,
        loader: true,
        setLoading: general.setLoading
      });

      resPos = await getCustomerRanking({
        id: value.value,
        loader: true,
        setLoading: general.setLoading
      });

      const totalPageTown = 1;
      // resInterestPages[0][item.town.name].pagination.total_pages | 1;
      const totalPageTown2 = 1;
      // resInterestPages[1][value.label].pagination.total_pages | 1;

      setTotalPage(
        totalPageTown > totalPageTown2 ? totalPageTown : totalPageTown2
      );

      formatUsers(resTrafficsUsers, value.label);
      formatPages(resTimePages, value.label);
      formatVisits(resVisitPages, value.label);
      formatLinks(resInterestPages, value.label);
    }

    setTown({
      name: value.label || "",
      positionUser: resPos.ranking_town.traffic.position || "-",
      positionTime: resPos.ranking_town.session.position || "-",
      positionPage: resPos.ranking_town.pageview.position || "-"
    });
  };

  const getMonths = (dateObj: any) => {
    const monthsOfYear = [];
    let finish = new Date(dateObj.endDate);

    for (
      let init = new Date(dateObj.startDate);
      compareDate(init, finish) === -1;
      init = addMonths(init, 1)
    ) {
      monthsOfYear.push(init);
    }
    return monthsOfYear;
  };

  const addTime = (time: string, date: any, add: number) => {
    switch (time) {
      case "day":
        return addDays(new Date(date), add);
      case "week":
        return addWeeks(new Date(date), add);
      case "month":
        return addMonths(new Date(date), add);
    }
    return new Date(date);
  };

  const initData = (dateObj: any, time: string) => {
    const division = time === "day" ? 1 : time === "week" ? 7 : 30;
    const length = Math.ceil(
      (differenceDate(new Date(dateObj.endDate), new Date(dateObj.startDate)) +
        1) /
        division
    );
    let data: any[] = [];
    for (let i = 0; i < length; i++) {
      data.push({ y: 0, t: addTime(time, dateObj.startDate, i) });
    }

    return data;
  };

  const getIndex = (time: string, endDate: any, date: any) => {
    switch (time) {
      case "day":
        return differenceDate(new Date(endDate), date) + 1;
      case "week":
        return differenceWeek(new Date(endDate), date) + 1;
      case "month":
        return differenceMonth(new Date(endDate), date) + 1;
    }
    return 0;
  };

  const getData = (
    obj: any,
    data: any[],
    dateObj: any,
    time: string,
    key: string
  ) => {
    const division = time === "day" ? 1 : time === "week" ? 7 : 30;

    const length = Math.ceil(
      (differenceDate(new Date(dateObj.endDate), new Date(dateObj.startDate)) +
        1) /
        division
    );

    obj.dates.forEach((d: any) => {
      const dateSplit = d.date.split("/");
      const date = new Date(
        dateSplit[2],
        parseInt(dateSplit[1]) - 1,
        dateSplit[0]
      );
      const index: number = getIndex(time, dateObj.endDate, date);
      if (data[length - index]) {
        data[length - index].y = Math.ceil(parseFloat(d[key]));
      }
    });
    return data;
  };

  const formatUsers = (items: any, townName?: string) => {
    const dateUser = getDate();
    const towns = [item.town.name, townName || town.name];

    const monthsOfYear: any[] = getMonths(dateUser);

    let data: any[] = initData(dateUser, userTime.value);
    let data2: any[] = initData(dateUser, userTime.value);

    data = getData(items[0][towns[0]], data, dateUser, userTime.value, "users");
    data2 = getData(
      items[1][towns[1]],
      data2,
      dateUser,
      userTime.value,
      "users"
    );

    const dataLine = {
      labels: monthsOfYear,
      datasets: [
        {
          label: item.town.name,
          fill: false,
          tension: 0,
          backgroundColor: "#2CDBBA",
          borderColor: "#2CDBBA",
          borderWidth: 2,
          radius: 0,
          pointHitRadius: 5,
          data: data,
          yAxisID: "first-y-axis"
        },

        {
          label: townName ? townName : town.name,
          fill: false,
          backgroundColor: "#EEB222",
          borderColor: "#EEB222",
          borderWidth: 2,
          radius: 0,
          pointHitRadius: 5,
          data: data2,
          yAxisID: "first-y-axis"
        }
      ]
    };

    setCompareUsers(dataLine);
  };

  const formatPages = (items: any, townName?: string) => {
    const datePage = getDate();
    const towns = [item.town.name, townName || town.name];

    const monthsOfYear: any[] = getMonths(datePage);

    let data: any[] = initData(datePage, pageTime.value);
    let data2: any[] = initData(datePage, pageTime.value);

    data = getData(
      items[0][towns[0]],
      data,
      datePage,
      pageTime.value,
      "avg_session"
    );
    data2 = getData(
      items[1][towns[1]],
      data2,
      datePage,
      pageTime.value,
      "avg_session"
    );

    const dataLine = {
      labels: monthsOfYear,
      datasets: [
        {
          label: item.town.name,
          fill: false,
          tension: 0,
          backgroundColor: "#2CDBBA",
          borderColor: "#2CDBBA",
          borderWidth: 2,
          radius: 0,
          pointHitRadius: 5,
          data: data,
          yAxisID: "first-y-axis"
        },

        {
          label: townName ? townName : town.name,
          fill: false,
          tension: 0,
          backgroundColor: "#EEB222",
          borderColor: "#EEB222",
          borderWidth: 2,
          radius: 0,
          pointHitRadius: 5,
          data: data2,
          yAxisID: "first-y-axis"
        }
      ]
    };

    setCompareTime(dataLine);
  };

  const formatVisits = (items: any, townName?: string) => {
    const datePage = getDate();
    const towns = [item.town.name, townName || town.name];

    const monthsOfYear: any[] = getMonths(datePage);

    let data: any[] = initData(datePage, pageVisit.value);
    let data2: any[] = initData(datePage, pageVisit.value);

    data = getData(
      items[0][towns[0]],
      data,
      datePage,
      pageVisit.value,
      "page_views"
    );
    data2 = getData(
      items[1][towns[1]],
      data2,
      datePage,
      pageVisit.value,
      "page_views"
    );

    const dataLine = {
      labels: monthsOfYear,
      datasets: [
        {
          label: item.town.name,
          fill: false,
          tension: 0,
          backgroundColor: "#2CDBBA",
          borderColor: "#2CDBBA",
          borderWidth: 2,
          radius: 0,
          pointHitRadius: 5,
          data: data,
          yAxisID: "first-y-axis"
        },

        {
          label: townName ? townName : town.name,
          fill: false,
          tension: 0,
          backgroundColor: "#EEB222",
          borderColor: "#EEB222",
          borderWidth: 2,
          radius: 0,
          pointHitRadius: 5,
          data: data2,
          yAxisID: "first-y-axis"
        }
      ]
    };

    setCompareVisits(dataLine);
  };

  const formatLinks = (items: any, townName?: string) => {
    const towns = [item.town.name, townName || town.name];
    const tables = items.map((d: any, indexT: number) => {
      return d[towns[indexT]].events
        ? d[towns[indexT]].events.map((i: any, index: number) => ({
            name: {
              value: i.link,
              type: "text"
            },
            position: {
              value:
                // parseInt(d[towns[indexT]].pagination.next_page_token || 8) -
                // 8 +
                index + 1,
              type: "text",
              mark: item.town.name === i.name
            },
            count: {
              value: i.clicks,
              type: "text"
            }
          }))
        : [];
    });

    setCompareLinks(tables);
  };

  const getStatus = (status: string, count: number) => {
    if (status === "up" && count) {
      return true;
    } else if (status === "down" && count) {
      return false;
    }
    return undefined;
  };

  const getPosition = () => {
    switch (type.value) {
      case "comunidad":
        return "ranking_community";
      case "provincia":
        return "ranking_province";
      default:
        return "ranking_town";
    }
  };

  const columns = ["Posición", "Población"];
  const keyRows: iKeyRows[] = [
    { key: "position", column: "" },
    { key: "town", column: "" }
  ];

  const sectionTable = () => {
    const positionTraffic = positions[getPosition()].traffic || {};
    const positionSession = positions[getPosition()].session || {};
    const positionPageView = positions[getPosition()].pageview || {};
    return [
      <div className="col-4">
        <CardPosition
          up={getStatus(positionTraffic.status, positionTraffic.count)}
          date={getDateFormat(new Date(), "MMMM yyyy")}
          position={positionTraffic.count ? positionTraffic.position : "-"}
          text={"Posición"}
          section={"Usuarios"}
        />
      </div>,
      <div className="col-4">
        <CardPosition
          up={getStatus(positionSession.status, positionSession.count)}
          date={getDateFormat(new Date(), "MMMM yyyy")}
          position={positionSession.count ? positionSession.position : "-"}
          text={"Posición"}
          section={"Tiempo en página"}
        />
      </div>,
      <div className="col-4">
        <CardPosition
          up={getStatus(positionPageView.status, positionPageView.count)}
          date={getDateFormat(new Date(), "MMMM yyyy")}
          position={positionPageView.count ? positionPageView.position : "-"}
          text={"Posición"}
          section={"Páginas vistas"}
        />
      </div>,
      <div
        style={{ maxHeight: "600px", overflowY: "scroll" }}
        className="col-4"
      >
        <Table rows={users} columns={columns} keyRows={keyRows} />
        <div className="container-pagination">
          <Pagination
            page={pageTraffic}
            limit={totalPageTraffic}
            callback={(page: number) => changePageTraffic(page)}
          />
        </div>
      </div>,
      <div
        style={{ maxHeight: "600px", overflowY: "scroll" }}
        className="col-4"
      >
        <Table rows={time} columns={columns} keyRows={keyRows} />
        <div className="container-pagination">
          <Pagination
            page={pageSession}
            limit={totalPageSession}
            callback={(page: number) => changePageSession(page)}
          />
        </div>
      </div>,
      <div
        style={{ maxHeight: "600px", overflowY: "scroll" }}
        className="col-4"
      >
        <Table rows={links} columns={columns} keyRows={keyRows} />
        <div className="container-pagination">
          <Pagination
            page={pageViews}
            limit={totalPageViews}
            callback={(page: number) => changePageViews(page)}
          />
        </div>
      </div>
    ];
  };

  const optionsLine = {
    scales: {
      yAxes: [
        {
          id: "first-y-axis",
          pointDot: false,
          type: "linear",
          ticks: {
            beginAtZero: 0
            // max: 150,
            // maxTicksLimit: 4
          },
          gridLines: {
            drawBorder: false
          }
        }
      ],
      xAxes: [
        {
          type: "time",
          time: {
            displayFormats: { month: "MMM YY" },
            tooltipFormat: "DD/MM/YY",
            unit: "month"
          }
        }
      ]
    },
    ticks: {},
    legend: {
      position: "bottom",
      boxWidth: 5,
      labels: {
        usePointStyle: true,
        boxWidth: 3,
        padding: 30,
        fontSize: 14
      }
    },
    pointDot: false
  };

  const values = [
    {
      label: "Dia",
      value: "day"
    },
    {
      label: "Semana",
      value: "week"
    },
    {
      label: "Mes",
      value: "month"
    }
  ];

  const columnsLinks = ["Posición", "Usuarios", "Página"];
  const keyRowsLinks: iKeyRows[] = [
    { key: "position", column: "" },
    { key: "count", column: "" },
    { key: "name", column: "" }
  ];

  const sectionGraphics = () => [
    console.log(compareVisits),
    <div id="report-line-chart" className="col-12">
      <CardInfo
        towns={[
          {
            town: item.town ? item.town.name : "",
            position: positions.ranking_town.traffic.position
          },
          { town: town.name, position: town.positionUser }
        ]}
        headerTitle="Usuarios"
        headerTooltip={
          "Comparativa del número total de usuarios que acceden a las distintas páginas webs."
        }
        headerSelect={{
          options: {
            defaultValue: userTime,
            values
          },
          leftText: "Ver todo",
          callback: (value: any) => setUserTime({ ...value })
        }}
        expand={true}
        height="330"
      >
        <Line height={80} data={compareUsers} options={optionsLine}></Line>
      </CardInfo>
    </div>,
    <div id="report-line-chart" className="col-12">
      <CardInfo
        towns={[
          {
            town: item.town ? item.town.name : "",
            position: positions.ranking_town.session.position
          },
          { town: town.name, position: town.positionTime }
        ]}
        headerTitle="Tiempo en página"
        headerTooltip={
          "Comparativa del tiempo total que pasan los usuarios en las distintas página web."
        }
        headerSelect={{
          options: {
            defaultValue: pageTime,
            values
          },
          leftText: "Ver todo",
          callback: (value: any) => setPageTime({ ...value })
        }}
        expand={true}
        height="330"
      >
        <Line height={80} data={compareTime} options={optionsLine}></Line>
      </CardInfo>
    </div>,
    <div id="report-link" className="col-12">
      <CardInfo
        headerTitle="Link visitados"
        headerTooltip={
          "Comparativa del número de usuarios que visitan sus páginas."
        }
      >
        <div className="container">
          <div className="town-title">
            <h3>{item.town ? item.town.name : "-"}</h3>
          </div>
          <Table
            rows={compareLinks[0]}
            columns={columnsLinks}
            keyRows={keyRowsLinks}
          />
          <div className="town-title">
            <h3>{town.name}</h3>
          </div>
          <Table
            rows={compareLinks[1]}
            columns={columnsLinks}
            keyRows={keyRowsLinks}
          />
          <div className="container-pagination">
            <Pagination
              page={page}
              limit={totalPage}
              callback={(page: number) => changeLinks(page)}
            />
          </div>
        </div>
      </CardInfo>
    </div>,
    <div id="report-link-chart" className="col-12">
      <CardInfo
        towns={[
          {
            town: item.town ? item.town.name : "",
            position: positions.ranking_town.pageview.position
          },
          { town: town.name, position: town.positionPage }
        ]}
        headerTitle="Páginas vistas"
        headerTooltip={"Comparativa del número de visitas en sus páginas."}
        headerSelect={{
          options: {
            defaultValue: pageVisit,
            values
          },
          leftText: "Ver todo",
          callback: (value: any) => setPageVisit({ ...value })
        }}
        expand={true}
        height="330"
      >
        <Line height={80} data={compareVisits} options={optionsLine}></Line>
      </CardInfo>
    </div>
  ];

  const { UikLayoutMain, UikContentItem } = uik;

  const positionGlobal = positions[getPosition()].global || {};

  return (
    <UikLayoutMain className={"wrapper"}>
      <UikContentItem>
        <div className="grid">
          {!town.name && [
            <div key="col-1" className="col-9" />,
            <div key="col-2" className="col-3">
              <Select
                position="bottomRight"
                className=""
                label=""
                options={[
                  { value: "", label: "Global" },
                  { value: "comunidad", label: "Comunidad" },
                  { value: "provincia", label: "Provincia" }
                ]}
                placeholder={""}
                errorMessage={""}
                value={[type]}
                onChange={(value: any) => {
                  setType({ ...value });
                }}
              />
            </div>
          ]}
          <div className="col-8">
            <CardComparator
              title="Plan Profesional"
              text="Selecciona el municipio que deseas para realizar una comparativa respecto a la presencia digital de cada uno de ellos."
              handleClick={(value: any) => handleComparator(value)}
            />
          </div>
          <div className="col-4">
            {town.name ? (
              <CardsResult
                text={town.name ? "Te estás comparando con" : ""}
                town={town.name || "Ninguno"}
                button={town.name ? "Cerrar" : ""}
                handleButton={
                  town.name ? () => handleComparator("") : undefined
                }
              />
            ) : (
              <CardPosition
                up={getStatus(positionGlobal.status, positionGlobal.count)}
                date={getDateFormat(new Date(), "MMMM yyyy")}
                position={positionGlobal.count ? positionGlobal.position : "-"}
                text={"Posición"}
                section={"Posición general"}
              />
            )}
          </div>
          {town.name === "" ? sectionTable() : sectionGraphics()}
        </div>
      </UikContentItem>
    </UikLayoutMain>
  );
};

export default withRouter(CustomerDetail);
