import React, { useState, useContext, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router';

import GeneralContext from '../../context/general.context'

import * as uik from '../../@uik';

import TopBar from '../../components/TopBar/TopBar';
import InfoModal from '../../components/InfoModal/InfoModal';
import Form from '../../components/Form/Form';

import { getOneAboutUs, editAboutUs, createAboutUs } from '../../services/aboutUs.services';

import { iAboutUsForm } from '../../models/aboutUs.models';
import { iModal } from '../../models/general.model';
import { images } from '../../assets/images';

interface RouteParams {
  id: string
}

interface iAboutUsProps extends RouteComponentProps<RouteParams> { }

const AboutUsForm: React.FC<iAboutUsProps> = ({ history, match }) => {

  const [item, setItem] = useState<iAboutUsForm>({
    title: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      render: 'input',
      label: 'Título',
      placeholder: 'título...',
      className: 'col-12'
    },
    description: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      placeholder: 'descripción...',
      label: 'Descripción',
      render: 'ckeditor',
      className: 'col-12'
    }
  });

  const [reloadItem, setReloadItem] = useState<boolean>(true);
  const [reloadForm, setReloadForm] = useState<boolean>(true);

  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });

  const title: string = match.params.id ? 'Editar Sobre Nosotros' : 'Crear Sobre Nosotros';
  const isEdit: boolean = !!match.params.id;

  const general = useContext(GeneralContext);

  useEffect(() => {

    if (reloadItem && match.params.id) {
      getItem();
      setReloadItem(false);
    }
  }, [reloadItem]);

  const getItem = async () => {
    const res: any = await getOneAboutUs({
      id: match.params.id,
      loader: true,
      setLoading: general.setLoading,
    });

    Object.keys(item).forEach((key: string) => {
      item[key].value = res[key] || '';
    })

    setItem({ ...item });
    setReloadForm(true);
  }

  const handleAccept = async () => {
    const data: any = {
      title: item.title.value,
      description: item.description.value
    }

    try {
      if (isEdit) {
        await editAboutUs({
          data,
          loader: true,
          setLoading: general.setLoading,
          id: match.params.id,
        });
      } else {
        await createAboutUs({
          data,
          loader: true,
          setLoading: general.setLoading,
        });
      }
      history.push('/about-us');
    } catch (err) {
      setModal({
        code: (err.response && err.response.status) ? err.response.status : 'err-local',
        text: (err.response && err.response.description) ? err.response.description : 'Error inesperado, inténtelo más tarde.',
        show: true,
        button: '',
        handleAccept: closeModal,
        title: 'Error',
        alert: '',
        className: 'error',
        img: images.IconXCircle
      });
    }
  }

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: '',
      img: ''
    })
  }

  const { UikTopBarSection, UikTopBarTitle, UikContainerVertical,
    UikLayoutMain, UikWidgetContainer } = uik;

  return (
    <UikContainerVertical className={"page"}>
      <TopBar>
        <UikTopBarSection>
          <UikTopBarTitle>
            <img
              src={images.IconArrowLeftGrey} className="img-back"
              alt="back"
              onClick={() => history.push('/about-us')}
            />
            {title}
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <UikWidgetContainer>
          <Form
            titleForm={title}
            buttonText="Guardar"
            handleAccept={handleAccept}
            dataForm={item}
            reloadForm={reloadForm}
            setReloadForm={setReloadForm}
          // handleBack={() => history.push('/about-us')}
          />

        </UikWidgetContainer>
      </UikLayoutMain>
      <InfoModal
        className={modal.className}
        onClickAccept={modal.handleAccept}
        mainText={modal.text}
        titleText={modal.title}
        alertText={modal.alert}
        buttonText={modal.button}
        active={modal.show}
        img={modal.img}
        close={() => closeModal()}
      />
    </UikContainerVertical>
  );
}

export default withRouter(AboutUsForm);

