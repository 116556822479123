import React, { useState, useContext, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router';
import ReactTooltip from 'react-tooltip';

import { editBills, getOneBillsDownload } from '../../../services/bills.services';

import GeneralContext from '../../../context/general.context'

import Table from '../../../components/Table/Table';
import InfoModal from '../../../components/InfoModal/InfoModal';

import * as uik from '../../../@uik';

import { iModal, iKeyRows } from '../../../models/general.model';
import { iBills } from '../../../models/bills.models';

import Cards from '../../../components/Card/Card';
import { iCustomers } from '../../../models/customers.models';
import { getOneCustomer, getCustomersInvoices } from '../../../services/customer.services';
import { images } from '../../../assets/images';
import { currencyFormat, textWithPoints } from '../../../utils/text';
import { TOTAL_ITEMS } from '../../../constants/general';
import Pagination from '../../../components/Pagination/Pagination';

interface RouteParams {
  id: string
}

export interface iCustomerBillsProps extends RouteComponentProps<RouteParams> { }

const CustomerDetailBills: React.FC<iCustomerBillsProps> = ({ match, history }) => {

  const [items, setItems] = useState<iBills[]>([]);
  const [item, setItem] = useState<iCustomers>({
    username: '',
    town: {},
    profile: {},
    contact: {},
    subscription: {},
    description: '',
    history: '',
    visits: [],
    places: [],
    reportMails: [],
    multimedia: [],
    id: 0,
    updatedAt: ''
  });

  const [reloadItems, setReloadItems] = useState<boolean>(true);
  const [order, setOrder] = useState<any>({ order: '', key: '' });
  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const general = useContext(GeneralContext);


  useEffect(() => {
    if (reloadItems && match.params.id) {
      getItem();

      setReloadItems(false);
    }
  }, [reloadItems]);

  const getItem = async () => {
    const res: any = await getOneCustomer({
      id: match.params.id,
      loader: true,
      setLoading: general.setLoading,
    });

    getItems();
    setItem({ ...res });
  }

  const getItems = async () => {

    const params = [`limit=${TOTAL_ITEMS}`];
    const offset = TOTAL_ITEMS * (page - 1);
    params.push(`offset=${offset}`);

    if (order.key !== '' && order.order) {
      params.push(`orderBy=${order.key}`);
      params.push(`order=${order.order}`);
    } else {
      params.push(`orderBy=invoice.updated_at`);
      params.push(`order=desc`);
    }
    const res = await getCustomersInvoices({
      params,
      id: match.params.id,
      loader: true,
      setLoading: general.setLoading
    });

    setItems(res.results);
    setTotalPage(Math.ceil(res.total / TOTAL_ITEMS) || 1);
  }

  const handleOrder = (order: any) => {
    setOrder({ ...order });
    setReloadItems(true);
  }

  const handleStatus = async (item: any, state: string) => {
    try {
      const data = {
        status: state
      }

      await editBills({
        data,
        id: item.id,
        loader: true,
        setLoading: general.setLoading
      });
      closeModal();
      setModal({
        code: 'success',
        button: '',
        handleAccept: () => { },
        text: 'Se ha cambiado  correctamente de estado.',
        show: true,
        title: 'Editar estado',
        alert: '',
        className: '',
        img: images.IconCheckCircle
      });
      setReloadItems(true);
    } catch (err) {
      setModal({
        code: 'error',
        button: '',
        handleAccept: () => { },
        alert: 'Error inesperado.',
        show: true,
        title: 'Cambiar estado factura',
        text: 'Intente cambiar estado de la factura más tarde.',
        className: 'error',
        img: images.IconXCircle
      });
    }
  }

  const handleDownloadItem = async (item: any) => {
    try {
      const res = await getOneBillsDownload({
        id: encodeURIComponent(item.link),
        loader: true,
        setLoading: general.setLoading
      });

      const dlnk: any = document.getElementById('downloadPDF');
      dlnk.href = `data:application/pdf;base64,${res.file}`;
      dlnk.download = `${item.customerUser}-factura.pdf`;//'facturas.pdf'; //file.name
      dlnk.click();
      closeModal();

    } catch (err) {
      setModal({
        code: 'error',
        button: '',
        handleAccept: () => { },
        alert: 'Error inesperado.',
        show: true,
        title: 'Descargar factura',
        text: 'Intente descargar la factura más tarde.',
        className: 'error',
        img: images.IconXCircle
      });
    }
  }

  const getIcon = (item: any) => {
    const icon = [
      <ReactTooltip id={`state-${item.id}`} place="left" type="dark" effect="float">
        <span>{item.status}</span>
      </ReactTooltip>
    ];
    switch (item.status) {
      case 'pagada':
        icon.push(
          <div data-tip data-for={`state-${item.id}`} className="icon-bad-billing">
            <img src={images.IconCheckGreen} alt="payed" />
          </div>
        )
        break;
      case 'cancelada':
        icon.push(
          <div data-tip data-for={`state-${item.id}`} className="icon-bad-billing">
            <img src={images.IconCross} alt="cancel" />
          </div>);
        break;
      case 'pendiente':
        icon.push(
          <div data-tip data-for={`state-${item.id}`} className="icon-bad-billing">
            <img src={images.IconExclamation} alt="pending" />
          </div>
        );
        break;
      case 'devuelta':
        icon.push(
          <div data-tip data-for={`state-${item.id}`} className="icon-bad-billing">
            <img src={images.IconMoney} alt="abonada" />
          </div>
        );
        break;
    }
    return icon;
  }

  const getRows = () => {
    return !items ? [] : items.map((item: any) => {

      const buttons = [{
        label: "Descargar",
        icon: <li className="oi icon-download"></li>,
        onClick: () => {
          setModal({
            code: item.id,
            button: 'Aceptar',
            handleAccept: () => handleDownloadItem(item),
            text: 'Se va a descargar la factura seleccionada',
            show: true,
            title: 'Descargar factura',
            alert: '',
            className: 'success',
            img: ''
          });
        }
      },
      {
        label: "Editar",
        icon: <img src={images.IconEdit} />,
        onClick: () => {
          history.push(`/billing/edit/${item.id}`);;
        }
      }
      ];

      if (item.status !== 'pendiente') {
        buttons.push({
          label: "Pendiente",
          icon: (
            <div className="icon-bad-billing">
              <img src={images.IconExclamation} alt="cross" />
            </div>
          ),
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Pendiente',
              handleAccept: () => handleStatus(item, 'pendiente'),
              text: 'Se cambiará el estado a pendiente',
              show: true,
              title: 'Cambiar a Pendiente',
              alert: '',
              className: 'success',
              img: ''
            });
          },
        });
      }
      if (item.status !== 'cancelada') {
        buttons.push({
          label: "Cancelar",
          icon: <img src={images.IconDelete} />,
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Cancelar',
              handleAccept: () => handleStatus(item, 'cancelada'),
              text: 'Se cambiará el estado a cancelado',
              show: true,
              title: 'Cancelar factura',
              alert: '',
              className: 'error',
              img: ''
            });
          },
        });
      }
      if (item.status !== 'pagada') {
        buttons.push({
          label: "Pagar",
          icon: <img src={images.IconBillingBlack} />,
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Pagar',
              handleAccept: () => handleStatus(item, 'pagada'),
              text: 'Se va a pagar la factura seleccionada',
              show: true,
              title: 'Pagar factura',
              alert: '',
              className: 'success',
              img: ''
            });
          }
        });
      }
      if (item.status !== 'devuelta') {
        buttons.push({
          label: "Abonar",
          icon: <div className="icon-bad-billing">
            <img src={images.IconMoney} alt="cross" />
          </div>,
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Abonar',
              handleAccept: () => handleStatus(item, 'devuelta'),
              text: 'Se va a abonar la factura seleccionada',
              show: true,
              title: 'Abonar factura',
              alert: '',
              className: 'success',
              img: ''
            });
          }
        });
      }
      return ({
        state: {
          value: getIcon(item),
          type: 'icon'
        },
        date: {
          value: item.date,
          type: 'text'
        },
        description: {
          value: textWithPoints(item.description, 30),
          type: 'text'
        },
        amount: {
          value: `${currencyFormat(item.amount)} €`,
          type: 'text'
        },
        buttons: {
          value: 'botones',
          actions: buttons,
          type: 'buttons'
        }
      });
    }
    )
  }

  const changePage = (page: number) => {
    setPage(page);
    setReloadItems(true);
  }

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: '',
      img: ''
    })
  }

  const { UikLayoutMain, UikWidgetContent, UikWidget } = uik;

  const columns = ['Estado', 'Fecha', 'Descripción', 'Importe', 'Acciones'];

  const keyRows: iKeyRows[] = [{ key: 'state', column: 'invoice.status' }, { key: 'date', column: 'invoice.createdAt' },
  { key: 'description', column: 'description' }, { key: 'amount', column: 'invoice.amount' }, { key: 'buttons', column: '' }];

  return (
    <>
      <div className="display-content">
        <div className="flex-container set-padding">
          <Cards
            width={"33.33%"}
            title={item.subscription ? item.subscription.name : 'DEMO'}
            texts={[item.subscriptionFrequency ? (item.subscriptionFrequency === 'mensual' ? 'Mensual' : 'Anual') : '-']}
            textLeft="Plan"
            textRight="Editar"
            handleClick={() => history.push(`/customers/edit/${item.id}`)}
          />
          <Cards
            width={"33.33%"}
            title={item.contact ? item.contact.name : '-'}
            texts={item.contact ? [item.contact.credential, item.contact.phoneNumber] : ['-']}
            textLeft="Contacto"
            textRight="Editar"
            handleClick={() => history.push(`/customers/edit/${item.id}`)}
          />
          <Cards
            width={"33.33%"}
            title={item.username || '-'}
            texts={["email"]}
            textLeft="Cuenta"
            textRight="Editar"
            handleClick={() => history.push(`/customers/edit/${item.id}`)}
          />
        </div>

      </div>
      <UikLayoutMain className={"wrapper"}>
        <UikWidget margin>
          <UikWidgetContent>
            <h2>Historial de pagos</h2>
            <Table
              rows={getRows()}
              columns={columns}
              keyRows={keyRows}
              handleOrder={(order: any) => handleOrder(order)}
            />
            <div className="container-pagination">
              <Pagination page={page} limit={totalPage} callback={(page: number) => changePage(page)} />
            </div>
          </UikWidgetContent>
        </UikWidget>
      </UikLayoutMain>
      <InfoModal
        className={modal.className}
        titleText={modal.title}
        alertText={modal.alert}
        mainText={modal.text}
        buttonText={modal.button}
        active={modal.show}
        onClickAccept={() => modal.handleAccept()}
        close={() => closeModal()}
        img={modal.img}
      />
      <a id='downloadPDF' style={{ display: "none" }} />
    </>
  );
}




export default withRouter(CustomerDetailBills);

