import React from 'react';

import { CardContainer, DataContainer } from './card-rate-style';

import * as uik from '../../@uik';
import { currencyFormat } from '../../utils/text';
import IconComponent from '../Icon/Icon';
import { images } from '../../assets/images';
interface iCardsProps {
  title: string;
  text: string;
  type: string;
  prices: any[];
  active: boolean;
  data: any[];
  handleClick?: Function;
}

const CardRate: React.FC<iCardsProps> = ({ title, text, prices, active, handleClick, data, type }) => {
  const { UikButton, UikDivider } = uik;

  return (
    <>
      <CardContainer>
        <div className="card-header">
          <h3>{title}</h3>
        </div>
        <div className="card-divider">
          <UikDivider />
        </div>
        <div className="card-body">
          <div className="card-body-text">
            <p>{text}</p>
          </div>
          <div className="card-body-price">
            {prices.map((price: any) => <p>{currencyFormat(price ? price : 0)} €/{type}</p>)}
          </div>
          <div className="card-button">
            <UikButton success={active} onClick={() => handleClick && handleClick()}>
              {active ? 'Plan activo' : 'Editar'}
            </UikButton>
          </div>
        </div>
      </CardContainer>
      <DataContainer>
        {data.map((item: any) => (
          <div key={item.value} className={`container-data ${item.active ? 'active' : ''}`}>

            <p>
              {item.active && (
                <img src={images.IconCheckGreen} />
              )}
              {item.value}
            </p>
          </div>
        ))}
      </DataContainer>
    </>
  );
}

export default CardRate;
