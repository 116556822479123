import React, { useState, useContext, SyntheticEvent } from 'react'

import * as uik from '../../@uik';

import { iDataForgot } from '../../models/login.model';
import { isValidEmail } from '../../utils/validations';
import { forgotPassword } from '../../services/login.services';

import GeneralContext from '../../context/general.context'
import { ERROR_MESSAGES } from '../../constants/errorMessages';
import { withRouter, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import InfoModal from '../../components/InfoModal/InfoModal';
import IconComponent from '../../components/Icon/Icon';

export interface iForgotPasswordProps extends RouteComponentProps { }

const ForgotPassword: React.FC<iForgotPasswordProps> = ({ history }) => {

  const [data, setData] = useState<iDataForgot>({
    username: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'email'
    },
  });
  const [modal, setModal] = useState<boolean>(false);

  const general = useContext(GeneralContext)


  const validateForm = (): boolean => {
    let isOk = true;

    Object.keys(data).forEach((key: string) => {
      if (data[key].required && data[key].value === '') {
        data[key].error = true;
        data[key].errorCode = 'required';
        isOk = false;
      } else if (data[key].type === 'email' && !isValidEmail(data[key].value)) {
        data[key].errorCode = 'invalid-email';
        data[key].error = true;
        isOk = false;
      } else {
        data[key].errorCode = '';
        data[key].error = false;
      }
    })

    setData({ ...data });
    return isOk;
  }

  const handleForgotPassword = async () => {
    const isOk = validateForm();
    if (isOk) {
      const dataSend = {
        username: data.username.value,
        type: "staff"
      }
      try {
        const res = await forgotPassword({
          loader: true,
          setLoading: general.setLoading,
          data: dataSend,
        })
        setModal(true);
        //MODAL?
      } catch (err) {
        data.username.error = true;
        data.username.errorCode = 'login-error';
        setData({ ...data });
      }
    }
  }

  const handleChange = (e: SyntheticEvent) => {

    const target = e.target as HTMLInputElement;
    data[target.name].value = target.value;
    setData({ ...data });
  }

  const { UikWidgetContent,
    UikWidget,
    UikInput,
    UikFormInputGroup,
    UikHeadline,
    UikHeadlineDesc,
    UikDivider,
    UikButton
  } = uik;

  return (
    <div className="pageWrapper">
      <UikWidget
        className="widgetWrapper"
      >
        <div className="content">
          <UikWidgetContent className="left">
            <UikHeadline>
              ¿Olvidaste la contraseña?
          </UikHeadline>
            <UikHeadlineDesc>
              Indica tu cuenta para que te enviemos un correo para modificar tu contraseña
          </UikHeadlineDesc>
            <form className="login" onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
              <UikFormInputGroup>
                <UikInput
                  label="Correo"
                  name="username"
                  placeholder="your@email.com"
                  errorMessage={ERROR_MESSAGES[data.username.errorCode]}
                  value={data.username.value}
                  onChange={handleChange}
                />
              </UikFormInputGroup>
              <UikDivider margin />
              <UikButton
                className="btnAction"
                type="submit"
                // Component={NavLink}
                success
                onClick={handleForgotPassword}
              // to="/buildings"
              >
                Enviar
          </UikButton>
            </form>
            <div className="login-footer">
              <NavLink to="/">
                Iniciar sesión
            </NavLink>
            </div>
          </UikWidgetContent>
          <div className="right">
            <h3 className="headline">
              <IconComponent name="IconGlobalData" className={"svg-none"} />
              Dashboard
          </h3>
            <p className="desc">
              Super Admin España Fascinante
          </p>
          </div>
        </div>
      </UikWidget>
      <p>
        Panel Administrador
    </p>

      <InfoModal
        className={"success"}
        titleText={"Correo enviado"}
        // alertText={"Esta acción no se puede deshacer."}
        mainText={"Se ha enviado un email a tu correo."}
        active={modal}
        close={() => setModal(false)}
      />
    </div>
  )
}

export default withRouter(ForgotPassword);

