import styled, { css } from "styled-components";
import arrow from "../../assets/images/arrow.svg";

export const ThContainer = styled.th`
  
    text-transform: uppercase;
    padding: 18px 16px;
    
    &.pointer {
      cursor: pointer;
    }

    &.filter {
        padding-right: 30px;
        position: relative;
        &::after{
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          background: url(${arrow}) no-repeat center;
          transition: 0.3s all ease-in-out; 
      }

      &.active {
        &::after {
          transform: translateY(-50%) rotate(-180deg);
        }
      
      }
    }
`;

export const TdContainer = styled.td`

  &.mark {
    background: #D7EDEE;
    color: #248489; 
    }
  
    a {
      color: #248489;
    }

    .pointer {
      cursor: pointer;
    }

  .active {
    background: #A1CDCE;
    border: 2px solid #248489;
    border-radius: 100%;
    width: 12px;
    height: 12px;
  }

  .inactive {
    background: #CEA1A1;
    border: 2px solid #DE4B4B;
    border-radius: 100%;
    width: 12px;
    height: 12px;
  }

  .pending {
    background: #FFFF9E;
    border: 2px solid #DED34B;
    border-radius: 100%;
    width: 12px;
    height: 12px;
  }
`;

export const TheadContainer = styled.thead<{ colorHead: string | undefined}>`

${(props: any) =>
    props.colorHead &&
    css`
      background-color: ${props.colorHead};
    `};
`;

export const DivText = styled.div`
  .mark {
    background: #D7EDEE;
    color: #248489; 
  }
`;
