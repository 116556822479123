import React, { Component } from "react";
import { PaginationContainer } from "./pagination-style";
import { images } from "../../assets/images";
import { numberFormat } from "../../utils/text";

interface PaginationState {
  page: any;
  previous: string;
  next: string;
}

interface PaginationProps {
  page: number;
  callback: (page: number) => void;
  limit: number;
}

class Pagination extends Component<PaginationProps, PaginationState> {
  state: PaginationState = {
    page: 1,
    previous: "",
    next: "",
  };

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    this.setState({ previous: this.props.page === 1 ? "limit" : "", page: this.props.page });
  };

  componentDidUpdate(prevProps: PaginationProps) {
    if (prevProps.page !== this.props.page && this.props.page !== this.state.page) {
      this.setState({ previous: this.props.page === 1 ? "limit" : "", page: this.props.page });
    }
  }

  previous = (skip: number = 1, first: boolean = false) => {
    if (this.state.page !== 1) {
      let auxPage: number = first ? 2 : this.state.page;
      let previous;
      previous = auxPage === 2 ? "limit" : "";
      this.setState({ page: auxPage - 1, next: "", previous });
      this.props.callback(auxPage - 1);
    }
  };

  next = (skip: number = 1, last: boolean = false) => {
    if (this.state.page !== Number(this.props.limit)) {
      let auxPage = last ? this.props.limit - 1 : parseFloat(this.state.page);
      let next;
      next = auxPage === Number(this.props.limit) ? "limit" : "";
      this.setState({ page: auxPage + 1, previous: "", next });
      this.props.callback(auxPage + 1);
    }
  };

  handleKeyDown = (e: any) => {
    const { page } = this.state;
    if (e.key === 'Enter') {
      this.props.callback(page);
    }
  }

  handleBlur = (e: any) => {
    const { page } = this.props;
    this.setState({ page });
  }

  handleChange = (e: any) => {
    const { limit } = this.props;
    if (/^\d+$/.test(e.target.value) || e.target.value === '') {
      this.setState({ page: e.target.value > limit ? limit : e.target.value })
    }
  }

  getWidth = () => {
    const { limit } = this.props;
    switch (limit.toString().length) {
      case 1:
        return '32px';
      case 2:
        return '40px';
      case 3:
        return '48px';
      case 4:
        return '56px';
      default:
        return '80px';
    }
  }

  render() {
    const limit = this.props.limit === 0 ? 1 : this.props.limit;
    const { previous, page } = this.state;

    return (
      <PaginationContainer>
        <div className="pagination-container">

          <div className="pagination-left">
            <span onClick={() => this.previous(1)} className={previous}>
              <img alt="previous" src={images.IconArrowLeft} />
            </span>
          </div>

          <div className="pagination-main">
            <div className='page-text'>
              <input
                style={{ 'width': this.getWidth() }}
                value={page}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                onBlur={this.handleBlur}
              />
            </div>
            <div className='divider-line'>
              <p>/</p>
            </div>
            <div className='limit-text'>
              <p>{numberFormat(limit)}</p>
            </div>
          </div>

          <div className="pagination-right">
            <span onClick={() => this.next(1)} className={Number(limit) === Number(page) ? "limit" : ""}>
              <img alt="next" src={images.IconArrowRight} />
            </span>
          </div>

        </div>
      </PaginationContainer >
    );
  }
}

export default Pagination;
