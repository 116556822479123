import styled from 'styled-components';

const MultifileUploadContainer = styled.div`
  position: relative;


  .filepond--list {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .filepond--item {
    width: calc(25% - .5em);
  }

  .filepond--root .filepond--drop-label {
    display: flex;
    position: absolute;
    min-height: 250px;
  }

  .multifile-label-text {
    font-size: 14px;
    
    .black-text {
      color: black;
      font-weight: bold;
    }

    .red-text {
      color: red;
    }


  }

  @media screen and (max-width: 1450px) {
    .filepond--item {
      width: calc(50% - .5em);
    }
  }

  @media screen and (max-width: 1250px) {
    .filepond--item {
      width: calc(100% - .5em);
    }    
  }

`

export default MultifileUploadContainer;