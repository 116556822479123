import styled from "styled-components";

export const NotFoundStyle = styled.div`
  background: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 40px;
  .error {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    &__image {
      width: 60%;
      transform: translateX(-60px);
      img {
        width: 100%;
        height: auto;
      }
    }

    &__info {
      width: 40%;
      max-width: 700px;
      padding: 120px 0px 0px 100px;

      &__title {
        margin-bottom: 20px;
        max-width: 600px;
        p {

        font-weight: 600;
        font-size: 102px;
        line-height: 128px;
        color: #525252;
        }
      }
      &__text {
        margin-bottom: 70px;
        max-width: 500px;
        p {

        font-size: 20px;
        line-height: 28px;
        color: #525252;
        }
      }
      &__button {
        width: 200px;
      }
    }
  }

  @media (max-width: 1024px) {
    .error {
      &__image {
        display: none;
      }
      &__info {
        width: 100%;
        padding: 100px 0px;
      }
    }
  }

  @media (max-width: 780px) {
    .error {
      flex-direction: column;
      align-items: center;

      &__info {
        padding: 80px;
      }
    }
  }

  @media (max-width: 600px) {
    height: auto;
    .error {
      display: block;

      &__info {
        padding: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateX(0px);

        &__title {
          font-size: 60px;
        }

        &__text {
          text-align: center;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
`;
