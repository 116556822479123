import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router";

import GeneralContext from "../../../../context/general.context";

//types
import { iInterestsReportsProps } from "./Interests-type";

// Components
import CardInfoTown from "../../../../components/CardInfoTown/CardInfoTown";
import Table from "../../../../components/Table/Table";
import { iRow } from "../../../../models/general.model";
import { Pie } from "react-chartjs-2";
import { TOOLTIPS_TEXT, ANALYTICS_TOKEN, CHANNELS_TYPES_CHART } from "../../../../constants/general";
import {
  getInterestChannels,
  getInterestCategories,
  getReportsInterestPages,
  getInterestSegmentsViews,
} from "../../../../services/reports.services";
import { numberFormat } from "../../../../utils/text";
import Pagination from "../../../../components/Pagination/Pagination";
import { formatDateToHHMMSS } from "../../../../utils/time";

// Styles
import InterestsReportsContainer from "./Interests-style";

// External library (Look how to inyect)
import * as uik from "../../../../@uik";
import moment from "moment";

const InterestsReports: React.FC<iInterestsReportsProps> = ({ match }) => {
  const general = useContext(GeneralContext);

  ////////////////////////////////////////////////////////////////
  ////////////////////// TO SHOW LOADER //////////////////////////
  ////////////////////////////////////////////////////////////////
  const [chartWasLoaded1, setChartWasLoaded1] = useState(false);
  const [chartWasLoaded2, setChartWasLoaded2] = useState(false);
  const [chartWasLoaded3, setChartWasLoaded3] = useState(false);
  const [chartWasLoaded4, setChartWasLoaded4] = useState(false);

  useEffect(() => {
    general.setLoading(true);
  }, []);

  useEffect(() => {
    if (chartWasLoaded1 && chartWasLoaded2 && chartWasLoaded3 && chartWasLoaded4) general.setLoading(false);
  }, [chartWasLoaded1, chartWasLoaded2, chartWasLoaded3, chartWasLoaded4]);
  ////////////////////////////////////////////////////////////////
  //////////////////// END TO SHOW LOADER ////////////////////////
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  //////////////////////// CHANNELS CHART ////////////////////////
  ////////////////////////////////////////////////////////////////
  interface IAgeGenderChartDataChannel {
    channel: string;
    count: string;
    percentage: string;
  }

  interface IAgeGenderChartData {
    channels: IAgeGenderChartDataChannel[];
  }

  interface IChannelsDataChartObject {
    [key: string]: IAgeGenderChartData;
  }

  interface IChannelsDataChart {
    [key: number]: IChannelsDataChartObject;
  }

  const initChannelsDataDefaultValue = (): IChannelsDataChart => {
    return [
      {
        [decodeURI(match.params.code)]: {
          channels: [],
        },
      },
    ];
  };

  const [channelsDataChart, setChannelsDataChart] = useState<IChannelsDataChart>(initChannelsDataDefaultValue());

  const formatAgeChartData = (data: IChannelsDataChart): [string[], number[]] => {
    let formatedData: [string[], number[]] = [[], []];
    if (!data[0]) {
      return [[], []];
    }

    if (data[0][decodeURI(match.params.code)])
      data[0][decodeURI(match.params.code)].channels.forEach((value: IAgeGenderChartDataChannel, i: number) => {
        formatedData[0][i] = CHANNELS_TYPES_CHART[value.channel as string];
        formatedData[1][i] = Number(value.count);
      });

    return formatedData;
  };

  const dataPie = () => {
    const formatedDataPie = formatAgeChartData(channelsDataChart);

    return {
      labels: formatedDataPie[0],
      datasets: [
        {
          data: formatedDataPie[1],
          backgroundColor: ["#2CDBBA", "#EEB222", "#B79713", "#868686", "#D8DCE6", "#947cb0"],
          hoverBackgroundColor: ["#2CDBBA", "#EEB222", "#B79713", "#868686", "#D8DCE6", "#947cb0"],
        },
      ],
    };
  };

  const optionsPie = {
    scales: {
      yAxes: [
        {
          angleLines: {
            display: false,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          angleLines: {
            display: false,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: true, //LO demas de abajo no sirve cuando esto esta en false
      position: "bottom",
      boxWidth: 5,
      labels: {
        usePointStyle: true,
        boxWidth: 6,
        padding: 10,
        fontSize: 12,
      },
    },

    tooltips: {
      label: "reportsInterestChannelsChart",
      callbacks: {
        label: function(tooltipItem: any, data: any) {
          return data.labels[tooltipItem.index] + ": " + numberFormat(data.datasets[0].data[tooltipItem.index]);
        },
      },
    },

    pointDot: false,
  };

  //Load chart data
  const loadChannelsChartData = async () => {
    const params = [
      "towns=" + decodeURI(match.params.code),
      "startDate=2019-11-01",
      "endDate=" + moment().format("YYYY-MM-DD"),
    ];

    const res = await getInterestChannels({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: () => {},
      params: params,
    });

    setChannelsDataChart(res);
    setChartWasLoaded1(true);
  };

  useEffect(() => {
    if (decodeURI(match.params.code)) loadChannelsChartData();
  }, []);
  ////////////////////////////////////////////////////////////////
  ////////////////////// END GENDER CHART ////////////////////////
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  //////////////////////// CATEGORY TABLE ////////////////////////
  ////////////////////////////////////////////////////////////////
  interface ICategoriesChartDataSpec {
    count: string;
    percentage: string;
  }

  interface ICategoriesChartData {
    avg_sesion: ICategoriesChartDataSpec;
    category: string;
    sessions: ICategoriesChartDataSpec;
    users: ICategoriesChartDataSpec;
  }

  interface ICategoriesChartPagination {
    next_page_token: string;
    total: number;
    total_pages: number;
  }

  interface ICategoriesChartResp {
    data: ICategoriesChartData[];
    pagination: ICategoriesChartPagination;
  }

  interface ICategoriesDataChartObject {
    [key: string]: ICategoriesChartResp;
  }

  interface ICategoriesDataChart {
    [key: number]: ICategoriesDataChartObject;
  }

  const initCategoriesDataDefaultValue = (): ICategoriesDataChart => {
    return [
      {
        [decodeURI(match.params.code)]: {
          data: [],
          pagination: {
            next_page_token: "0",
            total: 1,
            total_pages: 1,
          },
        },
      },
    ];
  };

  const [categoriesDataChart, setCategoriesDataChart] = useState<ICategoriesDataChart>(
    initCategoriesDataDefaultValue(),
  );

  // for pagination
  const [pageCategoriesTableData, setPageCategoriesTableData] = useState<number>(1);
  const [totalPageCategoriesTableData, setTotalPageCategoriesTableData] = useState<number>(1);
  const [reloadItemsCategoriesTable, setReloadItemsCategoriesTable] = useState<boolean>(true);

  const changePageCategoriesTable = (page: number) => {
    setPageCategoriesTableData(page);
    setReloadItemsCategoriesTable(true);
  };

  const getCategoriesRows = (): iRow[] => {
    if (!categoriesDataChart[0]) return [];
    const currentTownData = categoriesDataChart[0][decodeURI(match.params.code)];
    let categoriesRow: iRow[] = [];

    if (currentTownData) {
      currentTownData.data.map((value: ICategoriesChartData) => {
        categoriesRow.push({
          category: {
            value: value.category,
            type: "text",
          },
          users: {
            value: numberFormat(Number(value.users.count)),
            type: "text",
          },
          sessions: {
            value: numberFormat(Number(value.sessions.count)),
            type: "text",
          },
          time: {
            value: formatDateToHHMMSS(value.avg_sesion.count),
            type: "text",
          },
        });
      });
    }
    return categoriesRow;
  };

  const columnsInfo = ["CATEGORÍA DE AFINIDAD", "USUARIOS", "SESIONES", "Duración media de la sesión"];

  const keyRowsInfo = [
    { key: "category", column: "" },
    { key: "users", column: "" },
    { key: "sessions", column: "" },
    { key: "time", column: "" },
  ];

  //Load chart data
  const loadCategoriesChartData = async () => {
    let nexTokenNumber = (pageCategoriesTableData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

    const params = [
      "towns=" + decodeURI(match.params.code),
      "startDate=2019-11-01",
      "endDate=" + moment().format("YYYY-MM-DD"),
      "nextPage=" + nexTokenNumber,
    ];

    const res = await getInterestCategories({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: () => {},
      params: params,
    });
    if (res.length !== 0) {
      setCategoriesDataChart(res);
    }
    if (res[0]) {
      setTotalPageCategoriesTableData(res[0][decodeURI(match.params.code)].pagination.total_pages);
    }
    setChartWasLoaded2(true);
  };

  useEffect(() => {
    if (decodeURI(match.params.code) && reloadItemsCategoriesTable) {
      loadCategoriesChartData();
      setReloadItemsCategoriesTable(false);
    }
  }, [reloadItemsCategoriesTable]);

  useEffect(() => {
    getCategoriesRows();
  }, [
    // For page view data loading
    categoriesDataChart,
  ]);
  ////////////////////////////////////////////////////////////////
  ///////////////////// END CATEGORY TABLE ///////////////////////
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  ///////////////////// PAGE SEARCH TABLE ////////////////////////
  ////////////////////////////////////////////////////////////////
  interface IPageViewsData {
    path: string;
    views: number;
    view_percentage: number;
  }

  interface IPageViewsPagination {
    next_page_token: string;
    total: number;
    total_pages: number;
  }

  interface IPageViewsResp {
    data: IPageViewsData[];
    pagination: IPageViewsPagination;
  }

  interface IPageViewsTableObject {
    [key: string]: IPageViewsResp;
  }

  interface IPageViewsTable {
    [key: number]: IPageViewsTableObject;
  }

  const initPageViewDefaultValue = (): IPageViewsTable => {
    return [
      {
        [decodeURI(match.params.code)]: {
          data: [],
          pagination: {
            next_page_token: "0",
            total: 1,
            total_pages: 1,
          },
        },
      },
    ];
  };

  const [pageViewsData, setPageViewsData] = useState<IPageViewsTable>(initPageViewDefaultValue());

  // for pagination
  const [pagePageViewsData, setPagePageViewsData] = useState<number>(1);
  const [totalPagePageViewsData, setTotalPagePageViewsData] = useState<number>(1);
  const [reloadItemsPageViews, setReloadItemsPageViews] = useState<boolean>(true);

  const changePagePageViews = (page: number) => {
    setPagePageViewsData(page);
    setReloadItemsPageViews(true);
  };

  const getPageViewsRows = (): iRow[] => {
    if (!pageViewsData[0]) return [];
    const currentTownData = pageViewsData[0][decodeURI(match.params.code)];
    let pageViewsRows: iRow[] = [];

    if (currentTownData) {
      currentTownData.data.map((value: IPageViewsData) => {
        pageViewsRows.push({
          page: {
            value: value.path,
            type: "text",
          },
          views: {
            value: numberFormat(Number(value.views)),
            type: "text",
            extraClass: "line-td text",
          },
          percentage: {
            value: numberFormat(Number(value.view_percentage)) + "%",
            type: "text",
            extraClass: "line-td",
          },
        });
      });
    }

    return pageViewsRows;
  };

  const columns = ["Página", "Vistas", "Número de Visitas a Páginas"];

  const keyRows = [
    { key: "page", column: "" },
    { key: "views", column: "" },
    { key: "percentage", column: "" },
  ];

  //Load chart data
  const loadPageViewsTableData = async () => {
    let nexTokenNumber = (pagePageViewsData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

    const params = [
      "towns=" + decodeURI(match.params.code),
      "startDate=2019-11-01",
      "endDate=" + moment().format("YYYY-MM-DD"),
      "nextPage=" + nexTokenNumber,
    ];

    const res = await getReportsInterestPages({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: () => {},
      params: params,
    });
    if (res.legth !== 0) {
      setPageViewsData(res);
    }
    if (res[0]) {
      setTotalPagePageViewsData(res[0][decodeURI(match.params.code)].pagination.total_pages);
    }

    setChartWasLoaded3(true);
  };

  useEffect(() => {
    if (decodeURI(match.params.code) && reloadItemsPageViews) {
      loadPageViewsTableData();
      setReloadItemsPageViews(false);
    }
  }, [reloadItemsPageViews]);
  ////////////////////////////////////////////////////////////////
  ////////////////// END PAGE SEARCH TABLE ///////////////////////
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  ////////////////// SEGMENTS TO BUY TABLE ///////////////////////
  ////////////////////////////////////////////////////////////////
  interface ISegmentBuyRespDataSpec {
    count: string;
    percentage: string;
  }

  interface ISegmentBuyRespData {
    avg_sesion: ISegmentBuyRespDataSpec;
    category: string;
    sessions: ISegmentBuyRespDataSpec;
    users: ISegmentBuyRespDataSpec;
  }

  interface ISegmentBuyPagination {
    next_page_token: string;
    total: number;
    total_pages: number;
  }

  interface ISegmentBuyResp {
    data: ISegmentBuyRespData[];
    pagination: ISegmentBuyPagination;
  }

  interface ISegmentBuyTableObject {
    [key: string]: ISegmentBuyResp;
  }

  interface ISegmentBuyTable {
    [key: number]: ISegmentBuyTableObject;
  }

  const initSegmentsBuyDefaultValue = (): ISegmentBuyTable => {
    return [
      {
        [decodeURI(match.params.code)]: {
          data: [],
          pagination: {
            next_page_token: "0",
            total: 1,
            total_pages: 1,
          },
        },
      },
    ];
  };

  const [segmentBuyData, setSegmentBuyData] = useState<ISegmentBuyTable>(initSegmentsBuyDefaultValue());

  // for pagination
  const [pageSegmentBuyData, setPageSementBuyData] = useState<number>(1);
  const [totalPageSegmentBuyData, setTotalPageSegmentBuyData] = useState<number>(1);
  const [reloadItemsSegmentsBuy, setReloadItemsSegmentsBuy] = useState<boolean>(true);

  const changePageSegmentsBuy = (page: number) => {
    setPageSementBuyData(page);
    setReloadItemsSegmentsBuy(true);
  };

  const getBuySegementsRows = (): iRow[] => {
    if (!segmentBuyData[0]) return [];
    const currentTownData = segmentBuyData[0][decodeURI(match.params.code)];
    let pageViewsRows: iRow[] = [];

    if (currentTownData) {
      currentTownData.data.map((value: ISegmentBuyRespData) => {
        pageViewsRows.push({
          category: {
            value: value.category,
            type: "text",
          },
          users: {
            value: numberFormat(Number(value.users.count)),
            type: "text",
          },
          sessions: {
            value: numberFormat(Number(value.sessions.count)),
            type: "text",
          },
          sessions_avg: {
            value: formatDateToHHMMSS(value.avg_sesion.count),
            type: "text",
          },
        });
      });
    }

    return pageViewsRows;
  };

  const segmentsColumns = ["Categoria", "Usuarios", "Sesiones", "Tiempo de duración"];

  const segmentskeyRows = [
    { key: "category", column: "" },
    { key: "users", column: "" },
    { key: "sessions", column: "" },
    { key: "sessions_avg", column: "" },
  ];

  //Load chart data
  const loadSegmentsBuyTableData = async () => {
    let nexTokenNumber = (pagePageViewsData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

    const params = [
      "towns=" + decodeURI(match.params.code),
      "startDate=2019-11-01",
      "endDate=" + moment().format("YYYY-MM-DD"),
      "nextPage=" + nexTokenNumber,
    ];

    const res = await getInterestSegmentsViews({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: () => {},
      params: params,
    });
    if (res.length !== 0) {
      setSegmentBuyData(res);
    }
    if (res[0]) {
      setTotalPageSegmentBuyData(res[0][decodeURI(match.params.code)].pagination.total_pages);
    }
    setChartWasLoaded4(true);
  };

  useEffect(() => {
    if (decodeURI(match.params.code) && reloadItemsSegmentsBuy) {
      loadSegmentsBuyTableData();
      setReloadItemsSegmentsBuy(false);
    }
  }, [reloadItemsSegmentsBuy]);
  ////////////////////////////////////////////////////////////////
  ////////////////// END PAGE SEARCH TABLE ///////////////////////
  ////////////////////////////////////////////////////////////////

  const detectIfChannelsChartHaveData = (): boolean => {
    if (channelsDataChart[0] && channelsDataChart[0][decodeURI(match.params.code)])
      return channelsDataChart[0][decodeURI(match.params.code)].channels.length == 0 ? true : false;

    return false;
  };

  // Get third party components
  const { UikTopBarSection, UikTopBarTitle, UikLayoutMain } = uik;

  return (
    <InterestsReportsContainer className="page">
      <UikLayoutMain className={"wrapper content"}>
        <div className="grid general-interest-grid">
          <div id="interests-principal-channels" className="col-4">
            <CardInfoTown
              headerTitle="Canales de Adquisición"
              height="400"
              tooltipText={TOOLTIPS_TEXT["canales-adquisicion"]}
              contentClass="interest-pie-chart-class"
              showDisableScreen={detectIfChannelsChartHaveData()}
              showDisableScreenText={"No hay datos suficientes para cargar la gráfica."}
              disableScreenClass={"middle-screen"}
              statusHeaderText={"Datos históricos*"}
              alignItems={"center"}
            >
              <div id="report-circle-chart-content">
                <Pie width={350} height={250} data={dataPie} options={optionsPie}></Pie>
              </div>
            </CardInfoTown>
          </div>

          <div id="interests-principal-category" className="col-8">
            <CardInfoTown
              headerTitle="Categorías de Afinidad"
              height="400"
              tooltipText={TOOLTIPS_TEXT["canales-principales"]}
              statusHeaderText={"Datos históricos*"}
            >
              <div className="cateogry-main-table">
                <Table rows={getCategoriesRows()} columns={columnsInfo} keyRows={keyRowsInfo} />

                <div className="container-pagination">
                  <Pagination
                    page={pageCategoriesTableData}
                    limit={totalPageCategoriesTableData || 1}
                    callback={(page: number) => changePageCategoriesTable(page)}
                  />
                </div>
              </div>
            </CardInfoTown>
          </div>

          <div id="interests-page-search" className="col-12">
            <CardInfoTown
              headerTitle="Página de búsqueda"
              height="400"
              tooltipText={TOOLTIPS_TEXT["paginas-de-busqueda"]}
              flexFlow="column"
              statusHeaderText={"Datos históricos*"}
            >
              <>
                <Table rows={getPageViewsRows()} columns={columns} keyRows={keyRows} />

                <div className="container-pagination">
                  <Pagination
                    page={pagePageViewsData}
                    limit={totalPagePageViewsData || 1}
                    callback={(page: number) => changePagePageViews(page)}
                  />
                </div>
              </>
            </CardInfoTown>
          </div>

          <div id="interests-segment-to-buy" className="col-12">
            <CardInfoTown
              headerTitle="Segmentos con intención de compra"
              height="400"
              tooltipText={TOOLTIPS_TEXT["segmentos-de-compra"]}
              flexFlow="column"
              statusHeaderText={"Datos históricos*"}
            >
              <>
                <Table rows={getBuySegementsRows()} columns={segmentsColumns} keyRows={segmentskeyRows} />

                <div className="container-pagination">
                  <Pagination
                    page={pageSegmentBuyData}
                    limit={totalPageSegmentBuyData || 1}
                    callback={(page: number) => changePageSegmentsBuy(page)}
                  />
                </div>
              </>
            </CardInfoTown>
          </div>
        </div>
      </UikLayoutMain>
    </InterestsReportsContainer>
  );
};

export default withRouter(InterestsReports);
