import React from 'react'

import * as uik from '../../../@uik';

import TopBar from '../../../components/TopBar/TopBar';
import { RouteComponentProps, withRouter, Switch, Route } from 'react-router';

import General from './General/General';
import Traffic from './Traffic/Traffic';
import Demographic from './Demographic/Demographic';
import Interests from './Interests/Interests';
import { images } from '../../../assets/images';


interface RouteParams {
  code: string
}

interface iGlobalDataDetailContainerProps extends RouteComponentProps<RouteParams> { }

const GlobalDataDetailContainer: React.FC<iGlobalDataDetailContainerProps> = ({ match, history }) => {

  const { UikTopBarSection, UikTopBarTitle, UikContainerVertical } = uik;
  return (
    <UikContainerVertical className={"page"}>
      <TopBar tabs={[
        { text: 'General', to: `/global-data/detail/${match.params.code}`, exact: true },
        { text: 'Tráfico', to: `/global-data/detail/${match.params.code}/traffic` },
        { text: 'Demográfico', to: `/global-data/detail/${match.params.code}/demographic` },
        { text: 'Intereses', to: `/global-data/detail/${match.params.code}/interest` },
      ]}>
        <UikTopBarSection>
          <UikTopBarTitle>
            <img
              src={images.IconArrowLeftGrey} className="img-back"
              alt="back"
              onClick={() => history.goBack()}
            />
            Detalle Global Data
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <Switch>
        <Route exact path="/global-data/detail/:code" component={General} />
        <Route exact path="/global-data/detail/:code/traffic" component={Traffic} />
        <Route exact path="/global-data/detail/:code/demographic" component={Demographic} />
        <Route exact path="/global-data/detail/:code/interest" component={Interests} />
      </Switch>
    </UikContainerVertical>
  );
}

export default withRouter(GlobalDataDetailContainer);


