import React, { useState, useContext, SyntheticEvent } from 'react'
import Cookie from 'js-cookie';

import * as uik from './../../@uik';

import { iData } from '../../models/login.model';
import { isValidEmail, isValidPassword } from '../../utils/validations';
import { login } from '../../services/login.services';

import GeneralContext from './../../context/general.context'
import { ERROR_MESSAGES } from '../../constants/errorMessages';
import { NavLink } from 'react-router-dom';
import { images } from '../../assets/images';
import IconComponent from '../../components/Icon/Icon';



const Login: React.FC = () => {

  const [data, setData] = useState<iData>({
    username: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'email'
    },
    password: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'password'
    }
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const general = useContext(GeneralContext)


  const validateForm = (): boolean => {
    let isOk = true;

    Object.keys(data).forEach((key: string) => {
      if (data[key].required && data[key].value === '') {
        data[key].error = true;
        data[key].errorCode = 'required';
        isOk = false;
      } else if (data[key].type === 'email' && !isValidEmail(data[key].value)) {
        data[key].errorCode = 'invalid-email';
        data[key].error = true;
        isOk = false;
      } else if (data[key].type === 'password' && !isValidPassword(data[key].value)) {
        data[key].errorCode = 'invalid-password';
        data[key].error = true;
        isOk = false;
      } else {
        data[key].errorCode = '';
        data[key].error = false;
      }
    })

    setData({ ...data });
    return isOk;
  }

  const handleLogin = async () => {
    const isOk = validateForm();
    if (isOk) {
      const dataSend = {
        username: data.username.value,
        password: data.password.value,
      }
      try {
        const res = await login({
          loader: true,
          setLoading: general.setLoading,
          data: dataSend,
        })
        if (res.type !== 'staff') {
          data.password.error = true;
          data.password.errorCode = 'login-error';
          setData({ ...data });
        } else {
          Cookie.set('accessToken', res.access_token, { expires: 7 });
          Cookie.set('idUser', res.id, { expires: 7 });
          general.setUser(res);
          general.setIsLogged(true);
        }
      } catch (err) {
        data.password.error = true;
        data.password.errorCode = 'login-error';
        setData({ ...data });
      }
    }
  }

  const handleChange = (e: SyntheticEvent) => {

    const target = e.target as HTMLInputElement;
    data[target.name].value = target.value;
    setData({ ...data });
  }

  const { UikWidgetContent,
    UikWidget,
    UikInput,
    UikFormInputGroup,
    UikHeadline,
    UikHeadlineDesc,
    UikDivider,
    UikButton,
  } = uik;

  return (
    <div className="pageWrapper">
      <UikWidget
        className="widgetWrapper"
      >
        <div className="content">
          <UikWidgetContent className="left">
            <UikHeadline>
              Iniciar sesión
          </UikHeadline>
            <UikHeadlineDesc>
              Accede a tu cuenta correctamente para iniciar sesión
          </UikHeadlineDesc>
            <form className="login" onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
              <UikFormInputGroup>
                <UikInput
                  label="Correo"
                  name="username"
                  placeholder="your@email.com"
                  errorMessage={ERROR_MESSAGES[data.username.errorCode]}
                  value={data.username.value}
                  onChange={handleChange}
                />
                <UikInput
                  label="Password"
                  name="password"
                  placeholder="·····"
                  type={showPassword ? "text" : "password"}
                  errorMessage={ERROR_MESSAGES[data.password.errorCode]}
                  value={data.password.value}
                  onChange={handleChange}
                  icon={(
                    <IconComponent
                      name="IconEyeGray"
                      onClick={() => setShowPassword(!showPassword)}
                      className={[showPassword ? 'active-icon' : 'inactive-icon', 'pointer'].join(' ')}
                    />
                  )}
                  iconPosition="right"
                />
              </UikFormInputGroup>
              <UikDivider margin />
              <UikButton
                className="btnAction"
                type="submit"
                // Component={NavLink}
                success
                onClick={handleLogin}
              // to="/buildings"
              >
                Entrar
          </UikButton>
            </form>
            <div className="login-footer">
              <NavLink to="/forgot-password">
                ¿Olvidaste tu contraseña?
            </NavLink>
            </div>
          </UikWidgetContent>
          <div className="right">
            <h3 className="headline">
              <IconComponent name="IconGlobalData" className={"svg-none"} />
              Dashboard
          </h3>
            <p className="desc">
              Super Admin España Fascinante
          </p>
          </div>
        </div>
      </UikWidget>
      <p>
        Patrocinadores
      </p>
      <img alt="sponsor" src={images.sponsor1} />
    </div>
  )
}

export default Login

