import React, { useState, useContext, useEffect } from 'react'

import { RouteComponentProps, withRouter } from 'react-router';
import * as uik from '../../@uik';

import GeneralContext from '../../context/general.context'
import { editSubscription, createSubscription, getOneSubscription, pictureSubscription } from '../../services/subscription.services';

import { iSubscriptionForm } from '../../models/subscriptions.models';
import { iModal } from '../../models/general.model';

import TopBar from '../../components/TopBar/TopBar';
import InfoModal from '../../components/InfoModal/InfoModal';
import Form from '../../components/Form/Form';
import { images } from '../../assets/images';

interface RouteParams {
  id: string
}

interface iSubscriptionFormProps extends RouteComponentProps<RouteParams> { }

const SubscriptionForm: React.FC<iSubscriptionFormProps> = ({ history, match }) => {

  const [item, setItem] = useState<iSubscriptionForm>({
    name: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      render: 'input',
      label: 'Nombre',
      placeholder: 'Pequeña',
      className: 'col-12'
    },
    priceMonthly: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'number',
      render: 'input',
      label: 'Precio Mensual',
      placeholder: '14.99',
      className: 'col-6'
    },
    priceAnnual: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'number',
      render: 'input',
      label: 'Precio Anual',
      placeholder: '149.99',
      className: 'col-6'
    },
    minPoblation: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'number',
      render: 'input',
      label: 'Minimo de población',
      placeholder: '0',
      className: 'col-6'
    },
    maxPoblation: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'number',
      render: 'input',
      label: 'Máximo de población',
      placeholder: '150',
      className: 'col-6'
    },
    tags: {
      value: [''],
      error: false,
      errorCode: '',
      required: false,
      type: 'text',
      render: 'array',
      label: 'Etiquetas',
      placeholder: 'tag',
      className: 'col-12'
    },
    file: {
      value: '',
      error: false,
      errorCode: '',
      required: false,
      type: 'text',
      render: 'file',
      label: 'Foto perfil',
      placeholder: 'selecciona una imagen...',
      accept: ["image/x-png,image/jpeg"],
      className: 'col-12',
      picture: ''
    }
  });
  const [reloadItem, setReloadItem] = useState<boolean>(true);
  const [reloadForm, setReloadForm] = useState<boolean>(true);
  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });

  const title: string = match.params.id ? 'Editar suscripción' : 'Crear suscripción';
  const isEdit: boolean = !!match.params.id;

  const general = useContext(GeneralContext);

  useEffect(() => {

    if (reloadItem && match.params.id) {
      getItem();
      setReloadItem(false);
    }
  }, [reloadItem]);

  const getItem = async () => {
    const res: any = await getOneSubscription({
      id: match.params.id,
      loader: true,
      setLoading: general.setLoading,
    });

    Object.keys(item).forEach((key: string) => {
      item[key].value = res[key] || '';
    });

    item['priceAnnual'].value = res.priceAnual;
    item['tags'].value = res.tags ? res.tags.map((tag: string) => tag) : [];
    item['file'].picture = res.image;

    setItem({ ...item });
    setReloadForm(true);
  }

  const handleAccept = async (dataForm: any) => {
    setItem({ ...dataForm });
    const data = {
      name: dataForm.name.value,
      priceMonthly: parseFloat(dataForm.priceMonthly.value),
      priceAnual: parseFloat(dataForm.priceAnnual.value),
      maxPoblation: parseFloat(dataForm.maxPoblation.value),
      minPoblation: parseFloat(dataForm.minPoblation.value),
      tags: dataForm.tags.value.filter((tag: string) => tag !== ''),
      subscriptionFrequency: 'mensual'
    }
    try {
      let id = match.params.id;
      if (isEdit) {
        await editSubscription({
          id: match.params.id,
          data,
          loader: true,
          setLoading: general.setLoading
        });
      } else {
        const res = await createSubscription({
          data,
          loader: true,
          setLoading: general.setLoading
        });
        id = res.id;
      }

      if (dataForm.file.value) {
        const formData = new FormData();
        formData.set('file', dataForm.file.value.data);
        await pictureSubscription({
          id,
          data: formData,
          loader: true,
          setLoading: general.setLoading
        })
      }

      history.push('/subscriptions');
    } catch (err) {
      setModal({
        code: (err.response && err.response.status) ? err.response.status : 'err-local',
        text: (err.response && err.response.description) ? err.response.description : 'Error inesperado, inténtelo más tarde.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Error',
        alert: '',
        className: 'error',
        img: images.IconXCircle
      });
    }
  }

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: '',
      img: images.IconXCircle
    })
  }

  const { UikTopBarSection, UikTopBarTitle, UikContainerVertical,
    UikLayoutMain, UikWidgetContainer } = uik;

  return (
    <UikContainerVertical className={"page"}>
      <TopBar>
        <UikTopBarSection>
          <UikTopBarTitle>
            <img
              src={images.IconArrowLeftGrey} className="img-back"
              alt="back"
              onClick={() => history.push('/subscriptions')}
            />
            {title}
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <UikWidgetContainer>
          <Form
            titleForm={title}
            buttonText="Guardar"
            handleAccept={handleAccept}
            dataForm={item}
            reloadForm={reloadForm}
            setReloadForm={setReloadForm}
          // handleBack={() => history.push('/subscriptions')}
          />

        </UikWidgetContainer>
      </UikLayoutMain>
      <InfoModal
        className={modal.className}
        onClickAccept={modal.handleAccept}
        mainText={modal.text}
        titleText={modal.title}
        alertText={modal.alert}
        buttonText={modal.button}
        active={modal.show}
        img={modal.img}
        close={() => closeModal()}
      />
    </UikContainerVertical>
  );
}

export default withRouter(SubscriptionForm);

