import React, { Component } from "react";
import { InfoModalContainer } from "./info-modal-style";
import * as uik from '../../@uik';
import { images } from "../../assets/images";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import Select from "../Select/Select";

interface InfoModalProps {
  className?: string;
  onClickAccept: Function;
  mainText: string;
  titleText: string;
  alertText?: string;
  buttonText: string;
  inputs?: any[];
  active?: boolean;
  img?: string;
  close: Function;
}
interface InfoModalState {
  active: string;
}

class InfoModal extends Component<InfoModalProps, InfoModalState> {
  readonly state: InfoModalState;

  public static defaultProps = {
    active: false,
    img: "",// images.IconSearchBlue,
    onClickAccept: () => { },
    className: "",
    mainText: "",
    buttonText: "",
    close: () => { },
    alertText: "",
    titleText: "",
    inputs: []
  };

  constructor(props: InfoModalProps) {
    super(props);

    this.state = {
      active: props.active ? "active" : "",
    };
  }

  componentDidUpdate(prevProps: InfoModalProps) {
    if (prevProps.active !== this.props.active) {
      this.setState({ active: this.props.active ? "active" : "" });
    }
  }

  close() {
    this.setState({ active: "" });
  }

  open() {
    this.setState({ active: "active" });
  }


  acceptInfoModal() {
    const { onClickAccept, close } = this.props;
    if (onClickAccept) {
      onClickAccept();
    }
    close();
  }

  renderInput = (item: any, index: number) => {
    const { UikInput } = uik;
    switch (item.render) {
      case 'input':
        return (
          <div key={`input-${index}`} className={item.className}>
            <UikInput
              label={item.label}
              placeholder={item.placeholder}
              errorMessage={ERROR_MESSAGES[item.errorCode]}
              value={item.value}
              onChange={(ev: any) => item.handleChange(ev.target.value, item.key)}
            />
          </div>);
      case 'select':
        return (
          <div key={`input-${index}`} className={item.className}>
            <Select
              position="bottomRight"
              className={item.error ? 'error' : ''}
              label={""}
              options={item.options}
              placeholder={item.placeholder}
              errorMessage={ERROR_MESSAGES[item.errorCode]}
              value={item.value.value ? [item.value] : ''}
              defaultValue={item.defaultValue}
              onChange={(value: any) => item.handleChange(value, item.key)}
            />
          </div>);
      default:
        return (
          <div key={`input-${index}`} className={item.className}>
            <UikInput
              label={item.label}
              placeholder={item.placeholder}
              errorMessage={ERROR_MESSAGES[item.errorCode]}
              value={item.value}
              onChange={(ev: any) => item.handleChange(ev.target.value, item.key)}
            />
          </div>);
    }

  }

  render() {
    const { mainText, className, buttonText, close, alertText, titleText, inputs, img } = this.props;
    const { active } = this.state;
    const { UikButton, UikDivider } = uik;

    return (
      <InfoModalContainer className={`${active} ${className}`}>
        <div className="info-modal-container">
          <div className="info-modal-header">
            <div className="info-modal-header-title">
              <p>{titleText}</p>
            </div>
            <div className="info-modal-close">
              <img alt="close" src={images.IconCloseGrey} onClick={() => close()} />
            </div>
          </div>
          <UikDivider />
          <div className="info-modal-body">
            {img && (
              <div className="info-modal-img">
                <img src={img} alt="img-modal" />
              </div>
            )}
            {alertText && (
              <div className="info-modal-alert">
                <p>{alertText}</p>
              </div>
            )}
            <div className="info-modal-text">
              <p>{mainText}</p>
            </div>
          </div>
          {inputs && inputs.length > 0 && (
            <div className="grid">
              {inputs.map((item: any, index: number) => (
                this.renderInput(item, index)
              ))}
            </div>
          )}
          <div className="grid info-modal-button">
            <div className={buttonText ? "col-6" : "col-12"}>
              <UikButton
                className={!buttonText ? "main-button" : ""}
                onClick={() => close()}>
                Cerrar
              </UikButton>
            </div>
            {buttonText && (
              <div className="col-6">
                <UikButton
                  className="main-button"
                  onClick={() => this.acceptInfoModal()}
                >
                  {buttonText}
                </UikButton>
              </div>

            )}
          </div>
        </div>
      </InfoModalContainer>
    );
  }
}

export default InfoModal;
