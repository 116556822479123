import { post } from "./general.services";

export const login = async (args: any) => {

  try {
    const res = await post({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "auth/login"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const resetPassword = async (args: any) => {

  try {
    const res = await post({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "auth/password/reset"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const forgotPassword = async (args: any) => {

  try {
    const res = await post({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "auth/password/forgot"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}