import React, { Component } from "react";
import { SearchBoxContainer } from "./search-box-style";
import { ERROR_MESSAGES } from "../../constants/errorMessages";

interface SearchBoxProps {
  className?: string;
  icon?: string;
  disabled?: boolean;

  required?: boolean;
  errorCode?: string;

  labelText: string;
  initialValue?: string;
  onSelect: (value: any) => void;
  onChange: Function;

  optionsText: Array<{
    [key: string]: string;
  }>;
  optionKey: string;
  optionValue: string;

  withTooltip?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;

  placeholder?: string;
}

interface SearchBoxState {
  showTooltip: boolean;
  showList: boolean;
  listFilter: any[];
  search: string;
  value: string;
  valueLabel: string;
  error: string;
}

class SearchBox extends Component<SearchBoxProps, SearchBoxState> {
  private toggleContainer: any;

  constructor(props: SearchBoxProps) {
    super(props);
    this.state = {
      showTooltip: false,
      showList: false,
      listFilter: props.optionsText,
      search: "",
      value: props.initialValue || '',
      valueLabel: "",
      error: "",
    };
    this.toggleContainer = React.createRef();

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

    window.addEventListener("click", this.onClickOutsideHandler);
  };

  componentDidUpdate(prevProps: SearchBoxProps) {
    const { value } = this.state;
    const { initialValue, optionValue, optionsText } = this.props;
    if (prevProps.initialValue !== initialValue && initialValue && value === "") {
      this.setState({ value: initialValue });
    } else if (prevProps.initialValue !== initialValue && initialValue === '') {
      this.setState({ value: '', listFilter: optionsText, search: '' });
    } else if (initialValue && initialValue !== prevProps.initialValue) {
      this.setState({ value: initialValue });
    }

    if (this.props.optionsText.length !== prevProps.optionsText.length) {
      const listFilter = this.props.optionsText.filter(item => item[optionValue].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1);
      this.setState({ listFilter })

    }
    else {
      let i = 0;
      let change = false;
      while (i < this.props.optionsText.length) {
        if (this.props.optionsText[i].value !== prevProps.optionsText[i].value) {
          change = true;
          break;
        }
        i++;
      }
      if (change) {
        const listFilter = this.props.optionsText.filter(item => item[optionValue].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1);
        this.setState({ listFilter })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClickOutsideHandler);
  }

  onClickOutsideHandler(event: Event) {
    const { showList } = this.state;
    if (this.state.showList && !this.toggleContainer.current.contains(event.target)) {
      if (showList) {
        this.setItemBlur()
      }
    }
  }

  handleSelect(option: any) {
    const { optionValue, onSelect } = this.props;

    this.setState({ value: option[optionValue], showList: false }, () => {
      onSelect(option);
    });
  }

  setActive() {
    const { showList } = this.state;
    showList && (document.activeElement as HTMLElement).blur();
    this.setState({ showList: !showList })
  }

  setValue(e: any) {
    const { optionValue, onChange } = this.props;
    onChange(e.target.value);
    if (e.target.value === '') {
      this.setState({ listFilter: this.props.optionsText, value: e.target.value });
    }
    else {
      const listFilter = this.props.optionsText.filter(item => item[optionValue].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1);

      this.setState({ listFilter, value: e.target.value });
    }
  }

  setItemBlur() {
    const { listFilter, value } = this.state;
    const { optionValue } = this.props;
    const element = listFilter.filter(item => item[optionValue].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1);

    if (value !== '' && element[0]) {
      const list = this.props.optionsText.filter(item => item[optionValue].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(element[0][optionValue].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1);
      this.setState({ value: element[0][optionValue], listFilter: list, showList: false });
      this.props.onSelect(element[0]);
    }
    else {
      this.setState({ listFilter: this.props.optionsText, value: '', showList: false });
    }
  }

  render() {
    const {
      className,
      icon,
      disabled,
      errorCode,
      labelText,
      optionsText,
      optionValue,
      required,
      placeholder
    } = this.props;

    const { showList, listFilter, value } = this.state;


    return (
      <SearchBoxContainer
        ref={this.toggleContainer}
        className={`${className} ${disabled ? "disabled" : ""} ${errorCode ? "error" : ""} ${value !== "" ? "complete" : ""} ${required ? "required" : ""}`}
      >
        {labelText && (
          <div className="input-box-topbar">
            <div className="input-box-topbar-label">
              <p>{labelText}</p>
            </div>
          </div>
        )}

        <div className="input-box-main">
          <div className="input-box-main-field">
            <input
              onFocus={() => this.setActive()}
              disabled={disabled}
              type="text"
              autoComplete="nope"
              value={value}
              onChange={e => this.setValue(e)}
              placeholder={placeholder}
            />
          </div>
          <div className="input-box-icon">
            <img src={icon} />
          </div>
        </div>

        {showList && (
          <ul className="input-box-main-list">
            {optionsText.length <= 0 ? (
              <li className="no-data">
                <p>No se han encontrado resultados.</p>
                <p>Escriba más caracteres y/o compruebe que todo está correcto.</p>
              </li>
            ) : (
                listFilter.map((option, i) => (
                  <li key={i} onClick={() => this.handleSelect(option)}>
                    <p>{option[optionValue]}</p>
                  </li>
                ))
              )}
          </ul>
        )}

        {errorCode && !disabled && (
          <div className="input-box-error">

            <p>{`${errorCode}`}</p>
          </div>
        )}
      </SearchBoxContainer>
    );
  }
}

export default SearchBox;
