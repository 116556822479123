import React from 'react';

import { CardContainer } from './card-position-style';
import { images } from '../../assets/images';

interface iCardsProps {
  up?: boolean;
  date: string;
  position: string;
  text: string;
  section: string;
}

const CardPosition: React.FC<iCardsProps> = ({ up, date, position, text, section }) => {

  return (
    <CardContainer>
      <div className="card-header">
        {up !== undefined && (
          <div className="card-header-left">
            {up ? <img alt="up" src={images.IconUp} /> :
              <img alt="down" src={images.IconDown} />
            }
          </div>
        )}
        <div className="card-header-right">
          <p>
            {date}
          </p>
        </div>
      </div>
      <div className="card-body">
        <div className="card-body-position">
          <p>{position}</p>
          <span>{text}</span>
        </div>
        <div className="card-body-section">
          <p>{section}</p>
        </div>
      </div>
    </CardContainer>
  );
}

export default CardPosition;
