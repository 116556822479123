function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var SvgIconTrash = function SvgIconTrash(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    width: 20,
    height: 20,
    viewBox: "0 0 20 20",
    fill: "none",
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, React.createElement("path", {
    style: {
      fill: "#FF4100"
    },
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13 13C13 13.4125 12.6625 13.75 12.25 13.75C11.8375 13.75 11.5 13.4125 11.5 13V10C11.5 9.5875 11.8375 9.25 12.25 9.25C12.6625 9.25 13 9.5875 13 10V13ZM8.5 13C8.5 13.4125 8.1625 13.75 7.75 13.75C7.3375 13.75 7 13.4125 7 13V10C7 9.58753 7.3375 9.25003 7.75 9.25003C8.1625 9.25003 8.5 9.58753 8.5 10V13ZM14.5 15.25C14.5 15.6632 14.164 16 13.75 16H6.25C5.836 16 5.5 15.6632 5.5 15.25V7H14.5V15.25ZM8.5 4.246C8.5 4.12975 8.6605 4 8.875 4H11.125C11.3395 4 11.5 4.12975 11.5 4.246V5.5H8.5V4.246ZM16.75 5.5H16H13V4.246C13 3.283 12.1592 2.5 11.125 2.5H8.875C7.84075 2.5 7 3.283 7 4.246V5.5H4H3.25C2.8375 5.5 2.5 5.8375 2.5 6.25C2.5 6.6625 2.8375 7 3.25 7H4V15.25C4 16.4905 5.0095 17.5 6.25 17.5H13.75C14.9905 17.5 16 16.4905 16 15.25V7H16.75C17.1625 7 17.5 6.6625 17.5 6.25C17.5 5.8375 17.1625 5.5 16.75 5.5V5.5Z",
    fill: "white"
  }));
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgIconTrash, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/icon-trash.0d0a1b35.svg";
export { ForwardRef as ReactComponent };