import React, { useState, useContext, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router';
import ReactTooltip from 'react-tooltip';

import { getBills, editBills, getOneBillsDownload, getBillsCsv } from '../../services/bills.services';

import GeneralContext from '../../context/general.context'

import Pagination from '../../components/Pagination/Pagination';
import TopBar from '../../components/TopBar/TopBar';
import Table from '../../components/Table/Table';
import InfoModal from '../../components/InfoModal/InfoModal';
import IconComponent from '../../components/Icon/Icon';

import * as uik from '../../@uik';

import { iModal, iKeyRows } from '../../models/general.model';
import { iBills } from '../../models/bills.models';
import { images } from '../../assets/images';
import { currencyFormat, textWithPoints } from '../../utils/text';
import { TOTAL_ITEMS } from '../../constants/general';


export interface iBillsProps extends RouteComponentProps { }

const Billing: React.FC<iBillsProps> = ({ history }) => {

  const [items, setItems] = useState<iBills[]>([]);
  const [filter, setFilter] = useState<string>('');

  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });

  const [reloadItems, setReloadItems] = useState<boolean>(true);
  const [order, setOrder] = useState<any>({ order: '', key: '' });

  const general = useContext(GeneralContext);


  useEffect(() => {
    if (reloadItems) {
      getItems();
      setReloadItems(false);
    }
  }, [reloadItems]);

  const getItems = async () => {

    const params = [`limit=${TOTAL_ITEMS}`];
    const offset = TOTAL_ITEMS * (page - 1);
    params.push(`offset=${offset}`);

    if (filter) {
      params.push(`filter=${filter}`);
    }

    if (order.key !== '' && order.order) {
      params.push(`orderBy=${order.key}`);
      params.push(`order=${order.order}`);
    } else {
      params.push(`orderBy=invoice.updated_at`);
      params.push(`order=desc`);
    }


    const res = await getBills({
      params,
      loader: true,
      setLoading: general.setLoading
    });

    setItems(res.results);
    setTotalPage(Math.ceil(res.total / TOTAL_ITEMS) || 1);
    if (totalItems === 0) {
      setTotalItems(res.total);
    }
  }

  const handleOrder = (order: any) => {
    setOrder({ ...order });
    setReloadItems(true);
  }

  const handleStatus = async (item: any, state: string) => {
    try {
      const data = {
        status: state
      }
      await editBills({
        data,
        id: item.id,
        loader: true,
        setLoading: general.setLoading
      });
      closeModal();
      setModal({
        code: 'success',
        button: '',
        handleAccept: () => { },
        text: 'Se ha cambiado  correctamente de estado.',
        show: true,
        title: 'Estado de la factura',
        alert: '',
        className: '',
        img: images.IconCheckCircle
      });
      setReloadItems(true);
    } catch (err) {
      setModal({
        code: 'error',
        button: '',
        handleAccept: () => { },
        alert: 'Error inesperado.',
        show: true,
        title: 'Cambiar estado factura',
        text: 'Intente cambiar estado de la factura más tarde.',
        className: 'error',
        img: images.IconXCircle
      });
    }
  }

  const handleDownloadItem = async (item: any) => {
    try {
      const res = await getOneBillsDownload({
        id: encodeURIComponent(item.link),
        loader: true,
        setLoading: general.setLoading
      });

      const dlnk: any = document.getElementById('downloadPDF');
      dlnk.href = `data:application/pdf;base64,${res.file}`;
      dlnk.download = `${item.customer.username || item.customer.town.name.toLowerCase().replace(/ /g, '-')}-factura.pdf`;//'facturas.pdf'; //file.name
      dlnk.click();
      closeModal();

    } catch (err) {
      setModal({
        code: 'error',
        button: '',
        handleAccept: () => { },
        alert: 'Error inesperado.',
        show: true,
        title: 'Descargar factura',
        text: 'Intente descargar la factura más tarde.',
        className: 'error',
        img: images.IconXCircle
      });
    }
  }

  const handleDownloadCsv = async () => {
    try {
      const res: any = await getBillsCsv({
        loader: true,
        setLoading: general.setLoading
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement('a');

      link.href = downloadUrl;

      link.setAttribute('download', 'file.csv'); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
      closeModal();

    } catch (err) {
      setModal({
        code: 'error',
        button: '',
        handleAccept: () => { },
        alert: 'Error inesperado.',
        show: true,
        title: 'Descargar factura',
        text: 'Intente descargar la factura más tarde.',
        className: 'error',
        img: images.IconXCircle
      });
    }
  }

  const getIcon = (item: any) => {
    const icon = [
      <ReactTooltip id={`state-${item.id}`} place="left" type="dark" effect="float">
        <span>{item.status}</span>
      </ReactTooltip>
    ];
    switch (item.status) {
      case 'pagada':
        icon.push(
          <div data-tip data-for={`state-${item.id}`} className="icon-bad-billing">
            <img src={images.IconCheckGreen} alt="payed" />
          </div>
        )
        break;
      case 'cancelada':
        icon.push(
          <div data-tip data-for={`state-${item.id}`} className="icon-bad-billing">
            <img src={images.IconCross} alt="cancel" />
          </div>);
        break;
      case 'pendiente':
        icon.push(
          <div data-tip data-for={`state-${item.id}`} className="icon-bad-billing">
            <img src={images.IconExclamation} alt="pending" />
          </div>
        );
        break;
      case 'devuelta':
        icon.push(
          <div data-tip data-for={`state-${item.id}`} className="icon-bad-billing">
            <img src={images.IconMoney} alt="abonada" />
          </div>
        );
        break;
    }
    return icon;
  }

  const getRows = () => {
    return !items ? [] : items.map((item: any) => {

      const buttons = [{
        label: "Descargar",
        icon: <li className="oi icon-download"></li>,
        onClick: () => {
          setModal({
            code: item.id,
            button: 'Aceptar',
            handleAccept: () => handleDownloadItem(item),
            text: 'Se va a descargar la factura seleccionada',
            show: true,
            title: 'Descargar factura',
            alert: '',
            className: '',
            img: '',
          });
        }
      },
        // {
        //   label: "Editar",
        //   icon: <img src={images.IconEdit}/>,
        //   onClick: () => {
        //     history.push(`/billing/edit/${item.id}`);;
        //   }
        // }
      ];

      if (item.status !== 'pendiente') {
        buttons.push({
          label: "Pendiente",
          icon: (
            <div className="icon-bad-billing">
              <img src={images.IconExclamation} alt="cross" />
            </div>
          ),
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Pendiente',
              handleAccept: () => handleStatus(item, 'pendiente'),
              text: 'Se cambiará el estado a pendiente',
              show: true,
              title: 'Cambiar a Pendiente',
              alert: '',
              className: 'success',
              img: '',
            });
          },
        });
      }
      if (item.status !== 'cancelada') {
        buttons.push({
          label: "Cancelar",
          icon: <img src={images.IconDelete} />,
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Cancelar',
              handleAccept: () => handleStatus(item, 'cancelada'),
              text: 'Se cambiará el estado a cancelado',
              show: true,
              title: 'Cancelar factura',
              alert: '',
              className: 'error',
              img: '',
            });
          },
        });
      }
      if (item.status !== 'pagada') {
        buttons.push({
          label: "Pagar",
          icon: <img src={images.IconBillingBlack} />,
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Pagar',
              handleAccept: () => handleStatus(item, 'pagada'),
              text: 'Se va a pagar la factura seleccionada',
              show: true,
              title: 'Pagar factura',
              alert: '',
              className: 'success',
              img: '',
            });
          }
        });
      }
      if (item.status !== 'devuelta') {
        buttons.push({
          label: "Abonar",
          icon: <div className="icon-bad-billing">
            <img src={images.IconMoney} alt="cross" />
          </div>,
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Abonar',
              handleAccept: () => handleStatus(item, 'devuelta'),
              text: 'Se va a abonar la factura seleccionada',
              show: true,
              title: 'Abonar factura',
              alert: '',
              className: 'success',
              img: '',
            });
          }
        });
      }
      return ({
        state: {
          value: getIcon(item),
          type: 'icon'
        },
        date: {
          value: item.date,
          type: 'text'
        },
        town: {
          value: item.customer && item.customer.town ? item.customer.town.name : '-',
          type: 'text'
        },
        contact: {
          value: item.customer ? item.customer.username : '-',
          type: 'text'
        },
        description: {
          value: textWithPoints(item.description, 30),
          type: 'text'
        },
        amount: {
          value: `${currencyFormat(item.amount)} €`,
          type: 'text'
        },
        buttons: {
          value: 'botones',
          actions: buttons,
          type: 'buttons'
        }
      });
    }
    )
  }

  const changePage = (page: number) => {
    setPage(page);
    setReloadItems(true);
  }

  const handleChangeSearch = (value: string) => {
    setFilter(value);
  }

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: '',
      img: ''
    })
  }

  const { UikTopBarSection, UikTopBarTitle, UikContainerVertical, UikLayoutMain,
    UikContentItem, UikWidget, UikInput } = uik;

  const columns = ['Estado', 'Fecha', 'Pueblo', 'Contacto', 'Descripción', 'Importe', 'Acciones'];

  const keyRows: iKeyRows[] = [{ key: 'state', column: 'invoice.status' }, { key: 'date', column: 'invoice.createdAt' },
  { key: 'town', column: 'town.name' }, { key: 'contact', column: 'customer.username' },
  { key: 'description', column: 'description' }, { key: 'amount', column: 'invoice.amount' },
  { key: 'buttons', column: '' }];


  return (
    <>
      <UikContainerVertical className={"page"}>
        <TopBar
          handleButton={() => history.push('billing/create')}
          textButton="Crear"
          handleButton2={() => setModal({
            code: 'download',
            button: 'Descargar',
            handleAccept: () => handleDownloadCsv(),
            text: `Se van a descargar ${totalItems} facturas, ¿estás seguro?`,
            show: true,
            title: 'Descargar facturas',
            alert: '',
            className: 'success'
          })}
          textButton2="Descargar"
          disabledButton2={!totalItems}
        >
          <UikTopBarSection>
            <UikTopBarTitle>
              <IconComponent name="IconBilling" className={"svg-black"} />
              Facturación
        </UikTopBarTitle>
          </UikTopBarSection>
        </TopBar>
        <UikLayoutMain className={"wrapper"}>
          <div className="container-search">
            <UikInput
              placeholder={"Buscar..."}
              label="*Solo filtra por contacto"
              type={"text"}
              errorMessage={""}
              value={filter}
              onChange={(ev: any) => handleChangeSearch(ev.target.value)}
              onKeyDown={(ev: any) => {
                if (ev.key === 'Enter') {
                  setPage(1);
                  setReloadItems(true)
                }
              }}
              icon={(
                <IconComponent
                  name="Search"
                  className={"svg-search pointer"}
                  onClick={() => {
                    setPage(1);
                    setReloadItems(true)
                  }}
                />
              )}
              iconPosition="right"
            />
          </div>
          <UikWidget margin>
            <UikContentItem>

              <Table
                rows={getRows()}
                columns={columns}
                keyRows={keyRows}
                handleOrder={(order: any) => handleOrder(order)}
              />
              <div className="container-pagination">
                <Pagination page={page} limit={totalPage} callback={(page: number) => changePage(page)} />
              </div>
            </UikContentItem>
          </UikWidget>
        </UikLayoutMain>
        <InfoModal
          className={modal.className}
          titleText={modal.title}
          alertText={modal.alert}
          mainText={modal.text}
          buttonText={modal.button}
          active={modal.show}
          onClickAccept={() => modal.handleAccept()}
          close={() => closeModal()}
          img={modal.img}
        />
      </UikContainerVertical>
      <a id='downloadPDF' style={{ display: "none" }} />
    </>
  );
}




export default withRouter(Billing);

