import React, { useState, useContext, useEffect, useRef } from 'react'
import { withRouter, RouteComponentProps } from 'react-router';
import Select from 'react-select';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

import moment from 'moment'
import 'moment/locale/es.js'

import GeneralContext from '../../context/general.context'

import TopBar from '../../components/TopBar/TopBar';
import Table from '../../components/Table/Table';
import InfoModal from '../../components/InfoModal/InfoModal';
import Pagination from '../../components/Pagination/Pagination';
import IconComponent from '../../components/Icon/Icon';

import * as uik from '../../@uik';

import { iModal } from '../../models/general.model';
import { iUsers } from '../../models/users.models';

import { COLUMNS, DURATION_RANGE } from '../../constants/globalData';
import { getGlobalData } from '../../services/reportCustom.services';
import { getCustomersProvinces, getCustomersCommunities, getCustomers } from '../../services/customer.services';
import SearchBox from '../../components/searchBox/SearchBox';
import { formatDateToHHMMSS } from '../../utils/time';
import { numberFormat } from '../../utils/text';
import { images } from '../../assets/images';

export interface iGlobalDataProps extends RouteComponentProps { }

const GlobalData: React.FC<iGlobalDataProps> = ({ history }) => {

  const [items, setItems] = useState<iUsers[]>([]);
  const [focusedInput, setFocusedInput] = useState<any>('');
  const [reloadItems, setReloadItems] = useState<boolean>(true);

  const [order, setOrder] = useState<any>({ order: '', key: '' });
  const [filter, setFilter] = useState<any>({
    date: {
      startDate: moment().subtract(1, 'month'),
      endDate: moment()
    },
    columns: [
      { label: 'Pueblo', value: 'town', type: 'text' },
      { label: 'Provincia', value: 'province', type: 'text' },
      { label: 'Comunidad', value: 'community', type: 'text' },
      { label: 'Nº de usuarios', value: 'users', type: 'text' },
      { label: 'Usuarios nuevos', value: 'new_users', type: 'text' },
      { label: 'Usuarios Recurrentes', value: 'returning_users', type: 'text' },
      { label: 'Nº de visitas', value: 'pageviews', type: 'text' },
      { label: 'Duración de sesión', value: 'avg_session', type: 'text' },
    ],
    search: "",
    durationRange: "",
    populationRange: "",
    communities: null,
    provinces: null,
    customer: ''
  });

  const [customers, setCustomers] = useState<any[]>([]);
  const [communities, setCommunities] = useState<any[]>([]);
  const [provinces, setProvinces] = useState<any[]>([]);
  const [provincesAll, setProvincesAll] = useState<any[]>([]);

  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);


  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });

  const searchInputReference = useRef<any>(null);

  let searchWaiter: any = null;
  const general = useContext(GeneralContext);

  useEffect(() => {
    getProvinces();
    getCommunities();
  }, []);

  useEffect(() => {
    if (reloadItems) {
      getItems();
      setReloadItems(false);
    }
  }, [reloadItems]);

  const getProvinces = async () => {
    const res = await getCustomersProvinces({
      loader: true,
      setLoading: general.setLoading,
    });

    const resProvinces = res.map((item: any) => ({
      label: item.name,
      value: item.code,
      communityLabel: item.community_name,
      communityCode: item.community_code,
    }))
    resProvinces.unshift({ label: 'Todos', value: '' });

    setProvinces(resProvinces);
    setProvincesAll(resProvinces.map((i: any) => i));
  }

  const getCommunities = async () => {
    const res = await getCustomersCommunities({
      loader: true,
      setLoading: general.setLoading,
    });

    const resCommunities = res.map((item: any) => ({ label: item.name, value: item.code }))
    resCommunities.unshift({ label: 'Todos', value: '' });

    setCommunities(resCommunities);
  }

  const getClientsItems = async (town?: string) => {

    if (searchWaiter) {
      clearInterval(searchWaiter);
    }
    searchWaiter = setTimeout(async () => {
      const params = [];

      if (town) {
        params.push(`filter=${town}`);
      }

      const res: any = await getCustomers({
        params,
        loader: false,
        setLoading: general.setLoading
      });
      let resCustomers = res.results ? res.results.map((i: any) => ({
        label: i.town ? i.town.name : i.username,
        value: i.town ? i.town.code : i.id,
        provinceLabel: i.town && i.town.province,
        provinceCode: i.town && i.town.provinceCode,
        communityLabel: i.town && i.town.community,
        communityCode: i.town && i.town.communityCode,
      })) : [];

      if (filter.provinces && filter.provinces.value) {
        resCustomers = resCustomers.filter((item: any) => item.provinceCode === filter.provinces.value);
      } else if (filter.communities && filter.communities.value) {
        resCustomers = resCustomers.filter((item: any) => item.communityCode === filter.communities.value);
      }

      resCustomers.unshift({ label: 'Todos', value: '' });

      setCustomers(resCustomers);
    }, 750);

  }

  const getItems = async () => {
    const params = [];
    const nextPage = page - 1;
    params.push(`nextPage=${nextPage}`);

    if (filter.search) {
      params.push(`filter=${filter.search}`);
    }

    if (filter.date.startDate && filter.date.endDate) {
      params.push(`endDate=${filter.date.endDate.format('YYYY-MM-DD')}`);
      params.push(`startDate=${filter.date.startDate.format('YYYY-MM-DD')}`);
    }

    if (filter.communities && filter.communities.value) {
      params.push(`community=${filter.communities.value}`);
    }

    if (filter.provinces && filter.provinces.value) {
      params.push(`province=${filter.provinces.value}`);
    }

    if (filter.customer && filter.customer.value) {
      params.push(`town=${filter.customer.value}`);
    }

    if (order.key !== '' && order.order) {
      params.push(`orderBy=${order.key}`);
      params.push(`order=${order.order}`);
    }

    if (filter.durationRange) {
      if (filter.durationRange.min) {
        params.push(`avgSessionMin=${filter.durationRange.min}`);
      }
      if (filter.durationRange.max) {
        params.push(`avgSessionMax=${filter.durationRange.max}`);
      }
    }

    const res = await getGlobalData({
      params,
      loader: true,
      setLoading: general.setLoading
    });

    setItems(res.results);
    setTotalPage(res.pages);
  }

  const handleOrder = (order: any) => {
    setOrder({ ...order });
    setPage(1);
    setReloadItems(true);
  }

  const getRows = () => {
    return !items ? [] : items.map((item: any) => {
      const obj: any = {};
      if (filter.columns) {
        filter.columns.forEach((c: any) => {
          obj[c.value] = {
            value: (c.value === 'avg_session' ?
              `${formatDateToHHMMSS(item[c.value])} s` :
              /^-{0,1}\d+$/.test(item[c.value]) ? numberFormat(item[c.value]) :
                item[c.value]) || '-',
            type: c.type || 'text'
          }
        });
        obj['buttons'] = {
          value: 'botones',
          actions: [
            {
              label: "Ver detalle",
              icon: <img src={images.IconEyeGray} />,
              onClick: () => {
                history.push(`/global-data/detail/${item.town}`);
              },
            },
          ],
          type: 'buttons'
        }
      }
      return obj;
    }
    );
  }

  const changePage = (page: number) => {
    setPage(page);
    setReloadItems(true);
  }

  const handleChangeSearch = (value: string) => {
    filter.search = value;
    setFilter({ ...filter });

  }

  const cleanSearchBox = () => {
    filter.customer = '';
    setCustomers([{ label: 'Todos', value: '' }]);
    if (searchInputReference && searchInputReference.current) {
      searchInputReference.current.setState({ value: '' });
    }
  }

  const handleChangeFilter = (item: any, key: string) => {
    if (key === 'customer') {
      filter.communities = { label: item.communityLabel, value: item.communityCode };
      filter.provinces = { label: item.provinceLabel, value: item.provinceCode };
      filter.customer = { label: item.label, value: item.value }
      setFilter({ ...filter });
      return;
    }

    if (key === 'communities') {
      filter.provinces = null;
      if (item.value) {
        setProvinces(provincesAll.filter((i: any) => item.value === i.communityCode));
      }
      cleanSearchBox();
    }

    if (key === 'provinces') {
      filter.communities = { label: item.communityLabel, value: item.communityCode };
      filter.provinces = { label: item.label, value: item.value };
      cleanSearchBox();
      setFilter({ ...filter });
      return;
    }

    filter[key] = item;
    setFilter({ ...filter });
  }

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: '',
      img: ''
    })
  }

  const { UikTopBarSection, UikTopBarTitle, UikContainerVertical, UikLayoutMain,
    UikContentItem, UikWidget, UikButton } = uik;

  return (
    <UikContainerVertical className={"page"}>
      <TopBar>
        <UikTopBarSection>
          <UikTopBarTitle>
            <IconComponent name="IconGlobalData" className={"svg-black"} />
            Datos globales
        </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <div className="grid margin-15">
          <div className="col-4">
            <div className="calendar-container">
              <DateRangePicker
                startDateId="startDateId"
                endDateId="endDateId"
                startDate={filter.date.startDate}
                endDate={filter.date.endDate}
                onDatesChange={(date: any) => handleChangeFilter(date, 'date')}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput: any) => { setFocusedInput(focusedInput) }}
                startDatePlaceholderText="A partir de"
                endDatePlaceholderText="Hasta el"
                orientation={'horizontal'}
                numberOfMonths={2}
                isOutsideRange={(day: any) => (moment().diff(day) <= 0)}
              />
            </div>

          </div>
          <div className="col-4">
            <Select
              className={"react-select-container"}
              classNamePrefix={"react-select"}
              placeholder="Columnas..."
              value={filter.columns}
              isMulti
              options={COLUMNS}
              onChange={(item: any) => handleChangeFilter(item, 'columns')}
            />
          </div>
          <div className="col-4">
            <Select
              className={"react-select-container"}
              classNamePrefix={"react-select"}
              placeholder="Rango duración..."
              value={filter.durationRange}
              options={DURATION_RANGE}
              onChange={(item: any) => handleChangeFilter(item, 'durationRange')}
            />
          </div>
          {/* <div className="col-4">
            <Select
              className={"react-select-container"}
              classNamePrefix={"react-select"}
              placeholder="Rango población..."
              value={filter.populationRange}
              options={POPULATION_RANGE}
              onChange={(item: any) => handleChangeFilter(item, 'populationRange')}
            />
          </div> */}
          <div className="col-4">
            <Select
              className={"react-select-container"}
              classNamePrefix={"react-select"}
              placeholder="Comunidades autónomas..."
              value={filter.communities}
              options={communities}
              onChange={(item: any) => handleChangeFilter(item, 'communities')}
            />
          </div>
          <div className="col-4">
            <Select
              className={"react-select-container"}
              classNamePrefixqui={"react-select"}
              placeholder="Provincias..."
              value={filter.provinces}
              options={provinces}
              onChange={(item: any) => handleChangeFilter(item, 'provinces')}
            />
          </div>
          <div className="col-4">
            <SearchBox
              ref={searchInputReference}
              labelText={''}
              onChange={(value: any) => getClientsItems(value)}
              optionsText={customers}
              optionKey={"value"}
              optionValue={"label"}
              onSelect={(value: any) => handleChangeFilter(value, 'customer')}
              placeholder="Nombre de municipio"
            />
          </div>
          <div className="col-12">
            <UikButton
              style={{ 'marginRight': '3px' }}
              success
              onClick={() => {
                setPage(1);
                setReloadItems(true)
              }}
            >
              Aplicar
            </UikButton>
            <UikButton onClick={() => {
              setFilter({
                date: {
                  startDate: moment().subtract(1, 'month'),
                  endDate: moment()
                },
                columns: [
                  { label: 'Pueblo', value: 'town', type: 'text' },
                  { label: 'Nº de usuarios', value: 'users', type: 'text' },
                  { label: 'Usuarios nuevos', value: 'new_users', type: 'text' },
                  // { label: 'Usuarios Recurrentes', value: 'recurring_users', type: 'text' },
                  { label: 'Provincia', value: 'province', type: 'text' },
                  { label: 'Comunidad', value: 'community', type: 'text' },
                  { label: 'Duración de sesión', value: 'avg_session', type: 'text' },
                ],
                search: "",
                durationRange: "",
                populationRange: "",
                communities: null,
                provinces: null,
                customer: ''
              })
              cleanSearchBox();
              setPage(1);
              setReloadItems(true)
            }}>
              Limpiar
          </UikButton>

          </div>
        </div>
        <UikWidget margin>
          <UikContentItem>
            <Table
              rows={getRows()}
              columns={filter.columns ? filter.columns.map((c: any) => c.label).concat(['Acciones']) : []}
              keyRows={filter.columns ? filter.columns.map((c: any) => ({ key: c.value, column: c.value })).concat([{ key: 'buttons', column: '' }]) : []}
              handleOrder={(order: any) => handleOrder(order)}
            />
            <div className="container-pagination">
              <Pagination page={page} limit={totalPage} callback={(page: number) => changePage(page)} />
            </div>
          </UikContentItem>
        </UikWidget>
      </UikLayoutMain>
      <InfoModal
        className={modal.className}
        titleText={modal.title}
        alertText={modal.alert}
        mainText={modal.text}
        buttonText={modal.button}
        active={modal.show}
        onClickAccept={() => modal.handleAccept()}
        close={() => closeModal()}
      />
    </UikContainerVertical>
  );
}




export default withRouter(GlobalData);

