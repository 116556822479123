import styled from "styled-components";

export const SelectContainer = styled.div`
  min-width: 100px;
  .uik-btn{
    &__base {
      &:hover {
        border: 1px solid #248489;
        background: #FFFFFF;
        color: #248489;
      }
    }
  }

  
  .errorMessage{
    color: #E6492D;
    margin-top: 6px;
    line-height: 1.375rem;
  }

  .full-width {
    width: 100%;
  }


  &.error {
    .uik-select {
      &__valueRendered{
      border: 1px solid #E6492D;
      }
    }
  }
`;
