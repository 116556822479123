
import React, { useState, useEffect } from 'react'
import * as uik from './../../@uik';
import { inputValue, ValueForm } from '../../models/general.model';
import { isValidEmail, isValidPassword, isValidDocumentID, isValidPhone_ESP, isValidPhone, isValidNumber, isValidCard } from '../../utils/validations';
import { ERROR_MESSAGES } from '../../constants/errorMessages';
import Select from '../Select/Select';
import { isValidIban } from '../../utils/iban';
import InputFile from '../InputFile/InputFile';
import SearchBox from '../searchBox/SearchBox';
import Textarea from '../Textarea/Textarea';
import { Prompt } from 'react-router';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import IconComponent from '../Icon/Icon';

interface iFormProps {
  dataForm: inputValue;
  handleAccept: Function;
  buttonText: string;
  titleForm?: string;
  docType?: 'NIF' | 'NIE' | 'DNI' | 'PASP' | 'NIFP';
  keyDocType?: string;
  reloadForm?: boolean;
  setReloadForm?: Function;
  handleBack?: Function;
}

interface AttachedFile {
  name: string;
  size: string;
  creation_date: string;
  error: boolean;
  data: any;
}

const editorConfiguration = {
  toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'blockQuote', 'undo', 'redo']
}

const Form: React.FC<iFormProps> = ({ dataForm, buttonText, handleAccept,
  titleForm, docType, keyDocType, reloadForm, setReloadForm, handleBack }) => {

  const [data, setData] = useState<any>(dataForm);
  const [showPassword, setShowPassword] = useState<any>({});
  const [isBlocking, setIsBlocking] = useState<boolean>(false);

  useEffect(() => {

    if (reloadForm) {
      setData({ ...dataForm })
      setReloadForm && setReloadForm(false);
    }
  }, [reloadForm]);

  const { UikButton, UikDivider, UikInput,
    UikWidget, UikWidgetContent } = uik;

  const validateValue = (data: any, key: string, isOk: boolean, index?: number) => {
    const value = index !== undefined ? data[key].value[index] : data[key].value;
    if (data[key].required && value === '') {
      data[key].error = true;
      data[key].errorCode = 'required';
      isOk = false;
    } else if (data[key].type === 'email' && !isValidEmail(value)) {
      data[key].errorCode = 'invalid-email';
      data[key].error = true;
      isOk = false;
    } else if (data[key].type === 'doc-number' && !isValidDocumentID(value, keyDocType ? data[keyDocType].value : (docType || 'NIF'))) {
      data[key].errorCode = 'invalid-format';
      data[key].error = true;
      isOk = false;
    } else if (data[key].type.indexOf('password') > -1 && !isValidPassword(value)) {
      data[key].errorCode = 'invalid-password';
      data[key].error = true;
      isOk = false;
    } else if (data[key].type === 'confirm-password' && value !== data['password'].value) {
      data[key].errorCode = 'repeat-password';
      data[key].error = true;
      isOk = false;
    } else if (data[key].type === 'phone-spain' && !isValidPhone_ESP(value)) {
      data[key].errorCode = 'invalid-format';
      data[key].error = true;
      isOk = false;
    } else if (data[key].type === 'mobile-phone' && value !== '' && !isValidPhone(value)) {
      data[key].errorCode = 'invalid-format';
      data[key].error = true;
      isOk = false;
    } else if (data[key].type === 'number' && !isValidNumber(value)) {
      data[key].errorCode = 'number-error';
      data[key].error = true;
      isOk = false;
    } else if (data[key].max && data[key].max < parseFloat(value)) {
      data[key].errorCode = 'max-error';
      data[key].error = true;
      isOk = false;
    } else if (data[key].type === 'iban' && !isValidIban(value)) {
      data[key].errorCode = 'invalid-iban';
      data[key].error = true;
      isOk = false;
    } else if (data[key].type === 'card' && !isValidCard(value)) {
      data[key].errorCode = 'invalid-card';
      data[key].error = true;
      isOk = false;
    } else {
      data[key].errorCode = '';
      data[key].error = false;
    }
    return {
      data,
      isOk
    }
  }

  const validateForm = (): void => {
    let isOk = true;

    Object.keys(data).forEach((key: string) => {
      if (Array.isArray(data[key].value)) {
        data[key].value.forEach((v: any, index: number) => {
          const res = validateValue(data, key, isOk, index);
          data[key] = res.data[key];
          isOk = res.isOk;
          if (data[key].error) {
            return;
          }
        });
      } else {
        const res = validateValue(data, key, isOk)
        data[key] = res.data[key];
        isOk = res.isOk
      }
    });

    setData({ ...data });

    if (isOk) {
      setIsBlocking(false);
      handleAccept(data);
    }
  }

  const handleChange = (value: any, key: string, index?: number) => {
    if (!data[key].length || data[key].length.max >= value.length) {
      if (index !== undefined) {
        data[key].value[index] = value;
      } else {
        data[key].value = value;
      }
    }
    setIsBlocking(true)
    setData({ ...data });
  }

  const handleChangeBody = (ev: any, editor: any, key: string) => {

    data[key].value = editor.getData();
    setIsBlocking(true)
    setData({ ...data });
  };

  const handleShowPassword = (key: string) => {
    showPassword[key] = !showPassword[key];
    setShowPassword({ ...showPassword });
  }

  const setAddValue = (key: string) => {
    data[key].value.push('');
    setData({ ...data });
  }
  const setRemoveValue = (key: string, index: number) => {
    data[key].value.splice(index, 1);

    setData({ ...data });
  }

  const renderInputs = (key: string, input: ValueForm) => {
    //TODO: input date and file 
    switch (input.render) {
      case 'input':
        return (
          <div key={`input-${key}`} className={input.className}>
            <UikInput
              autocomplete="nope"
              label={input.label}
              placeholder={input.placeholder}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value}
              onChange={(ev: any) => handleChange(ev.target.value, key)}
            />
          </div>);
      case 'textarea':
        return (
          <div key={`input-${key}`} className={input.className}>
            <Textarea
              label={input.label}
              placeholder={input.placeholder}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value}
              onChange={(value: string) => handleChange(value, key)}
            />

            {input.length && input.length.max && (<p>{input.value.length}/{input.length.max}</p>)}
          </div>);
      case 'ckeditor':
        return (
          <div key={`input-${key}`} className={input.className}>
            {input.label && (
              <div className="label-container">
                <p>{input.label}</p>
              </div>
            )}
            <div className={`ckeditor-container ${ERROR_MESSAGES[input.errorCode] ? 'error' : ''}`}>
              <CKEditor
                config={editorConfiguration}
                editor={ClassicEditor}
                data={input.value}
                onChange={(event: any, editor: any) => handleChangeBody(event, editor, key)}
              />
            </div>
            {ERROR_MESSAGES[input.errorCode] && (
              <div className="ckeditor-error">
                <p>{`${ERROR_MESSAGES[input.errorCode]}`}</p>
              </div>
            )}
            {input.length && input.length.max && (<p>{input.value.length}/{input.length.max}</p>)}
          </div>);
      case 'array':
        return (
          <div key={`input-${key}`} className={input.className}>
            {input.value.map((v: string, index: number) => (
              <div key={`input-${key}-${index}`} className={`flex-container ${input.className}`.trim()}>
                <UikInput
                  label={input.label}
                  placeholder={input.placeholder}
                  errorMessage={ERROR_MESSAGES[input.errorCode]}
                  value={v}
                  onChange={(ev: any) => handleChange(ev.target.value, key, index)}

                />
                <UikButton
                  style={{ 'marginTop': '38px' }}
                  onClick={() => setRemoveValue(key, index)}
                  error
                >
                  Eliminar
              </UikButton>
              </div>
            ))}
            <UikButton
              onClick={() => setAddValue(key)}
            >
              Añadir
            </UikButton>
            {input.value.length === 0 && input.errorCode && (
              <div className="container-error">
                {ERROR_MESSAGES[input.errorCode]}
              </div>
            )}
          </div>
        )
      case 'select':
        return (
          <div key={`input-${key}`} className={input.className}>
            <Select
              position="bottomRight"
              className={input.error ? 'error' : ''}
              label={input.label || ''}
              options={input.options || []}
              placeholder={input.placeholder || ''}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value.value ? [input.value] : ''}
              onChange={(value: any) => handleChange(value, key)}
            />
          </div>);
      case 'search':
        return (
          <div key={`input-${key}`} className={input.className}>
            <SearchBox
              required={input.required}
              onChange={(value: any) => input.onSearch && input.onSearch(value)}
              labelText={input.label || ''}
              errorCode={ERROR_MESSAGES[input.errorCode]}
              optionsText={input.options || []}
              optionKey={"value"}
              optionValue={"label"}
              initialValue={input.value.label ? input.value.label : ''}
              onSelect={(value: any) => handleChange(value, key)}
              placeholder={input.placeholder || ''}
            />
          </div>);
      case 'password':
        return (
          <div key={`input-${key}`} className={input.className}>
            <UikInput
              autocomplete="nope"
              label={input.label}
              placeholder={input.placeholder}
              type={showPassword[key] ? "text" : "password"}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value}
              onChange={(ev: any) => handleChange(ev.target.value, key)}
              icon={(
                <IconComponent
                  name="IconEyeGray"
                  onClick={() => handleShowPassword(key)}
                  className={[showPassword[key] ? 'active-icon' : 'inactive-icon', 'pointer'].join(' ')}
                />
              )}
              iconPosition="right"
            />
          </div>);
      case 'file':
        return (
          <div key={`input-${key}`} className={input.className}>
            <InputFile
              label={input.label}
              placeholder={input.placeholder || ''}
              required={input.required}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value ? input.value.name : ''}
              onChange={(files: AttachedFile[]) => handleChange(files[0], key)}
              accept={input.accept || ["application/pdf", "image/*"]}
            />
            {input.info && (
              <p>{input.info}</p>
            )}
            {input.picture && (
              <div className="picture-container flex-container">
                <img src={input.picture} alt="picture" />
                {input.hasDelete && (
                  <UikButton
                    style={{ 'marginTop': '38px', 'marginLeft': '10px' }}
                    onClick={() => input.hasDelete && input.hasDelete(input.keyDelete)}
                    error
                  >
                    Eliminar
                </UikButton>
                )}
              </div>
            )}
          </div>
        )
      default:
        return (
          <div key={`input-${key}`} className={input.className}>
            <UikInput
              autocomplete="nope"
              label={input.label}
              placeholder={input.placeholder}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value}
              onChange={(ev: any) => handleChange(ev.target.value, key)}
            />
          </div>);
    }
  }

  return (
    <UikWidget>
      <UikWidgetContent>
        <h3>{titleForm}</h3>
      </UikWidgetContent>
      <UikDivider />
      <form onSubmit={(ev: any) => ev.preventDefault()}>
        <UikWidgetContent>
          <div className="grid">
            {Object.keys(dataForm).length > 0 && (
              Object.keys(dataForm).map((key: string) => (
                renderInputs(key, dataForm[key])
              ))
            )}
          </div>

        </UikWidgetContent>
        <UikWidgetContent>
          <div className="button-container">
            <UikButton
              type="submit"
              success
              onClick={validateForm}
            >
              {buttonText}
            </UikButton>
          </div>
          {handleBack && (
            <div className="button-container">
              <UikButton
                className="margin-right"
                onClick={handleBack}
              >
                Atrás
                </UikButton>
            </div>
          )}
        </UikWidgetContent>
      </form>
      <Prompt
        when={isBlocking}
        message={(locationFuture: any) =>
          `¿Estás seguro que no quieres guardar antes de salir?`
        }
      />
    </UikWidget>
  );
}

export default Form;
