import { post, get, deleteReq, patch } from "./general.services";

export const createAboutUs = async (args: any) => {
  try {
    const res = await post({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "about-us"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const editAboutUs = async (args: any) => {

  try {
    const res = await patch({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `about-us/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getAboutUs = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `about-us${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    return [];
  }
}

export const getOneAboutUs = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `about-us/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}

export const deleteAboutUs = async (args: any) => {

  try {
    const res = await deleteReq({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `about-us/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}