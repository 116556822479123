export function currencyFormat(num = 0) {
  num = parseFloat(num, 2);
  return num
    .toFixed(2) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // use . as a separator
}

export function numberFormat(num = 0) {
  num = parseFloat(num);
  return num
    .toString()
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // use . as a separator
}

export function textWithPoints(text = '', limit = 0) {
  const points = "...";
  if (text && text.length > limit) {
    text = text.substring(0, limit) + points;
  }

  return text;
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}