import styled, { css } from 'styled-components';

export const CardContainer = styled.div<{ width: string | undefined }>`
  background: rgba(222, 75, 75, 0.15);

  &.active {
    background: rgba(75, 222, 80, 0.15);
  }
  
  &.inactive {
    background: rgba(222, 75, 75, 0.15);
  }
  
  &.pending {
    background: rgba(222, 211, 75, 0.15);
  }

  
  ${props => props.width ? (css`
  width: ${props.width}
  `) : ''}
  
  .card-body {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #4A4B4E;
      text-align: center;
      padding: 15px 30px;

      span {
        font-weight: 900;
      }
    }
  }

  .card-footer {
    bottom: 0;
    p {
      padding: 0 0 21px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      text-decoration-line: underline;
      color: #248489;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
    }
    
  }
  
`;
