import React, { useState, useContext } from 'react';

import { CardContainer } from './card-comparator-style';
import * as uik from '../../@uik';
import { getCustomers } from '../../services/customer.services';
import GeneralContext from '../../context/general.context';
import SearchBox from '../searchBox/SearchBox';

interface iCardsProps {
  title: string;
  text: string;
  handleClick: Function;
}

const CardComparator: React.FC<iCardsProps> = ({ title, text, handleClick }) => {
  const [value, setValue] = useState<any>('');
  const [options, setOptions] = useState<any[]>([]);
  const { UikDivider, UikButton } = uik;

  let searchWaiter: any = null;
  const general = useContext(GeneralContext);

  const getClientsItems = async (filter?: string) => {

    if (searchWaiter) {
      clearInterval(searchWaiter);
    }
    searchWaiter = setTimeout(async () => {
      const params = [];

      if (filter) {
        params.push(`filter=${filter}`);
      }

      const res: any = await getCustomers({
        params,
        loader: false,
        setLoading: general.setLoading
      });
      const customers = res.results ? res.results.map((i: any) => ({ label: i.town ? i.town.name : i.username, value: i.id })) : [];

      setOptions(customers);
    }, 750);

  }
  return (
    <CardContainer>
      <div className="card-header">
        <p>{title}</p>
      </div>
      <UikDivider className="card-divider" />
      <div className="card-body">

        <p>{text}</p>

        <div className="card-inline">
          <div className={"container-input"}>
            <SearchBox
              labelText={''}
              onChange={(value: any) => getClientsItems(value)}
              optionsText={options}
              optionKey={"value"}
              optionValue={"label"}
              onSelect={(value: any) => setValue({ ...value })}
              placeholder="Nombre de municipio"
            />
          </div>
          <UikButton success onClick={() => handleClick(value)}>
            Comparar
          </UikButton>
        </div>

      </div>

    </CardContainer >
  );
}

export default CardComparator;