export const COLUMNS = [
  { label: 'Pueblo', value: 'town', type: 'text' },
  { label: 'Provincia', value: 'province', type: 'text' },
  { label: 'Comunidad', value: 'community', type: 'text' },
  { label: 'Nº de usuarios', value: 'users', type: 'text' },
  { label: 'Usuarios nuevos', value: 'new_users', type: 'text' },
  { label: 'Usuarios Recurrentes', value: 'returning_users', type: 'text' },
  { label: 'Nº de visitas', value: 'pageviews', type: 'text' },
  { label: 'Duración de sesión', value: 'avg_session', type: 'text' },
];

export const  DURATION_RANGE = [
  {label: 'Ninguno', max: '', min: '', value: ''},
  {label: 'Menos de 10 segundos', max: '10', min: '', value: '>10'},
  {label: 'Entre 10 y 30 segundos', min: '10', max:'30', value: '10-30'},
  {label: 'Más de 60 segundos', min: '60', max: '', value: '>60'},
  {label: 'Más de 120 segundos', min: '120', max: '', value: '>120'}
];


export const  POPULATION_RANGE = [
  {label: '500 - 1.000', value: '500-1.000', },
  {label: '1.000 - 10.000', value: '1.000-10.000'},
  {label: '10.000 - 20.000', value: '10.000-20.000'},
  {label: '20.000 - 50.000', value: '20.000-50.000'},
  {label: '50.000 - 100.000', value: '50.000-100.000'},
  {label: '100.000 - 500.000', value: '100.000-500.000'},
]