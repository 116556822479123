import React, { useState, useContext } from 'react'

import * as uik from './../../@uik';

import GeneralContext from './../../context/general.context'
import { iSettingsUser, iSettingsPassword } from '../../models/users.models';
import TopBar from '../../components/TopBar/TopBar';
import { RouteComponentProps, withRouter } from 'react-router';
import { editUser, editPassword } from '../../services/user.services';

import InfoModal from '../../components/InfoModal/InfoModal';
import Form from '../../components/Form/Form';
import IconComponent from '../../components/Icon/Icon';

import { iModal } from '../../models/general.model';
import { images } from '../../assets/images';


interface RouteParams {
  id: string
}

interface iSettingsProps extends RouteComponentProps<RouteParams> { }

const Settings: React.FC<iSettingsProps> = ({ history, match }) => {

  const [user, setUser] = useState<iSettingsUser>({
    username: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'email',
      placeholder: 'correo...',
      label: 'Correo',
      render: 'input',
      className: 'col-12'
    }
  });

  const [changePassword, setChangePassword] = useState<iSettingsPassword>({
    password: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'password',
      placeholder: '......',
      label: 'Nueva contraseña',
      render: 'password',
      className: 'col-12'
    },
    confirmPassword: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'confirm-password',
      placeholder: '......',
      label: 'Confirmar contraseña',
      render: 'password',
      className: 'col-12'
    },
    actualPassword: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'password',
      placeholder: '......',
      label: 'Contraseña actual',
      render: 'password',
      className: 'col-12'
    }
  });

  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });

  const title: string = 'Configuración';

  const general = useContext(GeneralContext);

  const handleAcceptPassword = async (dataForm: iSettingsPassword) => {
    setChangePassword({ ...dataForm });
    const data = {
      newPassword: dataForm.password.value,
      verifyPassword: dataForm.confirmPassword.value,
      currentPassword: dataForm.actualPassword.value,
    }
    try {
      await editPassword({
        data,
        loader: true,
        setLoading: general.setLoading,
      });
      setModal({
        code: 'ok',
        text: 'Se ha cambiado la contraseña correctamente.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Info',
        alert: '',
        className: '',
        img: images.IconCheckCircle
      });
    } catch (err) {
      setModal({
        code: (err.response && err.response.status) ? err.response.status : 'err-local',
        text: (err.response && err.response.description) ? err.response.description : 'Error inesperado, inténtelo más tarde.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Error',
        alert: '',
        className: 'error',
        img: images.IconXCircle
      });

    }

  }

  const handleAcceptUser = async (dataForm: iSettingsUser) => {
    setUser({ ...dataForm });
    const data = {
      username: dataForm.username.value
    }
    try {
      await editUser({
        data,
        loader: true,
        setLoading: general.setLoading,
      });
      setModal({
        code: 'ok',
        text: 'Se ha cambiado el email correctamente.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Info',
        alert: '',
        className: '',
        img: images.IconCheckCircle
      });
    } catch (err) {
      setModal({
        code: (err.response && err.response.status) ? err.response.status : 'err-local',
        text: (err.response && err.response.description) ? err.response.description : 'Error inesperado, inténtelo más tarde.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Error',
        alert: '',
        className: 'error',
        img: images.IconXCircle
      });
    }
  }

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: '',
      img: ''
    })
  }

  const { UikTopBarSection, UikTopBarTitle, UikContainerVertical, UikLayoutMain, UikWidgetContainer } = uik;

  return (
    <UikContainerVertical className={"page"}>
      <TopBar>
        <UikTopBarSection>
          <UikTopBarTitle>
            <IconComponent name="IconSettings" className={"svg-black"} />
            {title}
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <UikWidgetContainer>
          <Form
            titleForm="Cambio de email"
            buttonText="Guardar"
            handleAccept={handleAcceptUser}
            dataForm={user}
          />
          <Form
            titleForm="Cambio de contraseña"
            buttonText="Guardar"
            handleAccept={handleAcceptPassword}
            dataForm={changePassword}
          />
        </UikWidgetContainer>
      </UikLayoutMain>
      <InfoModal
        className={modal.className}
        onClickAccept={modal.handleAccept}
        mainText={modal.text}
        titleText={modal.title}
        alertText={modal.alert}
        buttonText={modal.button}
        active={modal.show}
        img={modal.img}
        close={() => closeModal()}
      />
    </UikContainerVertical>
  );
}

export default withRouter(Settings);

