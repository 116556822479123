import React, { useContext } from 'react'
import classnames from 'classnames';

import * as uik from './../../@uik';

import GeneralContext from '../../context/general.context'
import { NavLink } from 'react-router-dom';
import IconComponent from '../Icon/Icon';

// @flow
interface iTopBarProps {
  children?: JSX.Element;
  className?: boolean;
  rightEl?: JSX.Element;
  handleButton?: Function;
  textButton?: string;
  handleButton2?: Function;
  textButton2?: string;
  disabledButton2?: boolean;
  notSuccess?: boolean;
  handleSearch?: Function;
  tabs?: any[];
}

const TopBar: React.FC<iTopBarProps> = ({ children, className, rightEl,
  handleButton, textButton, handleSearch, textButton2, handleButton2,
  tabs, notSuccess, disabledButton2 }) => {

  const general = useContext(GeneralContext);
  const {
    UikTopBar,
    UikTopBarSection,
    UikInput,
    UikDivider,
    UikButton,
    UikNavBurger } = uik;

  return (
    <UikTopBar
      className={classnames("wrapper", className)}
    >
      <UikButton
        className={"menuButton"}
        onClick={() => general.setIsMenuOpen(!general.isMenuOpen)}
        transparent
      >
        <UikNavBurger isOpen={general.isMenuOpen} />
      </UikButton>
      {children}
      <UikTopBarSection className={"actions"}>
        {tabs && (
          <div className="tabs">
            {tabs.map((tab: any) => (
              <NavLink to={tab.to} exact={tab.exact ? true : false}>
                {tab.text}
              </NavLink>
            ))}
          </div>
        )}
        {handleSearch && (
          rightEl || (
            <UikInput
              icon={(
                <IconComponent
                  name="Search"
                  className={"svg-search pointer"}
                  onClick={() => { }}
                />
              )}
              placeholder="Buscar..."
            />
          )
        )}
        {textButton && handleButton && handleSearch && (
          <UikDivider
            className={"actionMobileHidden"}
            direction="vertical"
            margin
          />
        )}
        {textButton && handleButton && (
          <UikButton
            className="btnAction"
            // Component={NavLink}
            success={!notSuccess}
            onClick={handleButton}
          // to="/buildings"
          >
            {textButton}
          </UikButton>
        )}

        {textButton2 && handleButton2 && (
          <UikButton
            style={{ 'marginLeft': '5px' }}
            className="btnAction"
            onClick={handleButton2}
            disabled={disabledButton2}
          // to="/buildings"
          >
            {textButton2}
          </UikButton>
        )}
      </UikTopBarSection>
    </UikTopBar>
  )
}
export default TopBar;
