import React, { useState, useContext, useEffect } from 'react'

import * as uik from '../../@uik';

import GeneralContext from '../../context/general.context'
import TopBar from '../../components/TopBar/TopBar';
import { RouteComponentProps, withRouter, Switch, Route } from 'react-router';
import { iCustomers } from '../../models/customers.models';
import { getOneCustomer } from '../../services/customer.services';

import CustomerDetail from './Detail/CustomerDetail';
import CustomerDetailBills from './Detail/CustomerDetailBills';
import CustomerDetailComparative from './Detail/CustomerDetailComparative';
import CustomerDetailReports from './Detail/CustomerDetailReports';
import { images } from '../../assets/images';

interface RouteParams {
  id: string
}

interface iCustomerDetailContainerProps extends RouteComponentProps<RouteParams> { }

const CustomerDetailContainer: React.FC<iCustomerDetailContainerProps> = ({ match, history }) => {
  const [item, setItem] = useState<iCustomers>({
    username: '',
    town: {},
    profile: {},
    contact: {},
    subscription: {},
    description: '',
    visits: [],
    places: [],
    reportMails: [],
    multimedia: [],
    id: 0,
    updatedAt: '',
    history: '',
  });

  const general = useContext(GeneralContext);

  useEffect(() => {

    if (match.params.id) {
      getItem();
    }
  }, []);

  const getItem = async () => {
    const res: any = await getOneCustomer({
      id: match.params.id,
      loader: true,
      setLoading: general.setLoading,
    });

    setItem({ ...res });
  }
  const { UikTopBarSection, UikTopBarTitle, UikContainerVertical } = uik;
  return (
    <UikContainerVertical className={"page"}>
      <TopBar tabs={[
        { text: 'Detalle', to: `/customers/detail/${item.id}`, exact: true },
        { text: 'Facturación', to: `/customers/detail/${item.id}/bills` },
        { text: 'Comparativa', to: `/customers/detail/${item.id}/comparative` },
        { text: 'Reportes', to: `/customers/detail/${item.id}/reports` },
      ]}>
        <UikTopBarSection>
          <UikTopBarTitle>
            <img
              src={images.IconArrowLeftGrey} className="img-back"
              alt="back"
              onClick={() => history.goBack()}
            />
            Detalle Cliente
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <Switch>
        <Route exact path="/customers/detail/:id" component={CustomerDetail} />
        <Route exact path="/customers/detail/:id/bills" component={CustomerDetailBills} />
        <Route exact path="/customers/detail/:id/reports" component={CustomerDetailReports} />
        <Route exact path="/customers/detail/:id/comparative" component={CustomerDetailComparative} />
      </Switch>
    </UikContainerVertical>
  );
}

export default withRouter(CustomerDetailContainer);


