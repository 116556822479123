import { post, get, put, deleteReq, putFormData } from "./general.services";
import axios from "axios";

export const createCustomer = async (args: any) => {
  try {
    const res = await post({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "customers"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const profileCustomer = async (args: any) => {
  try {
    const res = await putFormData({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}/profile-image`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const editCustomer = async (args: any) => {

  try {
    const res = await put({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const changeSubscription = async (args: any) => {

  try {
    const res = await put({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}/subscription`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getCustomers = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `customers${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    console.log(err);
    return [];
  }
}

export const getCustomerRanking = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}/ranking`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}

export const getOneCustomer = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}

export const deleteCustomerPicture = async (args: any) => {

  try {
    const res = await put({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}/profile-image/${args.key}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const deleteCustomerMultimedia = async (args: any) => {

  try {
    const res = await put({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}/multimedia/${args.key}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const deleteCustomer = async (args: any) => {

  try {
    const res = await deleteReq({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `customers/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getCustomersInvoices = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `customers/${args.id}/invoices${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    console.log(err);
    return [];
  }
}

export const getCustomersReports = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `customers/${args.id}/reports${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    console.log(err);
    return [];
  }
}

export const getCustomersProvinces = async (args: any) => {

  try {
    const res: any = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: "customers/provinces",
    });

    return res.data.provinces;
  }
  catch (err) {
    return [];
  }
}

export const getCustomersCommunities = async (args: any) => {

  try {
    const res: any = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: "customers/communities",
    });

    return res.data.communities;
  }
  catch (err) {
    return [];
  }
}

export const getImage = async (args: any) => {
  args.setLoading(args.loader);
  try {

    const response = await axios({
      method: "GET",
      url: args.url,
      
    });
    args.setLoading(false);
    return response;
  }
  catch (err) {
    args.setLoading(false);
    throw err;
  }
}