import React from 'react';

import * as uik from '../../@uik';

import { CardContainer } from './card-delete-style';

interface iCardDeleteProps {
  title: string;
  textLeft: string;
  buttonText: string;
  handleClick: Function;
}

const CardDelete: React.FC<iCardDeleteProps> = ({ title, textLeft, buttonText, handleClick }) => {
  const { UikButton } = uik;
  return (
    <CardContainer>
      <div className="grid">
        <div className="col-6">
          <h3>{title}</h3>
          <p>{textLeft}</p>
        </div>
        <div className="col-6">
          <UikButton danger onClick={() => handleClick()}>
            {buttonText}
          </UikButton>
        </div>
      </div>

    </CardContainer>
  );
}

export default CardDelete;
