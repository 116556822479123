import React, { useState, useContext, SyntheticEvent } from 'react'

import * as uik from '../../@uik';

import { iDataReset } from '../../models/login.model';
import { isValidPassword } from '../../utils/validations';
import { resetPassword } from '../../services/login.services';

import GeneralContext from '../../context/general.context'
import { ERROR_MESSAGES } from '../../constants/errorMessages';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import IconComponent from '../../components/Icon/Icon';

export interface iResetPasswordProps extends RouteComponentProps { }


const ResetPassword: React.FC<iResetPasswordProps> = ({ history }) => {

  const [data, setData] = useState<iDataReset>({
    confirmPassword: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'password'
    },
    password: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'password'
    }
  });

  const [showPassword, setShowPassword] = useState<any>({ confirmPassword: false, password: false });

  const general = useContext(GeneralContext)


  const validateForm = (): boolean => {
    let isOk = true;

    Object.keys(data).forEach((key: string) => {
      if (data[key].required && data[key].value === '') {
        data[key].error = true;
        data[key].errorCode = 'required';
        isOk = false;
      } else if (data[key].type === 'password' && !isValidPassword(data[key].value)) {
        data[key].errorCode = 'invalid-password';
        data[key].error = true;
        isOk = false;
      } else if (key === 'confirmPassword' && data[key].value !== data['password'].value) {
        data[key].errorCode = 'repeat-password';
        data[key].error = true;
        isOk = false;
      } else {
        data[key].errorCode = '';
        data[key].error = false;
      }
    })

    setData({ ...data });
    return isOk;
  }

  const handleReset = async () => {
    const isOk = validateForm();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.has("setPassToken") ? urlParams.get("setPassToken") : 'none';
    if (isOk) {
      const dataSend = {
        token,
        verifyPassword: data.confirmPassword.value,
        newPassword: data.password.value,
      }
      try {
        const res = await resetPassword({
          loader: true,
          setLoading: general.setLoading,
          data: dataSend,
        })
        history.push('/');
      } catch (err) {
        data.confirmPassword.error = true;
        data.confirmPassword.errorCode = 'server-error';
        setData({ ...data });
      }
    }
  }

  const handleChange = (e: SyntheticEvent) => {

    const target = e.target as HTMLInputElement;
    data[target.name].value = target.value;
    setData({ ...data });
  }

  const { UikWidgetContent,
    UikWidget,
    UikInput,
    UikFormInputGroup,
    UikHeadline,
    UikHeadlineDesc,
    UikDivider,
    UikButton
  } = uik;

  return (
    <div className="pageWrapper">
      <UikWidget
        className="widgetWrapper"
      >
        <div className="content">
          <UikWidgetContent className="left">
            <UikHeadline>
              Reiniciar contraseña
          </UikHeadline>
            <UikHeadlineDesc>
              Cambia tu contraseña para que puedas acceder a tu cuenta
          </UikHeadlineDesc>
            <form className="login" onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
              <UikFormInputGroup>
                <UikInput
                  label="Password"
                  name="password"
                  placeholder="·····"
                  type={showPassword.password ? "text" : "password"}
                  errorMessage={ERROR_MESSAGES[data.password.errorCode]}
                  value={data.password.value}
                  onChange={handleChange}
                  icon={(
                    <IconComponent
                      name="IconEyeGray"
                      onClick={() => setShowPassword({ ...showPassword, password: !showPassword['password'] })}
                      className={[showPassword.password ? 'active-icon' : 'inactive-icon', 'pointer'].join(' ')}
                    />
                  )}
                  iconPosition="right"
                />
                <UikInput
                  label="Confirmar password"
                  name="confirmPassword"
                  placeholder="·····"
                  type={showPassword.confirmPassword ? "text" : "password"}
                  errorMessage={ERROR_MESSAGES[data.confirmPassword.errorCode]}
                  value={data.confirmPassword.value}
                  onChange={handleChange}
                  icon={(
                    <IconComponent
                      name="IconEyeGray"
                      onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword['confirmPassword'] })}
                      className={[showPassword.confirmPassword ? 'active-icon' : 'inactive-icon', 'pointer'].join(' ')}
                    />
                  )}
                  iconPosition="right"
                />
              </UikFormInputGroup>
              <UikDivider margin />
              <UikButton
                className="btnAction"
                type="submit"
                // Component={NavLink}
                success
                onClick={handleReset}
              // to="/buildings"
              >
                Entrar
          </UikButton>
            </form>
            <div className="login-footer">
              <NavLink to="/">
                Iniciar sesión
            </NavLink>
            </div>
          </UikWidgetContent>
          <div className="right">
            <h3 className="headline">
              <IconComponent name="IconGlobalData" className={"svg-none"} />
              Dashboard
          </h3>
            <p className="desc">
              Super Admin España Fascinante
          </p>
          </div>
        </div>
      </UikWidget>
      <p>
        Panel Administrador
    </p>
    </div>
  )
}

export default withRouter(ResetPassword);

