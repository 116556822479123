export const calcularIBAN = (ccc, pais) => {
  ccc = limpiar(ccc);
  pais = (pais === undefined ? 'es' : pais).toUpperCase();
  const cifras = ccc + valorCifras(pais) + "00";
  const resto = modulo(cifras, 97);
  return pais + cerosIzquierda(98 - resto, 2) + ccc;
}

// Ejemplo1: validarIBAN("ES00 1234 5678 0612 3456 7890") --> false
// Ejemplo2: validarIBAN("ES68 1234 5678 0612 3456 7890") --> true
export const isValidIban = (iban) => {
  if (iban === "") return true;
  iban = limpiar(iban);
  const pais = iban.substr(0, 2);
  const dc = iban.substr(2, 2);
  const cifras = iban.substr(4, 20) + valorCifras(pais) + dc;
  const resto = modulo(cifras, 97);
  return resto === 1;
}


//iban, funciones internas
const limpiar = (numero) => {
  return numero
    .replace(/IBAN/g, "")
    .replace(/ /g, "")
    .replace(/-/g, "");
}

const valorCifras = (cifras) => {
  const LETRAS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"; // A=10, B=11, ... Z=35
  let items = [];
  for (let i = 0; i < cifras.length; i++) {
    const posicion = LETRAS.indexOf(cifras[i]);
    items.push(posicion < 0 ? "-" : posicion);
  }
  return items.join("");
}

const modulo = (cifras, divisor) => {
  /*
    El entero más grande en Javascript es 9.007.199.254.740.990 (2^53)
    que tiene 16 cifras, de las cuales las 15 últimas pueden tomar cualquier valor.
    El divisor y el resto tendrán 2 cifras. Por lo tanto CUENTA como tope
    puede ser de 13 cifras (15-2) y como mínimo de 1 cifra.
  */
  const CUENTA = 10;
  const largo = cifras.length;
  let resto = 0;
  for (let i = 0; i < largo; i += CUENTA) {
    let dividendo = resto + "" + cifras.substr(i, CUENTA);
    resto = dividendo % divisor;
  }
  return resto;
}

const cerosIzquierda = (cifras, largo) => {
  cifras += '';
  while (cifras.length < largo) { cifras = '0' + cifras; }
  return cifras;
}