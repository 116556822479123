import styled from "styled-components";

export const SearchBoxContainer = styled.div`
  position: relative;

  &.show-list {
    z-index: 12;
    .input-box-main-list {
      display: block;
    }
  }

  &.show-tooltip {
    .input-box-topbar {
      z-index: 13;
    }

    .input-box-tooltip {
      display: block;
    }
  }

  &.error {
    .input-box-main-field {
      input {
        border: 1px solid #E6492D;
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: grey;
    }

    .input-box-main-field {
      background: grey;

      input {
        border-bottom: 1px solid grey;
        color: grey;
      }

    }
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    margin-bottom: 10px;
  }

  .input-box-topbar-label {
    color: grey;
    text-transform: uppercase;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-field {
    position: relative;

    &:hover {
      background: #f0fafd;
    }
  }

  input {
    border: 1px solid #EAEDF3;
    border-radius: 4px;
    display: block;
    width: 100%;
    background: white;
    padding: 10px 15px;
    transition: .2s all;
    outline: none;
    font-size: 0.875rem;

    &:focus {
      background: #ffffff;
      border-radius: 3px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
      border-bottom: none;
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 20px;
    z-index: 10;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 29;
    width: 260px;
    display: none;
  }

  .input-box-main-list {
    position: absolute;
    top: 100%;
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    background: #ffffff;
    width: 100%;
    color: blue;
    max-height: 250px;
    overflow-y: scroll;

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;

      &:hover {
        background: #f0fafd;
      }

      &.no-data {
        font-size: 14px;
        line-height: 20px;
        color: blue;
        padding: 15px;
      }
    }
  }

  .input-box-error {
    margin-top: 6px;
    line-height: 1.375rem;
    p {
      color: #E6492D;
    }
  }
`;
