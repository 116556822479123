import React, { useState, useContext, useEffect } from "react";

import { RouteComponentProps, withRouter } from "react-router";
import * as uik from "../../@uik";

import GeneralContext from "../../context/general.context";
import {
  editCustomer,
  createCustomer,
  getOneCustomer,
  profileCustomer,
  changeSubscription,
  deleteCustomerPicture,
  getCustomersProvinces,
  getCustomersCommunities
} from "../../services/customer.services";

import { iCustomerForm, iCustomers } from "../../models/customers.models";
import { iModal } from "../../models/general.model";

import TopBar from "../../components/TopBar/TopBar";
import InfoModal from "../../components/InfoModal/InfoModal";
import Form from "../../components/Form/Form";
import { getSubscriptions } from "../../services/subscription.services";
import { images } from "../../assets/images";
import { removeEmpty, trim } from "../../utils/object";

import MultifileUpload from "../../components/MultifileUpload/MultifileUpload";
import { SERVICES_URL } from "../../services/url";

interface RouteParams {
  id: string;
}

interface iCustomerFormProps extends RouteComponentProps<RouteParams> {}

const CustomerForm: React.FC<iCustomerFormProps> = ({ history, match }) => {
  const [customer, setCustomer] = useState<iCustomers>({
    username: "",
    town: {},
    profile: {},
    contact: {},
    subscription: {},
    id: -1,
    visits: [],
    places: [],
    reportMails: [],
    description: "",
    history: "",
    multimedia: [],
    updatedAt: ""
  });

  const [item, setItem] = useState<iCustomerForm>({
    townName: {
      value: "",
      error: false,
      errorCode: "",
      required: true,
      type: "text",
      render: "input",
      label: "Nombre pueblo",
      placeholder: "PUEBLO",
      className: "col-6"
    },
    townCode: {
      value: "",
      error: false,
      errorCode: "",
      required: true,
      type: "text",
      render: "input",
      label: "Código pueblo",
      placeholder: "AND04003",
      className: "col-6"
      // max: 32768
    },
    townMinPoblation: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "number",
      render: "input",
      label: "Mínimo de población",
      placeholder: "0",
      className: "col-6"
    },
    townMaxPoblation: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "number",
      render: "input",
      label: "Máximo de población",
      placeholder: "10000",
      className: "col-6"
    },
    contactPostalCode: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "text",
      render: "input",
      label: "Código postal",
      placeholder: "32001",
      className: "col-6"
    },
    street: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "text",
      render: "input",
      label: "Calle",
      placeholder: "Calle Desengaño, 21",
      className: "col-6"
    },

    townProvince: {
      value: "",
      error: false,
      errorCode: "",
      required: true,
      type: "text",
      render: "search",
      label: "Provincia",
      placeholder: "Almería",
      className: "col-6",
      options: [],
      onSearch: (filter: string) => {}
    },
    townCommunity: {
      value: "",
      error: false,
      errorCode: "",
      required: true,
      type: "text",
      render: "search",
      label: "Comunidad",
      placeholder: "Andalucía",
      className: "col-6"
    },
    nif: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "text",
      render: "input",
      label: "CIF",
      placeholder: "1234",
      className: "col-6"
    },
    username: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "email",
      render: "input",
      label: "Correo",
      placeholder: "email@email.com",
      className: "col-6"
    },
    contactName: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "text",
      render: "input",
      label: "contacto",
      placeholder: "José Martín",
      className: "col-6"
    },
    contactPhoneNumber: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "phone",
      render: "input",
      label: "teléfono",
      placeholder: "666666666",
      className: "col-12"
    },
    subscriptionId: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "text",
      render: "select",
      label: "Suscripción",
      placeholder: "suscripción...",
      className: "col-6",
      options: []
    },
    subscriptionFrequency: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "text",
      render: "select",
      label: "Módo de pago",
      placeholder: "módo de pago...",
      className: "col-6",
      options: [
        { label: "Mensual", value: "mensual" },
        { label: "Anual", value: "anual" }
      ]
    },
    file: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "text",
      render: "file",
      label: "Foto perfil",
      placeholder: "selecciona una imagen...",
      accept: ["image/x-png,image/jpeg"],
      className: "col-12",
      picture: "",
      keyDelete: "",
      hasDelete: (picture: string) => handleDeletePicture(picture)
    }
  });

  const [subscriptionOld, setSubscriptionOld] = useState<any>("");

  const [historyTown, setHistoryTown] = useState<any>({
    history: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "text",
      render: "textarea",
      label: "Historia",
      placeholder: "El pueblo tiene...",
      className: "col-12",
      length: {
        max: 25000
      }
    }
  });

  const [descriptionTown, setDescriptionTown] = useState<any>({
    description: {
      value: "",
      error: false,
      errorCode: "",
      required: false,
      type: "text",
      render: "textarea",
      label: "Descripción",
      placeholder: "El pueblo tiene...",
      className: "col-12",
      length: {
        max: 25000
      }
    }
  });

  const [visits, setVisits] = useState<any>({
    items: {
      value: [""],
      error: false,
      errorCode: "",
      required: false,
      type: "text",
      render: "array",
      label: "lugar",
      placeholder: "lugar...",
      className: "col-12"
    }
  });

  const [places, setPlaces] = useState<any>({
    items: {
      value: [""],
      error: false,
      errorCode: "",
      required: false,
      type: "text",
      render: "array",
      label: "visita",
      placeholder: "visita...",
      className: "col-12"
    }
  });

  const [multimedia, setMultimedia] = useState<any>([]);

  const [reports, setReports] = useState<any>({
    items: {
      value: [""],
      error: false,
      errorCode: "",
      required: false,
      type: "email",
      render: "array",
      label: "emails",
      placeholder: "emails...",
      className: "col-12"
    }
  });

  const [reloadItem, setReloadItem] = useState<boolean>(true);
  const [reloadForm, setReloadForm] = useState<boolean>(true);
  const [modal, setModal] = useState<iModal>({
    code: "",
    text: "",
    button: "",
    handleAccept: () => {},
    show: false,
    alert: "",
    title: "",
    className: "",
    img: ""
  });

  const [titleForm, setTitleForm] = useState<string>("Crear cliente");

  // const title: string = match.params.id ? 'Editar cliente' : 'Crear cliente';
  const isEdit: boolean = !!match.params.id;

  const general = useContext(GeneralContext);

  useEffect(() => {
    getSubscriptionsItems();
    getProvinces();
    getCommunities();
  }, []);

  useEffect(() => {
    if (reloadItem && match.params.id) {
      getItem();
      setReloadItem(false);
    }
  }, [reloadItem]);

  const getProvinces = async () => {
    const res = await getCustomersProvinces({
      loader: true,
      setLoading: general.setLoading
    });

    const resProvinces = res.map((item: any) => ({
      label: item.name,
      value: item.code
    }));

    item["townProvince"].options = resProvinces;
  };

  const getCommunities = async () => {
    const res = await getCustomersCommunities({
      loader: true,
      setLoading: general.setLoading
    });

    const resCommunities = res.map((item: any) => ({
      label: item.name,
      value: item.code
    }));

    item["townCommunity"].options = resCommunities;
  };

  const getItem = async () => {
    const res: any = await getOneCustomer({
      id: match.params.id,
      loader: true,
      setLoading: general.setLoading
    });

    Object.keys(item).forEach((key: string) => {
      item[key].value = res[key] || "";
    });

    item["townName"].value = res.town ? res.town.name : "";
    item["townCode"].value = res.town ? res.town.code : "";
    item["townProvince"].value = {
      value: res.town ? res.town.provinceCode : "",
      label: res.town ? res.town.province : ""
    };
    item["townCommunity"].value = {
      value: res.town ? res.town.communityCode : "",
      label: res.town ? res.town.community : ""
    };
    item["townMaxPoblation"].value = res.town ? res.town.maxPoblation : "";
    item["townMinPoblation"].value = res.town ? res.town.minPoblation : "";
    item["contactName"].value = res.contact ? res.contact.name : "";
    item["contactPostalCode"].value = res.contact ? res.contact.postalCode : "";
    item["contactPhoneNumber"].value = res.contact
      ? res.contact.phoneNumber
      : "";
    item["nif"].value = res.nif || "";
    item["street"].value = res.street || "";
    item["file"].picture =
      res.profile && res.profile.src ? res.profile.src : "";
    item["file"].keyDelete =
      res.profile && res.profile.key ? res.profile.key : "";
    item["subscriptionId"].value = res.subscription
      ? { label: res.subscription.name, value: res.subscription.id }
      : "";
    item["subscriptionFrequency"].value = res.subscriptionFrequency
      ? {
          label: res.subscriptionFrequency === "anual" ? "Anual" : "Mensual",
          value: res.subscriptionFrequency
        }
      : "";

    historyTown.history.value = res.history || "";
    descriptionTown.description.value = res.description || "";
    places.items.value = res.places
      ? res.places.map((place: string) => place)
      : [];
    visits.items.value = res.visits
      ? res.visits.map((visit: string) => visit)
      : [];
    reports.items.value = res.reportMails
      ? res.reportMails.map((visit: string) => visit)
      : [];

    setCustomer(JSON.parse(JSON.stringify(res)));
    setTitleForm(`Editar cliente ${res.town ? `'${res.town.name}'` : ""}`);
    setPlaces({ ...places });
    setSubscriptionOld(res.subscription ? res.subscription.id : "");
    setVisits({ ...visits });
    setHistoryTown({ ...historyTown });
    setItem({ ...item });
    setMultimedia(res.multimedia);
    setReloadForm(true);
  };

  const getSubscriptionsItems = async () => {
    const res: any = await getSubscriptions({
      loader: true,
      setLoading: general.setLoading
    });

    res.sort((a: any, b: any) => {
      return a.minPoblation - b.minPoblation;
    });

    const subscriptions = res.map((i: any) => ({ label: i.name, value: i.id }));
    item["subscriptionId"].options = subscriptions;
    setItem({ ...item });
  };

  const handleDeletePicture = async (picture: string) => {
    setModal({
      code: "",
      button: "Borrar",
      handleAccept: () => deletePicture(picture),
      text: "Se eliminará de forma permanente la foto de perfil",
      show: true,
      title: "Eliminar foto de perfil",
      alert: "Esta acción no se puede deshacer.",
      className: "error",
      img: ""
    });
  };

  const deletePicture = async (picture: string) => {
    try {
      await deleteCustomerPicture({
        key: picture,
        id: match.params.id,
        loader: true,
        setLoading: general.setLoading
      });

      setModal({
        code: "success",
        button: "",
        handleAccept: () => {},
        text: "Se ha eliminado correctamente la foto de perfil.",
        show: true,
        title: "Eliminar foto de perfil",
        alert: "",
        className: "",
        img: images.IconCheckCircle
      });
      setReloadItem(true);
    } catch (err) {
      setModal({
        code: "error",
        button: "",
        handleAccept: () => {},
        alert: "",
        show: true,
        title: "Eliminar foto de perfil",
        text: "Error inesperado, inténtelo más tarde.",
        className: "error",
        img: images.IconXCircle
      });
    }
  };

  const handleAccept = async (dataForm: any) => {
    setItem({ ...dataForm });
    const data: any = trim({
      username: dataForm.username.value,
      nif: dataForm.nif.value,
      townName: dataForm.townName.value,
      townCode: dataForm.townCode.value,
      townMinPoblation: parseFloat(dataForm.townMinPoblation.value),
      townMaxPoblation: parseFloat(dataForm.townMaxPoblation.value),
      townProvince: dataForm.townProvince.value.label,
      townProvinceCode: dataForm.townProvince.value.value,
      townCommunity: dataForm.townCommunity.value.label,
      townCommunityCode: dataForm.townCommunity.value.value,
      contactName: dataForm.contactName.value,
      contactPostalCode: dataForm.contactPostalCode.value,
      street: dataForm.street.value,
      contactPhoneNumber: parseFloat(dataForm.contactPhoneNumber.value),
      subscriptionId: parseFloat(dataForm.subscriptionId.value.value),
      subscriptionFrequency: dataForm.subscriptionFrequency.value.value
    });

    if (data.username === customer.username) {
      delete data["username"];
    }

    try {
      let id = match.params.id;
      if (isEdit) {
        await editCustomer({
          id: match.params.id,
          data,
          loader: true,
          setLoading: general.setLoading
        });
        if (data.subscriptionId && data.subscriptionId !== subscriptionOld) {
          await changeSubscription({
            id: match.params.id,
            data: {
              subscriptionId: data.subscriptionId
            },
            loader: true,
            setLoading: general.setLoading
          });
        }
      } else {
        const res = await createCustomer({
          data,
          loader: true,
          setLoading: general.setLoading
        });
        id = res.id;
      }

      if (dataForm.file.value) {
        const formData = new FormData();
        formData.set("file", dataForm.file.value.data);
        await profileCustomer({
          id,
          data: formData,
          loader: true,
          setLoading: general.setLoading
        });
      }
      if (!isEdit) {
        history.push("/customers");
      } else {
        handleModalCorrect("Datos generales");
        setReloadItem(true);
      }
    } catch (err) {
      setModal({
        code:
          err.response && err.response.status
            ? err.response.status
            : "err-local",
        text:
          err.response && err.response.description
            ? err.response.description
            : "Error inesperado, inténtelo más tarde.",
        show: true,
        button: "",
        handleAccept: () => {},
        title: "Error",
        alert: "",
        className: "error",
        img: images.IconXCircle
      });
    }
  };

  const handleEditCustomer = async (data: any, titleModal: string) => {
    try {
      let id = match.params.id;
      if (isEdit) {
        await editCustomer({
          id,
          data,
          loader: true,
          setLoading: general.setLoading
        });
        handleModalCorrect(titleModal);
        setReloadItem(true);
      }
    } catch (err) {
      setModal({
        code:
          err.response && err.response.status
            ? err.response.status
            : "err-local",
        text:
          err.response && err.response.description
            ? err.response.description
            : "Error inesperado, inténtelo más tarde.",
        show: true,
        button: "",
        handleAccept: () => {},
        title: "Error",
        alert: "",
        className: "error",
        img: images.IconXCircle
      });
    }
  };

  const handleAcceptHistoryTown = (dataForm: any) => {
    setHistoryTown({ ...dataForm });

    const data = {
      history: dataForm.history.value
    };

    handleEditCustomer(data, "Historia del pueblo");
  };

  const handleAcceptDescriptionTown = (dataForm: any) => {
    setDescriptionTown({ ...dataForm });

    const data = {
      description: dataForm.description.value
    };

    handleEditCustomer(data, "Descripción del pueblo");
  };

  const handleAcceptVisit = (dataForm: any) => {
    setVisits({ ...dataForm });

    const data = {
      visits: dataForm.items.value.filter((item: string) => item !== "")
    };

    handleEditCustomer(data, "Visitas");
  };

  const handleAcceptPlaces = async (dataForm: any) => {
    setPlaces({ ...dataForm });

    const data = {
      places: dataForm.items.value.filter((item: string) => item !== "")
    };

    handleEditCustomer(data, "Lugares");
  };

  const handleAcceptReports = async (dataForm: any) => {
    setReports({ ...dataForm });

    const data = {
      reportMails: dataForm.items.value.filter((item: string) => item !== "")
    };

    handleEditCustomer(data, "Reportes");
  };

  const handleModalCorrect = (title: string) => {
    setModal({
      title,
      code: "success",
      button: "",
      handleAccept: () => {},
      text: "Se ha guardado correctamente los cambios.",
      show: true,
      alert: "",
      className: "",
      img: images.IconCheckCircle
    });
  };

  const closeModal = () => {
    setModal({
      code: "",
      button: "",
      handleAccept: () => {},
      text: "",
      show: false,
      title: "",
      alert: "",
      className: "",
      img: ""
    });
  };

  const {
    UikTopBarSection,
    UikTopBarTitle,
    UikContainerVertical,
    UikLayoutMain,
    UikWidgetContainer,
    UikWidget,
    UikWidgetContent
  } = uik;

  return (
    <UikContainerVertical className={"page"}>
      <TopBar
        notSuccess={true}
        // handleButton={isEdit ? () => history.push('/customers') : undefined}
        // textButton={isEdit ? "Atrás" : undefined}
      >
        <UikTopBarSection>
          <UikTopBarTitle>
            <img
              src={images.IconArrowLeftGrey}
              className="img-back"
              alt="back"
              onClick={() =>
                isEdit ? history.goBack() : history.push("/customers")
              }
            />
            {titleForm}
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <UikWidgetContainer>
          <Form
            titleForm={"Datos generales"}
            buttonText="Guardar"
            handleAccept={handleAccept}
            dataForm={item}
            reloadForm={reloadForm}
            setReloadForm={setReloadForm}
            // handleBack={isEdit ? undefined : () => history.push('/customers')}
          />
          {isEdit && [
            <Form
              titleForm={"Descripción básica"}
              buttonText="Guardar"
              handleAccept={handleAcceptDescriptionTown}
              dataForm={descriptionTown}
              reloadForm={reloadForm}
              setReloadForm={setReloadForm}
              // handleBack={() => history.push('/customers')}
            />,
            <Form
              titleForm={"Historia del pueblo"}
              buttonText="Guardar"
              handleAccept={handleAcceptHistoryTown}
              dataForm={historyTown}
              reloadForm={reloadForm}
              setReloadForm={setReloadForm}
              // handleBack={() => history.push('/customers')}
            />,
            <Form
              titleForm={"Lugares de intéres"}
              buttonText="Guardar"
              handleAccept={handleAcceptPlaces}
              dataForm={places}
              reloadForm={reloadForm}
              setReloadForm={setReloadForm}
              // handleBack={() => history.push('/customers')}
            />,
            <Form
              titleForm={"Páginas qué visitar"}
              buttonText="Guardar"
              handleAccept={handleAcceptVisit}
              dataForm={visits}
              reloadForm={reloadForm}
              setReloadForm={setReloadForm}
              // handleBack={() => history.push('/customers')}
            />,
            <Form
              titleForm={"Reportes"}
              buttonText="Guardar"
              handleAccept={handleAcceptReports}
              dataForm={reports}
              reloadForm={reloadForm}
              setReloadForm={setReloadForm}
              // handleBack={() => history.push('/customers')}
            />,
            <UikWidget margin>
              <UikWidgetContent>
                <h4>Imágenes</h4>
                <MultifileUpload
                  className="multifileupload-custom-style"
                  backendUrl={"customers/" + match.params.id + "/multimedia"}
                  endpointUrl={SERVICES_URL}
                  multimedia={multimedia || []}
                  id={match.params.id}
                  setReload={(reload: boolean) => setReloadItem(reload)}
                />
              </UikWidgetContent>
            </UikWidget>
          ]}
        </UikWidgetContainer>
      </UikLayoutMain>
      <InfoModal
        className={modal.className}
        onClickAccept={modal.handleAccept}
        mainText={modal.text}
        titleText={modal.title}
        alertText={modal.alert}
        buttonText={modal.button}
        active={modal.show}
        img={modal.img}
        close={() => closeModal()}
      />
    </UikContainerVertical>
  );
};

export default withRouter(CustomerForm);
