import React, { useState, useEffect, useContext } from 'react';
import Cookie from 'js-cookie';

// Type
import { IMultifileUpload } from './MultifileUpload-type';

// Style
import MultifileUploadContainer from './MultifileUpload-style';

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { deleteCustomerMultimedia } from '../../services/customer.services';
import GeneralContext from '../../context/general.context';

const pluginImagePreview = require("filepond-plugin-image-preview");
const pluginValidate = require("filepond-plugin-file-validate-type");

// Register the plugins
registerPlugin(pluginImagePreview, pluginValidate);

const MultifileUpload: React.FC<IMultifileUpload> = ({ backendUrl, endpointUrl, className, multimedia, id, setReload }) => {

  const [pondFile, setPondFile] = useState();
  const [files, setFiles] = useState<any>([]);


  const [showText, setShowText] = useState(true);

  const general = useContext(GeneralContext);

  const serverObject = {
    url: endpointUrl,
    load:
      async (uniqueFileId: any, load: any, error: any) => {
        try {
          fetch(uniqueFileId, { mode: 'no-cors' })
            .then(res => res.blob())
            .then(load)
            .catch(error)
        } catch (err) {
          error(err);
        }
      },
    onload: (res: any) => {
      return res
    },
    process: {
      url: backendUrl,
      method: 'PUT' as 'PUT',
      headers: {
        Authorization: Cookie.get('accessToken') ? `Bearer ${Cookie.get('accessToken')}` : ''
      },
      ondata: (form: any) => {
        // Here we extract the real file and send only it to Diego Webservice
        const realFile = form.getAll('file')[1];
        const formatedForm = new FormData();
        formatedForm.set('file', realFile);
        return formatedForm;
      }
    }
  }

  useEffect(() => {
    const labelContainer = document.querySelector('.filepond--root .filepond--drop-label') as HTMLElement;

    if (files === null || files.length === 0) {
      setShowText(true);

      if (labelContainer)
        labelContainer.style.minHeight = '250px';

    }
    else {
      setShowText(false);

      if (labelContainer)
        labelContainer.style.minHeight = '100px';
    }

  }, [files])

  const handleRemove = async (obj: any): Promise<boolean> => {
    try {
      await deleteCustomerMultimedia({
        id,
        key: obj.filename,
        loader: true,
        setLoading: general.setLoading
      });
      setReload(true);
      return true;
    } catch (err) {
      return false
    }
  }

  return (
    <MultifileUploadContainer className={className}>
      <FilePond
        ref={(ref: any) => (setPondFile(ref))}
        acceptedFileTypes={['image/png', 'image/jpeg']}
        files={files}
        name='file'
        allowMultiple={true}
        maxParallelUploads={1}
        maxFiles={10 - multimedia.length}
        server={serverObject}
        labelIdle={multimedia.length < 10 ? `
        <div class='multifile-label-text ${!showText ? 'hide' : ''}'>
          Arrastra o selecciona un máximo de ${10 - multimedia.length} imágenes.<br>
          <span class='red-text'>Máx. 5MP</span>
        </div>` : `<div class='multifile-label-text ${!showText ? 'hide' : ''}'>
          Borra alguna imagen si quieres poder subir más
        <span class='red-text'>Máx. 5MP</span>
      </div>`}
        onupdatefiles={(fileItems: any) => {
          // setFiles(fileItems)
        }
        }
        oninit={() => console.log('FilePond instance has initialised', pondFile)}
        beforeRemoveFile={(object: any) => handleRemove(object) ? true : false}
      >
      </FilePond>
      <div className="grid">
        {multimedia && multimedia.length > 0 && (
          multimedia.map((img: string, index: number) => {
            const filename = new URL(img).pathname.replace("/", "")
            return (
              <div key={`multimedia-${index}`} className="col-6 image-item">
                <div className="image-close" onClick={() => handleRemove({ filename })}>
                  <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.586 13l-2.293 2.293a1 1 0 0 0 1.414 1.414L13 14.414l2.293 2.293a1 1 0 0 0 1.414-1.414L14.414 13l2.293-2.293a1 1 0 0 0-1.414-1.414L13 11.586l-2.293-2.293a1 1 0 0 0-1.414 1.414L11.586 13z" fill="currentColor" fillRule="nonzero"></path>
                  </svg>
                </div>
                <img src={img} alt="multimedia" />
                <p>{filename}</p>
              </div>);
          }))}
      </div>
    </MultifileUploadContainer >
  )

}

export default MultifileUpload