import styled from 'styled-components';

const CardInfoSimpleContainer = styled.div`
  position: relative;
  padding: 40px 30px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #EAEDF3;
  border-radius: 4px;
  overflow: hidden;
  height: 175px;

  .card-simple-value {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #EAEDF3; 
    width: 100%;
    max-width: 195px;

    h1 {
      font-weight: 200;
    }
  }

  .card-simple-footer {
    h5 {
      color: #248489;
      font-weight: bold;
    }
  }

  .card-simple-backImage {
    position: absolute;
    right: 20px;

    &.bottom {
      bottom: -5px;
    }

    &.top {
      top: -5px;
    }
  }

`

export default CardInfoSimpleContainer;