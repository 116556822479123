import React, { useState } from 'react'

import * as uik from '../../@uik';

import SingleButton from '../SingleButton/single-button';
import { ThContainer, TdContainer, TheadContainer } from './Table-style';
import { iRow, iItem, iKeyRows } from '../../models/general.model';
import { images } from '../../assets/images';


interface iOrder {
  key: string;
  order: "ASC" | "DESC" | "";
}
interface iTableProps {
  rows: iRow[];
  columns: string[];
  keyRows: iKeyRows[];
  handleOrder?: Function;
  colorHead?: string;
}

const Table: React.FC<iTableProps> = ({ rows, columns, keyRows, handleOrder, colorHead }) => {


  const [order, setOrder] = useState<iOrder>({ key: '', order: '' });
  const { UikWidgetTable } = uik;

  const handleClickColum = (index: number) => {
    if (order.key === keyRows[index].column) {
      if (order.order === '') {
        order.order = 'ASC';
      } else if (order.order === 'ASC') {
        order.order = 'DESC';
      } else if (order.order === 'DESC') {
        order.order = '';
      }
    } else {
      order.order = 'ASC';
      order.key = keyRows[index].column;
    }
    setOrder({ ...order });

    if (handleOrder) {
      handleOrder(order);
    }
  }

  const renderValue = (item: iItem) => {
    switch (item.type) {
      case 'text':
        return item.value
      case 'img':

        return <a target="_blank" rel="noopener noreferrer" href={item.value}>{item.name}</a>
          ;
      case 'buttons':
        return (
          <SingleButton
            className="secondary"
            drop={true}
            icon={<img src={images.IconOther} alt="more" />}
          >
            {item.actions &&
              item.actions.map((action: any, key: number) => (
                <div key={`action-${key}`} className="single-button-drop__item" onClick={() => action.onClick()}>
                  {action.icon && (
                    <div className="single-button-drop__item__icon">
                      <SingleButton className="secondary" icon={action.icon} />
                    </div>
                  )}
                  <div className="single-button-drop__item__text">
                    <p>{action.label}</p>
                  </div>
                </div>
              ))}
          </SingleButton>
        )
      default:
        return item.value
    }
  }
  return (
    <UikWidgetTable>
      <TheadContainer colorHead={colorHead ? colorHead : ''}>
        <tr>
          {columns.map((c: string, index: number) => (
            <ThContainer
              className={`${(handleOrder && keyRows[index].column !== '') ? 'pointer' : ''} ${(order.key === keyRows[index].column && order.order !== '') ? `filter ${order.order === 'ASC' ? 'active' : ''}` : ''}`}
              key={`column - ${c} `}
              onClick={() => handleOrder ? keyRows[index].column !== '' && handleClickColum(index) : null}>
              {c}
            </ThContainer>
          ))}
        </tr>
      </TheadContainer>
      <tbody>
        {rows.length > 0 ? rows.map((row: iRow, index: number) => (
          <tr key={`row - ${index} `}>
            {keyRows.map((keyObj: iKeyRows, indexKey: number) => {
              return (
                <TdContainer
                  className={`${row[keyObj.key].mark ? 'mark' : ''}${row[keyObj.key].onClick ? ' pointer' : ''}`}
                  key={`row - ${index} -${indexKey} `}
                  onClick={() => {
                    const onClick: any = row[keyObj.key].onClick ? row[keyObj.key].onClick : () => { }
                    onClick();
                  }}
                >
                  {renderValue(row[keyObj.key])}
                </TdContainer>
              )
            })}
          </tr>
        )) : (
            <tr>
              {keyRows.map((keyObj: iKeyRows, indexKey: number) => (
                <TdContainer
                  key={`row-${indexKey}`}
                >
                  -
                </TdContainer>
              ))}
            </tr>
          )}
      </tbody>
    </UikWidgetTable >
  )
}
export default Table;
