import IconTrash from "../images/icon-trash.svg";
import IconDoubleFill from "../images/icon-double-fill.svg";
import IconWarningYellow from "../images/icon-warning-yellow.svg";
import IconInfoBlue from "../images/icon-info-blue.svg";
import IconCloseGrey from "../images/icon-close-grey.svg";
import IconDownload from "../images/icon-download.svg";
import IconMask from "../images/icon-mask.svg";
import IconUp from "../images/icon-up.svg";
import IconDown from "../images/icon-down.svg";
import IconArrowLeft from "../images/icon-arrow-left.svg";
import IconArrowRight from "../images/icon-arrow-right.svg";
import IconInfoGray from "../images/icon-info-gray.svg";
import IconCross from "../images/icon-cross.svg";
import IconExclamation from "../images/icon-exclamation.svg";
import IconMoney from "../images/icon-money.svg";
import IconCheckCircle from "../images/icon-check-circle.svg";
import IconXCircle from "../images/icon-x-circle.svg";
import IconCheck from "../images/icon-check.svg";
import picture from "../images/profile.png";
import sponsor1 from "../images/sponsor1.png";
import ImageNotFound from "../images/not-found-background.svg";
import World from "../images/world.svg";
import IconReportGeneral from "../images/icon-general.svg";
import IconCardReportDecorative from "../images/icon-card-report-decorative.svg";
import IconHome from "../images/icon-home.svg";
import IconClients from "../images/icon-clients.svg";
import IconBilling from "../images/icon-billing.svg";
import IconBillingBlack from "../images/icon-billing-black.svg";
import IconTariffs from "../images/icon-tariffs.svg";
import IconRoles from "../images/icon-roles.svg";
import IconLeads from "../images/icon-leads.svg";
import IconGlobalData from "../images/icon-global-data.svg";
import IconSettings from "../images/icon-settings.svg";
import IconServices from "../images/icon-services.svg";
import IconTestimonials from "../images/icon-testimonials.svg";
import IconFuncionalities from "../images/icon-funcionalities.svg";
import IconLegal from "../images/icon-legal.svg";
import IconAboutUs from "../images/icon-about-us.svg";
import IconEyeGray from "../images/eye-gray.svg";
import IconEdit from "../images/edit-3.svg";
import IconDelete from "../images/delete.svg";
import IconCheckGreen from "../images/check.svg";
import IconOther from "../images/other.svg";
import IconArrowLeftGrey from "../images/arrow-left.svg";

export const images = {
  picture,
  IconTrash,
  IconDoubleFill,
  IconWarningYellow,
  IconInfoBlue,
  IconCloseGrey,
  IconDownload,
  IconMask,
  IconUp,
  IconArrowLeft,
  IconArrowRight,
  IconInfoGray,
  IconCross,
  IconExclamation,
  IconMoney,
  IconDown,
  IconCheckCircle,
  IconXCircle,
  IconCheck,
  sponsor1,
  ImageNotFound,
  World,
  IconReportGeneral,
  IconCardReportDecorative,
  IconHome,
  IconClients,
  IconBilling,
  IconTariffs,
  IconRoles,
  IconLeads,
  IconGlobalData,
  IconSettings,
  IconServices,
  IconTestimonials,
  IconFuncionalities,
  IconLegal,
  IconAboutUs,
  IconEyeGray,
  IconEdit,
  IconDelete,
  IconCheckGreen,
  IconOther,
  IconArrowLeftGrey,
  IconBillingBlack,
};
