import styled, { css } from 'styled-components';

const MapBarIndicatorContainer = styled.div<{ color: string | undefined }>`

  #map-indicator-bar {
    width: 21px;
    display: flex; 
    justify-content: flex-start;
    border-top: 1px solid #9D9D9D;
    border-bottom: 1px solid #9D9D9D;

    #bar-indicator {
      height: 170px;
      width: 14px;

      /* ${
  (props) => props.color
    ?
    (css`
            background-color: ${props.color}
          `)
    :
    'background: linear-gradient(#2CDBBA, #D5F1F8);'

  } */
      
      background: linear-gradient(#2CDBBA, #D5F1F8);
      .indicator-top-text {
        position: absolute;
        top: -10px;
        left: 30px;
      }

      .indicator-bottom-text {
        position: absolute;
        bottom: -10px;
        left: 30px;
      }
    }

 }


`

export default MapBarIndicatorContainer;