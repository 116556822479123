import React, { useState, useContext, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router';

import { getServices, deleteServices } from '../../services/services.services';

import GeneralContext from '../../context/general.context'

import TopBar from '../../components/TopBar/TopBar';
import Table from '../../components/Table/Table';
import InfoModal from '../../components/InfoModal/InfoModal';
import IconComponent from '../../components/Icon/Icon';

import * as uik from '../../@uik';

import { iRow, iDelete, iKeyRows } from '../../models/general.model';
import { iServices } from '../../models/services.models';
import { textWithPoints } from '../../utils/text';
import { images } from '../../assets/images';

export interface iServicesProps extends RouteComponentProps { }

const Services: React.FC<iServicesProps> = ({ history }) => {

  const [items, setItems] = useState<iServices[]>([]);

  const [deleteItem, setDeleteItem] = useState<iDelete>({ modal: false, id: '' });
  const [reloadItems, setReloadItems] = useState<boolean>(true);

  const general = useContext(GeneralContext);


  useEffect(() => {
    if (reloadItems) {
      getItems();
      setReloadItems(false);
    }
  }, [reloadItems]);

  const getItems = async () => {
    const items = await getServices({
      loader: true,
      setLoading: general.setLoading
    });
    setItems(items);
  }

  const handleDeleteItem = async () => {
    try {
      await deleteServices({ id: deleteItem.id, loader: true, setLoading: general.setLoading });
      setDeleteItem({ modal: false, id: '' });
      setReloadItems(true);
    } catch (err) { }
  }

  const getRows = (): iRow[] => {
    return !items ? [] : items.map((item: any) => ({
      title: {
        value: item.title,
        type: 'text'
      },
      description: {
        value: textWithPoints(item.description, 30),
        type: 'text'
      },
      logo: {
        value: item.logo,
        name: new URL(item.logo).pathname.replace("/", ""),
        type: 'img'
      },
      buttons: {
        value: 'botones',
        actions: [
          {
            label: "Editar",
            icon: <img src={images.IconEdit} />,
            onClick: () => {
              history.push(`services/edit/${item.id}`);
            },
          },
          {
            label: "Borrar",
            icon: <img src={images.IconDelete} />,
            onClick: () => {
              setDeleteItem({ modal: true, id: item.id });
            },
          },
        ],
        type: 'buttons'
      }
    }))
  }

  const { UikTopBarSection, UikTopBarTitle, UikContainerVertical, UikLayoutMain,
    UikContentItem, UikWidget } = uik;

  const columns = ['Título', 'Descripción', 'Logo', 'Acciones'];

  const keyRows: iKeyRows[] = [{ key: 'title', column: 'title' }, { key: 'description', column: 'description' },
  { key: 'logo', column: 'logo' }, { key: 'buttons', column: '' }];

  return (
    <UikContainerVertical className={"page"}>
      <TopBar
        handleButton={() => history.push('services/create')}
        textButton="Crear"
      >
        <UikTopBarSection>
          <UikTopBarTitle>
            <IconComponent name="IconServices" className={"svg-black"} />
            Servicios
        </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <UikWidget margin>
          <UikContentItem>
            <Table
              rows={getRows()}
              columns={columns}
              keyRows={keyRows}
            />
          </UikContentItem>
        </UikWidget>
      </UikLayoutMain>
      <InfoModal
        className={"error"}
        titleText={"Eliminar servicio"}
        alertText={"Esta acción no se puede deshacer."}
        mainText={"Se eliminará de forma permanente."}
        buttonText={'Borrar'}
        active={deleteItem.modal}
        onClickAccept={() => handleDeleteItem()}
        close={() => setDeleteItem({ modal: false, id: '' })}
      />
    </UikContainerVertical>
  );
}




export default withRouter(Services);

