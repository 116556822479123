import styled from 'styled-components';

export const CardContainer = styled.div`
  position: relative;
  color: black;
  background: #FFFFFF;
  
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #EAEDF3;
  display: block;
  min-width: 220px;

  .card-header {
    width: 100%;
    height: 30.5px;
    margin: 30px 0px 30px 0px;
    h3 {
      text-align: center;
      
    }
    
  }
  .card-divider {
    margin: 0px 30.5px 24px 30.5px;
  }
  .card-body {
    max-width: 194px;
    margin: 0 auto;
    .card-body-text {
      text-align: center;

    }
    
    .card-body-price {
      word-break: break-word;
      margin: 30px auto;
      p {
        text-align: center;
        color: #4A4B4E;
        font-size: 36px;
        line-height: 50px;
        span  {
          font-size: 24px;
          line-height: 42px;

        }
      }
    }
    .card-button {
      margin-bottom: 48px;
      text-align: center;
    }
    
  }  
`;

export const DataContainer = styled.div`
  margin-top: 32px;
  text-align: center;
  .container-data {
    margin-top: 18px;    
    text-align: initial;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 18px;
  }

  .container-data:first-child {
    margin-top: 0;
    text-align: initial;
  }

  .active {
    p{
      color: #248489;
    }
  }
`;
