import { get } from "./general.services";

export const getOneReportsDownload = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}/reports/${args.file}`
    });

    return res.data;
  } catch (err) {
    return {};
  }
};

export const getReportsTrafficsUsers = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `reports/traffic/users${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getReportsTrafficsPages = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `reports/traffic/pages${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getReportsInterestPages = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `reports/interest/pages${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getTrafficCohortsData = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/traffic/cohorts${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getTrafficSessionsData = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/traffic/sessions${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

//Demographic services
export const getWorldMapData = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/demographic/countries${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getAgeChartData = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/demographic/age${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getGenderChartData = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/demographic/gender${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getLanguagesChartData = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/demographic/languages${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

//Interests services
export const getInterestCategories = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/interest/categories${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getInterestChannels = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/interest/channels${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getInterestSegmentsViews = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/interest/segments${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getInterestLinks = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/interest/links${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

//Custom reports
export const getReportsCustomRanking = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/custom/ranking${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getRankingTrafficTable = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/custom/ranking/traffic${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getRankingSessionTable = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/custom/ranking/session${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};

export const getRankingPageViewsnTable = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `reports/custom/ranking/pageviews${
        args.params ? "?" + args.params.join("&") : ""
      }`
    });

    return res.data;
  } catch (err) {
    return [];
  }
};
