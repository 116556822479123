import React from 'react';

import { CardContainer } from './card-style';

interface iCardsProps {
  title: string;
  texts: string[];
  textRight: string;
  textLeft: string;
  handleClick: Function;
  width?: string;
}

const Cards: React.FC<iCardsProps> = ({ title, texts, textRight, textLeft, handleClick, width }) => {

  return (
    <CardContainer width={width}>
      <div className="card-header">
        <p>{title}</p>
      </div>
      <div className="card-body">
        {texts.map((text: string, index: number) => (
          <div key={`text-${index}`} className="card-body-text">
            <p>{text}</p>
          </div>
        ))}
      </div>
      <div className="card-footer">
        <div className="card-left">
          <p>{textLeft}</p>
        </div>
        <div className="card-right" onClick={() => handleClick()}>
          <p>{textRight}</p>
        </div>
      </div>
    </CardContainer>
  );
}

export default Cards;
