import styled from "styled-components";

export const LoaderContainer = styled.div`
  display: block;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path, svg rect{
    fill: #0e0e0e;
  }
  
  .loader-holder {
    position: absolute;
    z-index: 99999;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
  }

  `;
