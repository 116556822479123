export const removeEmpty = obj => {
  let newObj = {};
  Object.keys(obj).forEach(prop => {
    if (obj[prop] || obj[prop] === false || obj[prop] === 0) {
      if (typeof obj[prop] === 'string') {
        newObj[prop] = obj[prop].trim();
      } else {
        newObj[prop] = obj[prop];
      }
    }
  });
  return newObj;
};

export const trim = obj => {
  let newObj = {};
  Object.keys(obj).forEach(prop => {
    if (typeof obj[prop] === 'string') {
      newObj[prop] = obj[prop].trim();
    } else {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};