import { post, get, put, deleteReq } from "./general.services";
import axios from "axios";
import Cookie from 'js-cookie';
import { SERVICES_URL } from "./url";

export const createBills = async (args: any) => {
  try {
    const res = await post({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "invoices"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const editBills = async (args: any) => {

  try {
    const res = await put({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `invoices/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getBills = async (args: any) => {
  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `invoices${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    return [];
  }
}

export const getOneBills = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `invoices/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}

export const getOneBillsDownload = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `invoices/download/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}

export const getBillsCsv = async (args: any) => {
  args.setLoading(args.loader);
  try {
    const headers = {
      "Content-Disposition": "attachment;filename=facturas.csv",
      "Content-Type": "application/octet-stream",
      Authorization: Cookie.get('accessToken') ? `Bearer ${Cookie.get('accessToken')}` : ''
    }
    const response = await axios({
      method: "GET",
      url: SERVICES_URL + 'invoices/csv',
      responseType: 'blob',
      headers
    });
    args.setLoading(false);
    return response;
  }
  catch (err) {
    args.setLoading(false);
    console.log(err);
    throw err;
  }
}
export const deleteBills = async (args: any) => {

  try {
    const res = await deleteReq({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `invoices/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}