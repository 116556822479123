import styled from 'styled-components';

export const SingleButtonContainer = styled.div`
  border-radius: 5px;
  position: relative;
  background: #f8fafd;
  color: #000019;
  text-align: center;
  .single-button-icon {
    padding: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .single-button-drop {
    z-index: 9;
    position: absolute;
    top: -100%;
    right: calc(100% + 8px);
    padding: 12px;
    background: white;
    box-shadow: 0px 0px 6px rgba(0, 0, 25, 0.08);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;

    .single-button-drop-veil   {
      position: fixed;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      cursor: pointer;
      white-space: nowrap;
      &:last-child {
        margin-bottom: 0px;
      }
      &__icon {
        width: 32px;
        margin-right: 12px;
      }
    }
  }
  &.small {
    width: 32px;
    .single-button-icon {
      padding: 5px;
    }
  }
  &.table {
    width: 32px;
    .single-button-icon {
      padding: 0px;
    }
  }
  &.openDrop {
    .single-button-drop {
      opacity: 1;
      visibility: visible;
    }
  }

  &.modal {
    width: 40px;
    height: 40px;
  }
  &.full-height {
    width: 48px;
    height: 48px;
  }
`;
