import React from 'react';

import { CardContainer } from './card-result-style';

interface iCardsProps {
  text: string;
  town: string;
  button?: string;
  handleButton?: Function;
}

const CardsResult: React.FC<iCardsProps> = ({ text, town, button, handleButton }) => {

  return (
    <CardContainer>

      <div className="card-body">
        <p>{text}</p>
        <h3>{town}</h3>
        {button && handleButton && (
          <p className="close" onClick={() => handleButton()}>{button}</p>)}
      </div>
    </CardContainer>
  );
}

export default CardsResult;
