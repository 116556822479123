import { post, get, deleteReq, patch } from "./general.services";

export const createLegalInfo = async (args: any) => {
  try {
    const res = await post({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "legal-info"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const editLegalInfo = async (args: any) => {

  try {
    const res = await patch({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `legal-info/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getLegalInfo = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `legal-info${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    return [];
  }
}

export const getOneLegalInfo = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `legal-info/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}

export const deleteLegalInfo = async (args: any) => {

  try {
    const res = await deleteReq({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `legal-info/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}