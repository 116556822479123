import styled, { css } from 'styled-components';

export const CardContainer = styled.div<{ width: string | undefined }>`
  position: relative;
  color: black;
  background: #EDE4C5;
  /* width: 347px; */
  height: 184px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  display: block;
  
  ${props => props.width ? (css`
  width: ${props.width}
  `) : ''}
  
  .card-header {
    p {
      padding: 30px 0px 0px 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #3E3F42;
    }
  }
  .card-body {
    margin-left: 30px;
  }

  .card-footer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    padding: 0px 30px 30px 30px;
    /* height: 100%;  */
    width:100%;
    .card-left {
      left: 0;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #9EA0A5;
        text-transform: uppercase;
      }
    }
    .card-right {
      right: 0;
      cursor: pointer;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        text-decoration-line: underline;
        color: #248489;
      }
    }
  }
  
`;
