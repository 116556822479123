import { post, get } from "./general.services";
import Cookie from 'js-cookie';
import { SERVICES_URL } from "./url";
import axios from "axios";

export const createMail = async (args: any) => {
  try {
    const res = await post({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "mail"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getMails = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `mail${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    return [];
  }
}

export const getMailsCsv = async (args: any) => {
  args.setLoading(args.loader);
  try {
    const headers = {
      "Content-Disposition": "attachment;filename=facturas.csv",
      "Content-Type": "application/octet-stream",
      Authorization: Cookie.get('accessToken') ? `Bearer ${Cookie.get('accessToken')}` : ''
    }
    const response = await axios({
      method: "GET",
      url: SERVICES_URL + 'mail/csv',
      responseType: 'blob',
      headers
    });
    args.setLoading(false);
    return response;
  }
  catch (err) {
    args.setLoading(false);
    throw err;
  }
}