import React, { useState } from "react";

import { TextareaContainer } from "./textarea-style";
import { TextareaProps, useTextareaType } from "./textarea-types";

const useTextarea: useTextareaType = (props: TextareaProps) => {

  const [value, setValue] = useState(props.value || "");
  const [isFocus, setFocus] = useState("");
  const handleChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  const handleBlur = (e: any) => {
    setValue(e.target.value);
    setFocus("");
    if (value.length > 0) {
      setFocus("focus");
    }
  };
  const handleFocus = (e: any) => {
    setFocus("focus");
  };

  return {
    isFocus,
    handleBlur,
    handleChange,
    handleFocus,
    value,
    setValue
  };
};

const Textarea: React.FC<TextareaProps> = (props: TextareaProps) => {
  const {
    disabled,
    className,
    icon,
    iconDisabled,
    required,
    label,
    value,
    errorMessage,
    placeholder,
    message,
  } = props;

  const inputActions = useTextarea(props);

  // const errorText = errorMessage ? ERROR_MESSAGES[errorMessage] : "";

  return (
    <TextareaContainer
      className={`${className} ${disabled ? "disabled" : ""} ${errorMessage ? "error" : ""} ${
        inputActions.value.length > 0 ? "complete" : ""
        } ${inputActions.isFocus} `}
    >
      {label && (
        <div className="textarea-topbar">
          <p>{label}</p>
        </div>
      )}
      <div className="textarea-main">
        <div className={`textarea-main-field ${required ? "required" : ""}`}>
          <textarea
            placeholder={placeholder}
            disabled={disabled}
            className="textarea"
            onChange={e => inputActions.handleChange(e)}
            onFocus={e => inputActions.handleFocus(e)}
            onBlur={e => inputActions.handleBlur(e)}
            value={value}
          />
        </div>
        <div className="textarea-icon">
          <img src={disabled ? iconDisabled : icon} alt="icon" />
        </div>
      </div>
      {errorMessage && (
        <div className="textarea-error">
          <p>{`${errorMessage}`}</p>
        </div>
      )}
      {message && (
        <div className="textarea-message">
          <p>{`${message}`}</p>
        </div>
      )}
    </TextareaContainer>
  );
};

export default Textarea;
