interface InterfaceErrorMessage {
  'required': string,
  'invalid-format': string,
  'invalid-password': string,
  'repeat-password': string,
  'dni-repeat': string,
  'invalid-prefix': string,
  'invalid-email': string,
  'field-empty': string,
  'form-error': string,
  'server-error': string,
  'login-error': string,
  'min-error': string,
  'max-error': string,
  'number-error': string,
  'invalid-iban': string,
  'invalid-card': string,
  'invalid-array': string,
  '': string,
  [key: string]: string
}

export const ERROR_MESSAGES: InterfaceErrorMessage = {
  'required': 'Campo obligatorio',
  'invalid-format': 'Formato no válido',
  'invalid-password': 'Mínimo 6 caractéres',
  'repeat-password': 'Deben ser iguales',
  'dni-repeat': 'DNI repetido',
  'invalid-prefix': 'Prefijo no válido',
  'invalid-email': 'Email no válido',
  'field-empty': 'Revisa que has rellenado todo correctamente',
  'form-error': 'Revisa que has rellenado todo correctamente',
  'server-error': 'Ha ocurrido un error inesperado, por favor ponte en contacto con el administrador',
  'login-error': 'Usuario no existe o password errónea',
  'min-error': 'El número no llega al mínimo',
  'max-error': 'El número se pasa del máximo',
  'number-error': 'Debe ser un número. En caso de haber un decimal, debe indicarse mediante un punto y no una coma.',
  'invalid-iban': 'Iban no válido',
  'invalid-card': 'Tarjeta no válido',
  'invalid-array': 'Mínimo un campo tiene que estar relleno',
  '': '',
}