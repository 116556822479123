import React, { useState, useContext, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router';

import GeneralContext from '../../../context/general.context'

import Table from '../../../components/Table/Table';
import InfoModal from '../../../components/InfoModal/InfoModal';

import * as uik from '../../../@uik';

import { iModal, iKeyRows } from '../../../models/general.model';
import { iBills } from '../../../models/bills.models';

import { iCustomers } from '../../../models/customers.models';
import { getOneCustomer, getCustomersReports } from '../../../services/customer.services';
import { images } from '../../../assets/images';
import { TOTAL_ITEMS } from '../../../constants/general';
import Pagination from '../../../components/Pagination/Pagination';
import { getOneReportsDownload } from '../../../services/reports.services';

interface RouteParams {
  id: string
}

export interface iCustomerBillsReports extends RouteComponentProps<RouteParams> { }

const CustomerDetailReports: React.FC<iCustomerBillsReports> = ({ match, history }) => {

  const [items, setItems] = useState<iBills[]>([]);
  const [item, setItem] = useState<iCustomers>({
    username: '',
    town: {},
    profile: {},
    contact: {},
    subscription: {},
    description: '',
    history: '',
    visits: [],
    places: [],
    reportMails: [],
    multimedia: [],
    id: 0,
    updatedAt: ''
  });

  const [reloadItems, setReloadItems] = useState<boolean>(true);
  const [order, setOrder] = useState<any>({ order: '', key: '' });
  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const general = useContext(GeneralContext);


  useEffect(() => {
    if (reloadItems && match.params.id) {
      getItem();

      setReloadItems(false);
    }
  }, [reloadItems]);

  const getItem = async () => {
    const res: any = await getOneCustomer({
      id: match.params.id,
      loader: true,
      setLoading: general.setLoading,
    });

    getItems();
    setItem({ ...res });
  }

  const getItems = async () => {

    const params = [`limit=${TOTAL_ITEMS}`];
    const offset = TOTAL_ITEMS * (page - 1);
    params.push(`offset=${offset}`);

    if (order.key !== '' && order.order) {
      params.push(`orderBy=${order.key}`);
      params.push(`order=${order.order}`);
    } else {
      params.push(`orderBy=report.created_at`);
      params.push(`order=desc`);
    }
    const res = await getCustomersReports({
      params,
      id: match.params.id,
      loader: true,
      setLoading: general.setLoading
    });

    setItems(res.results);
    setTotalPage(Math.ceil(res.total / TOTAL_ITEMS) || 1);
  }

  const handleOrder = (order: any) => {
    setOrder({ ...order });
    setReloadItems(true);
  }

  const handleDownloadItem = async (item: any) => {
    try {
      const res = await getOneReportsDownload({
        id: match.params.id,
        file: encodeURIComponent(item.link),
        loader: true,
        setLoading: general.setLoading
      });

      const dlnk: any = document.getElementById('downloadPDF');
      dlnk.href = `data:application/pdf;base64,${res.file}`;
      dlnk.download = `${item.id}-reporte.pdf`;//'facturas.pdf'; //file.name
      dlnk.click();
      closeModal();

    } catch (err) {

      setModal({
        code: 'error',
        button: '',
        handleAccept: () => { },
        alert: 'Error inesperado.',
        show: true,
        title: 'Descargar factura',
        text: 'Intente descargar la factura más tarde.',
        className: 'error',
        img: images.IconXCircle
      });
    }
  }

  const getRows = () => {
    return !items ? [] : items.map((item: any) => {

      const buttons = [{
        label: "Descargar",
        icon: <li className="oi icon-download"></li>,
        onClick: () => {
          setModal({
            code: item.id,
            button: 'Aceptar',
            handleAccept: () => handleDownloadItem(item),
            text: 'Se va a descargar la factura seleccionada',
            show: true,
            title: 'Descargar factura',
            alert: '',
            className: '',
            img: ''
          });
        }
      }
      ];
      return ({

        date: {
          value: item.date,
          type: 'text'
        },
        buttons: {
          value: 'botones',
          actions: buttons,
          type: 'buttons'
        }
      });
    }
    )
  }

  const changePage = (page: number) => {
    setPage(page);
    setReloadItems(true);
  }

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: '',
      img: ''
    })
  }

  const { UikLayoutMain, UikWidgetContent, UikWidget } = uik;

  const columns = ['Fecha', 'Acciones'];

  const keyRows: iKeyRows[] = [{ key: 'date', column: 'report.createdAt' }, { key: 'buttons', column: '' }];

  return (
    <>
      <UikLayoutMain className={"wrapper"}>
        <UikWidget margin>
          <UikWidgetContent>
            <h2>Historial de reportes</h2>
            <Table
              rows={getRows()}
              columns={columns}
              keyRows={keyRows}
              handleOrder={(order: any) => handleOrder(order)}
            />
            <div className="container-pagination">
              <Pagination page={page} limit={totalPage} callback={(page: number) => changePage(page)} />
            </div>
          </UikWidgetContent>
        </UikWidget>
      </UikLayoutMain>
      <InfoModal
        className={modal.className}
        titleText={modal.title}
        alertText={modal.alert}
        mainText={modal.text}
        buttonText={modal.button}
        active={modal.show}
        onClickAccept={() => modal.handleAccept()}
        close={() => closeModal()}
        img={modal.img}
      />
      <a id='downloadPDF' style={{ display: "none" }} />
    </>
  );
}




export default withRouter(CustomerDetailReports);

