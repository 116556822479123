import React, { useState, useContext, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";

import { getCustomers, deleteCustomer } from "../../services/customer.services";

import GeneralContext from "../../context/general.context";

import TopBar from "../../components/TopBar/TopBar";
import Table from "../../components/Table/Table";
import InfoModal from "../../components/InfoModal/InfoModal";
import Pagination from "../../components/Pagination/Pagination";
import IconComponent from "../../components/Icon/Icon";

import * as uik from "../../@uik";

import { iDelete, iModal, iKeyRows } from "../../models/general.model";
import { iCustomers } from "../../models/customers.models";
import { TOTAL_ITEMS } from "../../constants/general";
import { images } from "../../assets/images";

export interface iCustomersProps extends RouteComponentProps { }

const Customer: React.FC<iCustomersProps> = ({ history }) => {
  const [items, setItems] = useState<iCustomers[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [deleteItem, setDeleteItem] = useState<iDelete>({ modal: false, id: "" });
  const [reloadItems, setReloadItems] = useState<boolean>(true);
  const [order, setOrder] = useState<any>({ order: "", key: "" });
  const [filter, setFilter] = useState<string>("");
  const [modal, setModal] = useState<iModal>({
    code: "",
    text: "",
    button: "",
    handleAccept: () => { },
    show: false,
    alert: "",
    title: "",
    className: "",
  });

  const general = useContext(GeneralContext);

  useEffect(() => {
    if (reloadItems) {
      getItems();
      setReloadItems(false);
    }
  }, [reloadItems]);

  const getItems = async () => {
    const params = [`limit=${TOTAL_ITEMS}`];
    const offset = TOTAL_ITEMS * (page - 1);
    params.push(`offset=${offset}`);

    if (filter) {
      params.push(`filter=${filter}`);
    }

    if (order.key !== "" && order.order) {
      params.push(`orderBy=${order.key}`);
      params.push(`order=${order.order}`);
    } else {
      params.push(`orderBy=customer.updated_at`);
      params.push(`order=desc`);
    }

    const res = await getCustomers({
      params,
      loader: true,
      setLoading: general.setLoading,
    });

    setItems(res.results);
    setTotalPage(Math.ceil(res.total / TOTAL_ITEMS) || 1);
  };

  const handleOrder = (order: any) => {
    setOrder({ ...order });
    setReloadItems(true);
  };

  const handleDeleteItem = async () => {
    try {
      await deleteCustomer({ id: deleteItem.id, loader: true, setLoading: general.setLoading });
      setDeleteItem({ modal: false, id: "" });
      setModal({
        code: "success",
        button: "",
        handleAccept: () => { },
        text: "Se ha eliminado correctamente el cliente seleccionado.",
        show: true,
        title: "Eliminar cliente",
        alert: "",
        className: "",
        img: images.IconCheckCircle,
      });
      setReloadItems(true);
    } catch (err) {
      setModal({
        code: "error",
        button: "",
        handleAccept: () => { },
        alert: "Error inesperado.",
        show: true,
        title: "Eliminar cliente",
        text: "Intente eliminar el cliente más tarde.",
        className: "error",
        img: images.IconXCircle,
      });
    }
  };

  const getRows = () => {
    return !items
      ? []
      : items.map((item: any) => ({
        status: {
          value: <div className={item.status || "inactive"} />,
          type: "text",
          onClick: () => history.push(`/customers/detail/${item.id}`),
        },
        town: {
          value: item.town.name,
          type: "text",
          onClick: () => history.push(`/customers/detail/${item.id}`),
        },
        province: {
          value: item.town.province,
          type: "text",
          onClick: () => history.push(`/customers/detail/${item.id}`),
        },
        community: {
          value: item.town.community,
          type: "text",
          onClick: () => history.push(`/customers/detail/${item.id}`),
        },
        range: {
          value: item.town.minPoblation ? `${item.town.minPoblation} - ${item.town.maxPoblation}` : "-",
          type: "text",
          onClick: () => history.push(`/customers/detail/${item.id}`),
        },
        subscription: {
          value: item.subscription ? item.subscription.name : "Demo",
          type: "text",
          onClick: () => history.push(`/customers/detail/${item.id}`),
        },
        username: {
          value: item.username || "-",
          type: "text",
          onClick: () => history.push(`/customers/detail/${item.id}`),
        },
        buttons: {
          value: "botones",
          actions: [
            {
              label: "Ver detalle",
              icon: <img src={images.IconEyeGray} />,
              onClick: () => {
                history.push(`/customers/detail/${item.id}`);
              },
            },
            {
              label: "Editar",
              icon: <img src={images.IconEdit} />,
              onClick: () => {
                history.push(`/customers/edit/${item.id}`);
              },
            },
            {
              label: "Borrar",
              icon: <img src={images.IconDelete} />,
              onClick: () => {
                setDeleteItem({ modal: true, id: item.id });
              },
            },
          ],
          type: "buttons",
        },
      }));
  };

  const changePage = (page: number) => {
    setPage(page);
    setReloadItems(true);
  };

  const handleChangeSearch = (value: string) => {
    setFilter(value);
  };

  const closeModal = () => {
    setModal({
      code: "",
      button: "",
      handleAccept: () => { },
      text: "",
      show: false,
      title: "",
      alert: "",
      className: "",
      img: "",
    });
  };

  const {
    UikTopBarSection,
    UikTopBarTitle,
    UikContainerVertical,
    UikLayoutMain,
    UikContentItem,
    UikWidget,
    UikInput,
  } = uik;

  const columns = ["Estado", "Municipio", "Provincia", "Comunidad", "Rango", "Plan", "Contacto", "Acciones"];

  const keyRows: iKeyRows[] = [
    { key: "status", column: "customer.status" },
    { key: "town", column: "town.name" },
    { key: "province", column: "town.province" },
    { key: "community", column: "town.community" },
    { key: "range", column: "town.minPoblation" },
    { key: "subscription", column: "subscription.name" },
    { key: "username", column: "customer.username" },
    { key: "buttons", column: "" },
  ];

  return (
    <UikContainerVertical className={"page"}>
      <TopBar handleButton={() => history.push("/customers/create")} textButton="Crear">
        <UikTopBarSection>
          <UikTopBarTitle>
            <IconComponent name="IconClients" className={"svg-black"} />
            Clientes
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <div className="container-search">
          <UikInput
            placeholder={"Buscar..."}
            type={"text"}
            errorMessage={""}
            value={filter}
            onChange={(ev: any) => handleChangeSearch(ev.target.value)}
            onKeyDown={(ev: any) => {
              if (ev.key === "Enter") {
                setPage(1);
                setReloadItems(true);
              }
            }}
            icon={
              <IconComponent
                name="Search"
                className={"svg-search pointer"}
                onClick={() => {
                  setPage(1);
                  setReloadItems(true);
                }}
              />
            }
            iconPosition="right"
          />
        </div>
        <div className="container-legend">
          <div className="item-legend">
            <p>
              <span className="active" /> Activo
            </p>
          </div>
          <div className="item-legend">
            <p>
              <span className="inactive" /> Inactivo
            </p>
          </div>
          <div className="item-legend">
            <p>
              <span className="pending" /> Pendiente
            </p>
          </div>
        </div>
        <UikWidget margin>
          <UikContentItem>
            <Table
              rows={getRows()}
              columns={columns}
              keyRows={keyRows}
              handleOrder={(order: any) => handleOrder(order)}
            />
            <div className="container-pagination">
              <Pagination page={page} limit={totalPage} callback={(page: number) => changePage(page)} />
            </div>
          </UikContentItem>
        </UikWidget>
      </UikLayoutMain>
      <InfoModal
        className={"error"}
        titleText={"Eliminar cliente"}
        alertText={"Esta acción no se puede deshacer."}
        mainText={"Se eliminará de forma permanente y con ello todos los datos asociados."}
        buttonText={"Borrar"}
        active={deleteItem.modal}
        onClickAccept={() => handleDeleteItem()}
        close={() => setDeleteItem({ modal: false, id: "" })}
      />
      <InfoModal
        className={modal.className}
        titleText={modal.title}
        alertText={modal.alert}
        mainText={modal.text}
        buttonText={modal.button}
        active={modal.show}
        onClickAccept={() => modal.handleAccept()}
        close={() => closeModal()}
        img={modal.img}
      />
    </UikContainerVertical>
  );
};

export default withRouter(Customer);
