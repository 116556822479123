import { get, deleteReq, postFormData, patchFormData } from "./general.services";

export const createServices = async (args: any) => {
  try {
    const res = await postFormData({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "services"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const editServices = async (args: any) => {

  try {
    const res = await patchFormData({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `services/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getServices = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `services${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    return [];
  }
}

export const getOneServices = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `services/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}

export const deleteServices = async (args: any) => {

  try {
    const res = await deleteReq({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `services/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}