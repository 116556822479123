import { post, get, put, deleteReq } from "./general.services";

export const createUser = async (args: any) => {
  try {
    const res = await post({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "users"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const editUser = async (args: any) => {

  try {
    const res = await put({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `users/username`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const editPassword = async (args: any) => {

  try {
    const res = await put({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `users/password`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getUsers = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `users${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    return [];
  }
}

export const getOneUser = async (args: any) => {

  try {
    const res = await get({
      loader: args.loader,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `users/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}

export const deleteUser = async (args: any) => {

  try {
    const res = await deleteReq({
      loader: args.loader,
      setLoading: args.setLoading,
      endpoint: `users/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}