import React from 'react'
import { iGeneralContext } from '../models/context.models'

const GeneralContext = React.createContext<iGeneralContext>({
  loading: false,
  isLogged: false,
  isMenuOpen: false,
  user: {},
  setLoading: () => {},
  setUser: () => {},
  setIsLogged: () => {},
  setIsMenuOpen: () => {},
})

export const GeneralProvider = GeneralContext.Provider
export const GeneralConsumer = GeneralContext.Consumer
export default GeneralContext