import styled from 'styled-components';

export const CardContainer = styled.div`
  position: relative;
  color: black;
  background: #FFFFFF;
  
  height: 100%;
  min-height: 184px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #EAEDF3;
  display: block;

  .card-header {
    width: 100%;
    height: 40px;
    .card-header-left {
      padding-top: 22px;
      padding-left: 22px;
      float: left;
      img {
        height: 24px;
        width: 24px;
      }
    }
    .card-header-right {
      padding-top: 22px;
      padding-right: 22px;
      float: right;
      p {
        color: #868686;
      }
    }
  }
  .card-body {
    .card-body-position {
      text-align: center;
      margin-top: 15px;
      p {
        color: #4A4B4E;
        font-size: 48px;
        line-height: 50px;
      }
      span {
        color: #868686;
        font-size: 12px;
        line-height: 18px;
      }
    }
    
    .card-body-section {
      text-align: center;
      margin-top: 13px;
      p {
        text-decoration-line: underline;
        color: #248489;
        line-height: 18px;
        font-weight: 700;

      }
    }
  }  
`;
