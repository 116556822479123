import styled from 'styled-components';

export const CardContainer = styled.div`
  position: relative;
  color: black;
  background: #EDE4C5;
  
  height: 223px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  display: block;

  .card-body {
        p {
          padding-top: 30px;
          text-align: center;
          color: #3E3F42;
        }
        h3{
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #248489;
        }

        .close {
          color: #DE4B4B;
          text-decoration-line: underline;
          cursor: pointer;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
        }
  }  
`;
