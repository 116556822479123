import React from 'react';

import * as uik from './../../@uik';
import { SelectContainer } from './select-style';


interface iLayoutProps {
  label: string;
  onChange: Function;
  errorMessage: string;
  value: any;
  options: any[]
  placeholder: string;
  position?: string;
  className?: string;
  defaultValue?: any;
}

const Select: React.FC<iLayoutProps> = ({
  label, onChange, errorMessage, value,
  options, placeholder, position, className, defaultValue }) => {


  const { UikSelect } = uik;

  return (
    <SelectContainer className={className}>
      <UikSelect
        className={'full-width'}
        label={label}
        onChange={onChange}
        errorMessage={errorMessage}
        value={value}
        options={options}
        placeholder={placeholder}
        position={position}
        defaultValue={defaultValue}
      />
      <p className="errorMessage">{errorMessage}</p>
    </SelectContainer >
  );
}

export default Select;
