import React, { Component } from 'react';
import { InputFileContainer } from './input-files-style';

import * as uik from '../../@uik';

import { images } from '../../assets/images';
import { getDateFormat } from '../../utils/time';

interface AttachedFile {
  name: string;
  size: string;
  creation_date: string;
  error: boolean;
  data: any;
}

interface InputFileProps {
  className?: string;
  label?: string;
  required?: boolean;
  errorMessage?: string;
  value?: string;
  accept?: string[];
  placeholder: string;
  onChange?: (files: AttachedFile[]) => void;
  onConfirm?: (files: AttachedFile[]) => void;
}

interface InputFileState {
  selectedFiles: AttachedFile[];
}

export default class InputFile extends Component<InputFileProps, InputFileState> {
  state: InputFileState = {
    selectedFiles: [],
  };
  fileInput: any;

  componentDidMount() {
    document.body.classList.add('no-scroll');
  }
  componentWillUnmount() {
    document.body.classList.remove('no-scroll');
  }

  uploadFile(files: any) {
    const inputFiles: AttachedFile[] = [];

    for (const file of files) {
      let error = false;
      if (file.size > 1024 * 1024 * 2) {
        // max size 2MB
        error = true;
      }
      inputFiles.push({
        error,
        data: file,
        name: file.name,
        size: file.size,
        creation_date: getDateFormat(new Date(file.lastModified), 'dd MMM yyyy'),
      });
    }

    this.setState({ selectedFiles: inputFiles }, () => {
      const { onChange } = this.props;
      if (onChange) onChange(inputFiles);
    });
  }

  removeFile(item: any, index: number) {
    const { selectedFiles } = this.state;

    selectedFiles.splice(index, 1);

    this.setState({ selectedFiles }, () => {
      const { onChange } = this.props;
      if (onChange) onChange(selectedFiles);
    });
  }

  // DEPRECATED: not being used
  confirmFiles() {
    const { selectedFiles } = this.state;
    const { onConfirm } = this.props;

    const tempFiles = selectedFiles.slice();

    this.setState({ selectedFiles: [] }, () => {
      if (onConfirm) onConfirm(tempFiles);
    });
  }

  render() {
    const { UikContentTitle } = uik;
    const { className, label, required, errorMessage, value, accept, placeholder } = this.props;

    return (
      <InputFileContainer
        className={`${className} ${required && 'required'} ${errorMessage ? ' error' : ''}`}
      >
        {label && (
          <div className="input-file-message-label">
            <UikContentTitle>{label}</UikContentTitle>
          </div>
        )}
        <div className="input-file-message-container">
          <div className="input-file-message-drag">
            <form className="uploader" encType="multipart/form-data">
              <input
                type="file"
                name="file"
                id="file"
                onChange={e => this.uploadFile(e.target.files)}
                accept={accept ? accept.toString() : 'image/*'}
              />
              <div className="input-box-main">
                <div className="input-box-main-field">
                  <p>{value || placeholder}</p>
                </div>
                <div className="input-box-icon">
                  <img src={value ? images.IconCheck : images.IconMask} alt="icon" />
                </div>
              </div>
            </form>
          </div>
          {errorMessage ? (
            <div className="input-box-error">
              <p className="errorMessage">{errorMessage}</p>
            </div>
          ) : (
              <div className="input-file-message-disclaimer">
                <p>Los archivos no pueden superar los 2MB*</p>
              </div>
            )}
        </div>
      </InputFileContainer>
    );
  }
}
