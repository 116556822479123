import React from 'react';

// Type
import { ICardInfo, ITown } from './CardInfo-type';

// Style
import CardInfoContainer from './CardInfo-style';

// Constants
import { images } from '../../assets/images';

// Third Party components
import * as uik from '../../@uik';
import ReactTooltip from 'react-tooltip';
import Select from '../Select/Select';

const CardInfo: React.FC<ICardInfo> = (props: ICardInfo) => {
  const {
    headerTitle,
    headerTooltip,
    headerCustom,
    headerSelect,
    children,
    footerRadio,
    footerSelect,
    height,
    expand,
    towns
  } = props;

  // Get third party components
  const {
    UikSelect,
    UikToggle
  } = uik;

  return (
    <CardInfoContainer height={height} className={expand ? 'expand' : 'no-expand'}>

      <div className='card-header'>

        {
          (headerTitle) ?
            (
              <div className='card-title'>
                <h2>{headerTitle}</h2>
                <img
                  data-tip data-for={`title-${headerTitle.replace(/ /g, '-')}`}
                  src={images.IconInfoGray}
                  alt='info icon'
                />
                {headerTooltip && (
                  <ReactTooltip
                    id={`title-${headerTitle.replace(/ /g, '-')}`}
                    place="left"
                    type="dark"
                    effect="float"
                  >
                    <span>{headerTooltip}</span>
                  </ReactTooltip>
                )}
              </div>
            )
            :
            (
              <></>
            )
        }


        {
          (headerCustom) ?
            (
              <div className='card-custom'>
                {headerCustom}
              </div>
            )
            :
            (
              <></>
            )
        }

        <div className='card-options'>
          {
            (headerSelect) ?
              (
                <div className='card-select-options'>
                  <p>{headerSelect.leftText}</p>
                  <Select
                    className={"select"}
                    value={[headerSelect ? headerSelect.options.defaultValue : '']}
                    onChange={(value: any) => headerSelect.callback(value)}
                    options={headerSelect ? headerSelect.options.values : []}
                    errorMessage={""}
                    placeholder={""}
                    label={""}
                  />
                </div>
              )
              :
              (
                <div></div>
              )
          }
        </div>

      </div>

      <div className='card-main-content'>
        {towns && (
          <div className="container-left">
            {towns.map((town: ITown, index: number) => (
              <div key={`town-${index}`} className="container-town">
                <div className="town-name">
                  {town.town}
                </div>
                <div className="town-position">
                  {town.position}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={towns ? 'container-right' : 'container'}>
          {children}
        </div>
      </div>
      {
        (footerSelect) ?
          (
            <div className='card-footer'>
              {
                (footerRadio) ?
                  (<div className='card-options'>
                    <UikToggle
                      defaultChecked={footerRadio.selected}
                      label={footerRadio.label}
                      name={footerRadio.name}
                    />
                  </div>)
                  :
                  (<div></div>)
              }

              <div className='card-options'>
                <UikSelect
                  className={"select"}
                  defaultValue={[footerSelect ? footerSelect.options.defaultValue : '']}
                  options={footerSelect ? footerSelect.options.values : []}
                  placeholder={footerSelect ? footerSelect.options.placeholder : ''}
                />
              </div>
            </div>
          )
          :
          (
            <></>
          )
      }
    </CardInfoContainer>
  )
}

export default CardInfo