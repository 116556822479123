import React from 'react';

// Type
import { ICardInfoSimple } from './CardInfoSimple-type';

// Style
import CardInfoSimpleContainer from './CardInfoSimple-style';


const CardInfoSimple: React.FC<ICardInfoSimple> = (props: ICardInfoSimple) => {
  const { title, value, footText, image, imagePosition } = props;

  return (
    <CardInfoSimpleContainer>
      <div className=''>
        <p>{title}</p>
      </div>

      <div className='card-simple-value'>
        <h1>{value}</h1>
      </div>
      
      <div className='card-simple-footer'>
        <h5>{footText}</h5>
      </div>

      {
        (image) ? 
          (
            <div className={'card-simple-backImage ' + (imagePosition?imagePosition:'top') }>
              <img src={image} alt='Trending up' />
            </div>
          )
        :
          (
            <div></div>
          )

      }
      
    </CardInfoSimpleContainer>
  )
}

export default CardInfoSimple