import styled from "styled-components";

export const PaginationContainer = styled.div`

  .pagination-container {
    display: flex;
    color: #0E0E0E;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagination-left {
    min-width: 20px;
    margin-right: 3px; 
    img {
      max-width: 16px;
    }
  }

  .pagination-main {
    display: flex;
    justify-content: center;
    align-items: center;

    .page-text {
      
      input {
        width: 40px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #EAEDF3;
        box-sizing: border-box;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
        border-radius: 4px; 
        padding: 3px 10px;
        margin-right: 5px;
        color: #248489
      }

    }

    .limit-text {
      margin-left: 5px;
      p {
        color: #3E3F42; 
      }
    }

    .divider-line {
      p {
        color: #3E3F42;
      }
    }

    /* span {
      margin: 0 8px;

      &.actual {
        color: #248489;
        position: relative;
        &:after {
          content: "";
          width: 6px;
          height: 6px;
          background: #248489;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          bottom: -10px;
          transform: translateX(-50%);
        }
      }
    } */
  }

  .pagination-right {
    min-width: 20px;
    margin-left: 5px;
    img {
      max-width: 16px;
    }
  }

  span {
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    opacity: 1;
    pointer-events: all;
    display: block;
    height: 16px;
  

    &.limit {
      opacity: 0.5;
      pointer-events: none;
      transform: translateX;
    }
  }

  .dots-container {
    color: #248489;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 16px;
  }

  .dots-item {
    display: flex;
    span {
      margin: 0;
      cursor: default;
      width: 4px;
      height: 4px;
      background: #248489;
      border-radius: 50%;
      display: block;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
