import styled, { css } from 'styled-components';

const CardInfoContainer = styled.div<{ height: string | undefined }>`
  position: relative;
  padding: 20px 30px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #EAEDF3;
  border-radius: 4px;

  display: flex;
  flex-flow: column;
  
  &.expand {
    justify-content: space-between;
  }

  &.no-expand {
    justify-content: flex-start;
  }

  ${(props) => props.height ? (css`
  height: ${props.height}px
  `) : ''}

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 15px;
    border-bottom: 1px solid #EAEDF3;
    margin-bottom: 15px;

    .card-title {
      display: flex;
      justify-items: center;
      align-items: center;

      img {
        margin-top: 0;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  
  .card-main-content {
    display: flex;
    justify-content: flex-start;

    .container {
      width: 100%;
      .town-title{
        background-color: #E5E5E5;
        padding: 12px;
      }
    }
    .container-right {
      width: 66.66%;
    } 
    .container-left {
      width: 33.33%;
      display: flex;
      flex-direction: column;

      div:not(:first-child) {
        margin-top: 10px;
      }

      .town-name {
        font-size: 12px;
        line-height: 22px;
        text-transform: uppercase;
        color: #868686;
        margin-left: 16px;
      }
      .town-position {
        font-size: 24px;
        line-height: 50px;
        color: #4A4B4E;
        margin-right: 56px;
      }

      .container-town{
        width: 282px;
        height: 83px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* background: rgba(44, 219, 186, 0.3); */
        border-radius: 10px;
      }

      .container-town:nth-child(odd) { 
        background: rgba(44, 219, 186, 0.3);
      }
      .container-town:nth-child(even) { 
        background: rgba(238, 178, 34, 0.3);
      }
      
    } 
  }

  .card-options {
    display: flex;
    align-items: center;

    .card-select-options {
      display: flex;
      align-items: center;
    
    }

    .uik-select__wrapper {
      margin-top: 0px;
      margin-left: 10px;
    }
    
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }
`

export default CardInfoContainer;