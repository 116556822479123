import React, { useState, useContext, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";

import * as uik from "./../../@uik";
import TopBar from "../../components/TopBar/TopBar";
import { iRow, iKeyRows } from "../../models/general.model";
import Table from "../../components/Table/Table";
import GeneralContext from "../../context/general.context";
import {
  getSubscriptions,
  getInvoices,
  getRankingPageViews,
  getRankingSession,
  getRankingTraffic,
  getRankingGlobal,
} from "../../services/reportCustom.services";
import { getDateFormat, getYearsSelect } from "../../utils/time";
import { MONTHS, TOTAL_ITEMS_COMPARATIVE } from "../../constants/general";
import Pagination from "../../components/Pagination/Pagination";
import Select from "../../components/Select/Select";
import DropdownIndicator from "../../components/ReactSelectDropDown/ReactSelectDropDown";
import ReactSelect from "react-select";
import { capitalize } from "../../utils/text";
import { getCustomersProvinces, getCustomersCommunities } from "../../services/customer.services";

const Home: React.FC = () => {
  const today = new Date();

  const [subscriptions, setSubscriptions] = useState<any>({});
  const [subscriptionDate, setSubscriptionsDate] = useState<any>({
    year: { value: getDateFormat(today, "yyyy"), label: parseFloat(getDateFormat(today, "yyyy")) },
    month: { value: getDateFormat(today, "MM"), label: capitalize(getDateFormat(today, "MMMM")) },
  });
  const [reloadSubscriptions, setReloadSubscriptions] = useState<boolean>(true);

  const [billing, setBilling] = useState<any[]>([]);
  const [billingDate, setBillingDate] = useState<any>({
    year: { value: getDateFormat(today, "yyyy"), label: getDateFormat(today, "yyyy") },
  });
  const [reloadBilling, setReloadBilling] = useState<boolean>(true);

  const [type, setType] = useState<any>({ label: "Global", value: "" });

  const [province, setProvince] = useState<any>("");
  const [community, setCommunity] = useState<any>("");

  const [provinces, setProvinces] = useState<any[]>([]);
  const [communities, setCommunities] = useState<any[]>([]);

  const [users, setUsers] = useState<any[]>([]);
  const [time, setTime] = useState<any[]>([]);
  const [links, setLinks] = useState<any[]>([]);
  const [global, setGlobal] = useState<any[]>([]);

  const [pageGlobal, setPageGlobal] = useState<number>(1);
  const [totalPageGlobal, setTotalPageGlobal] = useState<number>(1);

  const [pageTraffic, setPageTraffic] = useState<number>(1);
  const [totalPageTraffic, setTotalPageTraffic] = useState<number>(1);

  const [pageSession, setPageSession] = useState<number>(1);
  const [totalPageSession, setTotalPageSession] = useState<number>(1);

  const [pagePageViews, setPagePageViews] = useState<number>(1);
  const [totalPagePageViews, setTotalPagePageViews] = useState<number>(1);

  const [rankingDate, setRankingDate] = useState<any>({
    year: { value: getDateFormat(today, "yyyy"), label: parseFloat(getDateFormat(today, "yyyy")) },
    month: { value: getDateFormat(today, "MM"), label: capitalize(getDateFormat(today, "MMMM")) },
  });

  const [reloadRanking, setReloadRanking] = useState<boolean>(true);
  const [reloadUsers, setReloadUsers] = useState<boolean>(false);
  const [reloadTime, setReloadTime] = useState<boolean>(false);
  const [reloadLink, setReloadLink] = useState<boolean>(false);
  const [reloadGlobal, setReloadGlobal] = useState<boolean>(false);

  const years = getYearsSelect(parseFloat(getDateFormat(today, "yyyy")) - (parseFloat(getDateFormat(today, "yyyy")) - 2019));

  const general = useContext(GeneralContext);

  useEffect(() => {
    getProvinces();
    getCommunities();
  }, []);

  useEffect(() => {
    if (reloadSubscriptions) {
      getSubscriptionsItems();
      setReloadSubscriptions(false);
    }
  }, [reloadSubscriptions]);

  useEffect(() => {
    if (reloadBilling) {
      getBillingItems();
      setReloadBilling(false);
    }
  }, [reloadBilling]);

  useEffect(() => {
    if (reloadRanking) {
      getRankingItems();
      setReloadRanking(false);
    }
    if (reloadGlobal) {
      getRankingGlobalItems();
      setReloadGlobal(false);
    }
    if (reloadUsers) {
      getRankingTrafficItems();
      setReloadUsers(false);
    }
    if (reloadTime) {
      getRankingSessionItems();
      setReloadTime(false);
    }
    if (reloadLink) {
      getRankingPageViewsItems();
      setReloadLink(false);
    }
  }, [reloadRanking, reloadGlobal, reloadUsers, reloadTime, reloadLink]);

  const getProvinces = async () => {
    const res = await getCustomersProvinces({
      loader: true,
      setLoading: general.setLoading,
    });

    const resProvinces = res.map((item: any) => ({ label: item.name, value: item.code }));

    setProvinces(resProvinces);
  };

  const getCommunities = async () => {
    const res = await getCustomersCommunities({
      loader: true,
      setLoading: general.setLoading,
    });

    const resCommunities = res.map((item: any) => ({ label: item.name, value: item.code }));

    setCommunities(resCommunities);
  };

  const getSubscriptionsItems = async () => {
    const res = await getSubscriptions({
      loader: true,
      setLoading: general.setLoading,
      params: [`year=${subscriptionDate.year.value}`, `month=${subscriptionDate.month.value}`],
    });

    res.sort((a: any, b: any) => {
      return a.minPoblation - b.minPoblation;
    });

    const subscriptions = {
      labels: res.map((item: any) => item.name),
      datasets: [
        {
          label: "Clientes",
          backgroundColor: "rgba(22,101,216,0.2)",
          borderColor: "rgba(22,101,216,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(22,101,216,0.4)",
          hoverBorderColor: "rgba(22,101,216,1)",
          data: res.map((item: any) => item.totalCustomers),
        },
      ],
    };
    setSubscriptions({ ...subscriptions });
  };

  const getBillingItems = async () => {
    const res: any = await getInvoices({
      loader: true,
      setLoading: general.setLoading,
      params: [`year=${billingDate.year.value}`],
    });

    const labels: string[] = [];
    const data: number[] = [];

    for (let i = 0; i < MONTHS.length; i++) {
      labels.push(MONTHS[i].label);
      const key = ("0" + (i + 1).toString()).slice(-2);

      data.push(res[key] ? res[key].count : 0);
    }
    const billing: any = {
      labels,
      datasets: [
        {
          label: "Facturación",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data,
        },
      ],
    };

    setBilling({ ...billing });
  };

  const getRankingItems = async () => {
    getRankingGlobalItems();
    getRankingTrafficItems();
    getRankingSessionItems();
    getRankingPageViewsItems();
  };

  const getRankingGlobalItems = async () => {
    const params = [`limit=${TOTAL_ITEMS_COMPARATIVE}`];
    const offset = TOTAL_ITEMS_COMPARATIVE * (pageGlobal - 1);
    params.push(`offset=${offset}`);

    if (type.value && (province.value || community.value)) {
      params.push(`ranking=${type.value}`);
      params.push(`code=${province.value || community.value}`);
    }

    const res = await getRankingGlobal({
      params,
      loader: true,
      setLoading: general.setLoading,
    });

    const global: iRow[] =
      res && res.results
        ? res.results.map((i: any, index: number) => ({
          town: {
            value: i.town,
            type: "text",
          },
          position: {
            value: i.count ? i.position : "-",
            type: "text",
          },
        }))
        : [];

    setGlobal(global);
    setTotalPageGlobal(Math.ceil(res.total / TOTAL_ITEMS_COMPARATIVE) || 1);
  };

  const getRankingTrafficItems = async () => {
    const params = [`limit=${TOTAL_ITEMS_COMPARATIVE}`];
    const offset = TOTAL_ITEMS_COMPARATIVE * (pageTraffic - 1);
    params.push(`offset=${offset}`);

    if (type.value && (province.value || community.value)) {
      params.push(`ranking=${type.value}`);
      params.push(`code=${province.value || community.value}`);
    }

    const res = await getRankingTraffic({
      params,
      loader: true,
      setLoading: general.setLoading,
    });

    const users: iRow[] =
      res && res.results
        ? res.results.map((i: any, index: number) => ({
          town: {
            value: i.town,
            type: "text",
          },
          position: {
            value: i.position || "-",
            type: "text",
          },
        }))
        : [];

    setUsers(users);
    setTotalPageTraffic(Math.ceil(res.total / TOTAL_ITEMS_COMPARATIVE) || 1);
  };

  const getRankingSessionItems = async () => {
    const params = [`limit=${TOTAL_ITEMS_COMPARATIVE}`];
    const offset = TOTAL_ITEMS_COMPARATIVE * (pageSession - 1);
    params.push(`offset=${offset}`);

    if (type.value && (province.value || community.value)) {
      params.push(`ranking=${type.value}`);
      params.push(`code=${province.value || community.value}`);
    }

    const res = await getRankingSession({
      params,
      loader: true,
      setLoading: general.setLoading,
    });

    const time: iRow[] =
      res && res.results
        ? res.results.map((i: any, index: number) => ({
          town: {
            value: i.town,
            type: "text",
          },
          position: {
            value: i.position || "-",
            type: "text",
          },
        }))
        : [];

    setTime(time);
    setTotalPageSession(Math.ceil(res.total / TOTAL_ITEMS_COMPARATIVE) || 1);
  };

  const getRankingPageViewsItems = async () => {
    const params = [`limit=${TOTAL_ITEMS_COMPARATIVE}`];
    const offset = TOTAL_ITEMS_COMPARATIVE * (pagePageViews - 1);
    params.push(`offset=${offset}`);

    if (type.value && (province.value || community.value)) {
      params.push(`ranking=${type.value}`);
      params.push(`code=${province.value || community.value}`);
    }

    const res = await getRankingPageViews({
      params,
      loader: true,
      setLoading: general.setLoading,
    });

    const links: iRow[] =
      res && res.results
        ? res.results.map((i: any, index: number) => ({
          town: {
            value: i.town,
            type: "text",
          },
          position: {
            value: i.position || "-",
            type: "text",
          },
        }))
        : [];

    setLinks(links);
    setTotalPagePageViews(Math.ceil(res.total / TOTAL_ITEMS_COMPARATIVE) || 1);
  };

  const handleChangeSubscriptionDate = (value: any, key: string) => {
    subscriptionDate[key] = value;
    setSubscriptionsDate({ ...subscriptionDate });
    setReloadSubscriptions(true);
  };

  const handleChangeRankingDate = (value: any, key: string) => {
    rankingDate[key] = value;
    setRankingDate({ ...rankingDate });
    setReloadRanking(true);
  };

  const handleChangeBillingDate = (value: any, key: string) => {
    billingDate[key] = value;
    setBillingDate({ ...billingDate });
    setReloadBilling(true);
  };

  const changePageGlobal = (page: number) => {
    setPageGlobal(page);
    setReloadGlobal(true);
  };

  const changePageTraffic = (page: number) => {
    setPageTraffic(page);
    setReloadUsers(true);
  };
  const changePageSession = (page: number) => {
    setPageSession(page);
    setReloadTime(true);
  };
  const changePagePageViews = (page: number) => {
    setPagePageViews(page);
    setReloadLink(true);
  };

  const resetPages = () => {
    setPageGlobal(1);
    setPageTraffic(1);
    setPageSession(1);
    setPagePageViews(1);
  };

  const {
    UikTopBarSection,
    UikTopBarTitle,
    UikContainerVertical,
    UikLayoutMain,
    UikContentItem,
    UikWidget,
    UikWidgetHeader,
    UikWidgetContent,
  } = uik;

  const columns = ["Posición", "Población"];
  const keyRows: iKeyRows[] = [
    { key: "position", column: "" },
    { key: "town", column: "" },
  ];

  return (
    <UikContainerVertical className={"page"}>
      <TopBar>
        <UikTopBarSection>
          <UikTopBarTitle>KPIs</UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <UikWidget margin>
          <UikWidgetHeader
          // rightEl={(
          //   <div className={"headerActions"}>
          //     <Select
          //       className={"select-month"}
          //       value={[subscriptionDate.month]}
          //       onChange={(value: any) => handleChangeSubscriptionDate(value, 'month')}
          //       options={MONTHS}
          //       errorMessage={""}
          //       placeholder={""}
          //       label={""}
          //     />
          //     <Select
          //       className={"select"}
          //       value={[subscriptionDate.year]}
          //       options={years}
          //       onChange={(value: any) => handleChangeSubscriptionDate(value, 'year')}
          //       placeholder={""}
          //       errorMessage={""}
          //       label={""}
          //     />
          //   </div>
          // )}
          >
            Clientes activos por tarifa
          </UikWidgetHeader>
          <UikWidgetContent>
            <Bar
              data={subscriptions}
              width={400}
              height={300}
              options={{
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                        callback: (value: any) => {
                          if (Number.isInteger(value)) return value;
                        },
                      },
                    },
                  ],
                },
              }}
            />
          </UikWidgetContent>
        </UikWidget>
        <UikWidget margin>
          <UikWidgetHeader
            rightEl={
              <div className={"headerActions"}>
                <Select
                  className={"select"}
                  value={[billingDate.year]}
                  options={years}
                  onChange={(value: any) => handleChangeBillingDate(value, "year")}
                  placeholder={""}
                  errorMessage={""}
                  label={""}
                />
              </div>
            }
          >
            Facturación
          </UikWidgetHeader>
          <UikWidgetContent>
            <Line data={billing} />
          </UikWidgetContent>
        </UikWidget>
        <UikWidget margin>
          <UikWidgetHeader
            rightEl={
              <div className={"headerActions"}>
                <Select
                  position={"bottomRight"}
                  className=""
                  label={""}
                  options={[
                    { value: "", label: "Global" },
                    { value: "comunidad", label: "Comunidad" },
                    { value: "provincia", label: "Provincia" },
                  ]}
                  placeholder={""}
                  errorMessage={""}
                  value={[type]}
                  onChange={(value: any) => {
                    setType({ ...value });
                    resetPages();
                    if (value.value === "") {
                      setProvince("");
                      setCommunity("");
                      setReloadRanking(true);
                    }
                  }}
                />
                {type.value && (
                  <ReactSelect
                    style={{ width: "100px" }}
                    className={"react-select-container"}
                    classNamePrefix={"react-select"}
                    placeholder={type.value === "provincia" ? "Provincia" : "Comunidad"}
                    value={type.value === "provincia" ? province : community}
                    options={type.value === "provincia" ? provinces : communities}
                    onChange={(item: any) => {
                      resetPages();
                      if (type.value === "provincia") {
                        setProvince({ ...item });
                        setReloadRanking(true);
                      } else {
                        setCommunity({ ...item });
                        setReloadRanking(true);
                      }
                    }}
                    components={{ DropdownIndicator }}
                  />
                )}
              </div>
            }
          >
            Ranking del pueblo
          </UikWidgetHeader>
          <UikWidgetContent>
            <div className="grid">
              <div className="col-6">
                <h3>Posición EF</h3>
                <Table rows={global} columns={columns} keyRows={keyRows} />
                <div className="container-pagination">
                  <Pagination
                    page={pageGlobal}
                    limit={totalPageGlobal || 1}
                    callback={(page: number) => changePageGlobal(page)}
                  />
                </div>
              </div>
              <div className="col-6">
                <h3>Tráfico de usuarios</h3>
                <Table rows={users} columns={columns} keyRows={keyRows} />
                <div className="container-pagination">
                  <Pagination
                    page={pageTraffic}
                    limit={totalPageTraffic || 1}
                    callback={(page: number) => changePageTraffic(page)}
                  />
                </div>
              </div>
              <div className="col-6">
                <h3>Tiempo en página</h3>
                <Table rows={time} columns={columns} keyRows={keyRows} />
                <div className="container-pagination">
                  <Pagination
                    page={pageSession}
                    limit={totalPageSession || 1}
                    callback={(page: number) => changePageSession(page)}
                  />
                </div>
              </div>
              <div className="col-6">
                <h3>Link visitados</h3>
                <Table rows={links} columns={columns} keyRows={keyRows} />
                <div className="container-pagination">
                  <Pagination
                    page={pagePageViews}
                    limit={totalPagePageViews || 1}
                    callback={(page: number) => changePagePageViews(page)}
                  />
                </div>
              </div>
            </div>
          </UikWidgetContent>
        </UikWidget>

        <UikContentItem></UikContentItem>
      </UikLayoutMain>
    </UikContainerVertical>
  );
};

export default Home;
