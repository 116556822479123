import styled from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  color: black;
  background: transparent;
  border: 1px solid #D8DCE6;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  display: block;
  padding: 26px;
  margin: 15px;

  button {
    float: right;
    border: 1px solid #DE4B4B;
    color: #DE4B4B;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px; 
    &:hover, &:focus{
      border: 1px solid #DE4B4B;
    color: #DE4B4B;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
    }
  }
  `;
