import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import classnames from 'classnames';

import './@uik/styles.css';
import './App.css';
import { GeneralProvider } from './context/general.context';
import Login from './containers/Login/Login';
import Home from './containers/Home/Home';
import * as uik from './@uik';

import Navigation from './components/Navigation/Navigation';
import Users from './containers/Users/Users';
import UserForm from './containers/Users/UserForm';
import Billing from './containers/Billing/Billing';
import ForgotPassword from './containers/Login/ForgotPassword';
import ResetPassword from './containers/Login/ResetPassword';
import Customers from './containers/Clients/Customers';
import CustomerForm from './containers/Clients/CustomerForm';
import CustomerDetailContainer from './containers/Clients/CustomerDetailContainer';
import Settings from './containers/Users/Settings';
import Loader from './components/Loader/Loader';
import { getOneUser } from './services/user.services';
import Subscriptions from './containers/Subscriptions/Subscriptions';
import SubscriptionForm from './containers/Subscriptions/SubscriptionForm';
import Services from './containers/Services/Services';
import ServiceForm from './containers/Services/ServiceForm';
import Testimonials from './containers/Testimonials/Testimonials';
import TestimonialForm from './containers/Testimonials/TestimonialForm';
import Functionalities from './containers/Functionalities/Functionalities';
import FunctionalForm from './containers/Functionalities/FunctionalForm';
import AboutUs from './containers/AboutUs/AboutUs';
import AboutUsForm from './containers/AboutUs/AboutUsForm';
import LegalInfo from './containers/LegalInfo/LegalInfo';
import LegalInfoForm from './containers/LegalInfo/LegalInfoForm';
import BillingForm from './containers/Billing/BillingForm';
import GlobalData from './containers/GlobalData/GlobalData';
import Mails from './containers/Mails/Mails';
import Cookie from 'js-cookie';
import NotFound from './components/NotFound/NotFound';
import General from './containers/GlobalData/Detail/General/General';
import GlobalDataDetailContainer from './containers/GlobalData/Detail/GlobalDataDetailContainer';

const App: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [reloadItem, setReloadItem] = useState<boolean>(true);
  const [isLogged, setIsLogged] = useState(Cookie.get('accessToken') && Cookie.get('idUser') ?
    true : false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { UikContainerHorizontal, UikContainerVertical } = uik;

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.has("setPassToken") ? urlParams.get("setPassToken") : 'none';

  useEffect(() => {
    if (reloadItem) {
      getUser();
      setReloadItem(false);
    }
  }, [reloadItem]);

  const getUser = async () => {
    if (Cookie.get('accessToken') && Cookie.get('idUser')) {
      const res = await getOneUser({
        loader: true,
        setLoading,
        id: Cookie.get('idUser')
      });
      setUser(res);
    }
  }

  return (
    <Router>
      <GeneralProvider value={{
        loading,
        isLogged,
        user,
        isMenuOpen,
        setLoading,
        setUser,
        setIsLogged,
        setIsMenuOpen
      }}>
        {isLogged ? (
          <UikContainerHorizontal>
            <Navigation className={classnames("menuAnimate", { ["menuOpen"]: isMenuOpen, })} />
            <UikContainerVertical className={classnames("menuAnimate", { ["menuOpen"]: isMenuOpen })}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/global-data" component={GlobalData} />
                <Route path="/global-data/detail/:code" component={GlobalDataDetailContainer} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/mails" component={Mails} />
                <Route exact path="/users" component={Users} />
                <Route exact path="/users/create" component={UserForm} />
                <Route exact path="/users/edit/:id" component={UserForm} />
                <Route exact path="/subscriptions" component={Subscriptions} />
                <Route exact path="/subscriptions/create" component={SubscriptionForm} />
                <Route exact path="/subscriptions/edit/:id" component={SubscriptionForm} />
                <Route exact path="/customers" component={Customers} />
                <Route exact path="/customers/create" component={CustomerForm} />
                <Route exact path="/customers/edit/:id" component={CustomerForm} />
                <Route path="/customers/detail/:id" component={CustomerDetailContainer} />
                <Route exact path="/services" component={Services} />
                <Route exact path="/services/create" component={ServiceForm} />
                <Route exact path="/services/edit/:id" component={ServiceForm} />
                <Route exact path="/testimonials" component={Testimonials} />
                <Route exact path="/testimonials/create" component={TestimonialForm} />
                <Route exact path="/testimonials/edit/:id" component={TestimonialForm} />
                <Route exact path="/functionalities" component={Functionalities} />
                <Route exact path="/functionalities/create" component={FunctionalForm} />
                <Route exact path="/functionalities/edit/:id" component={FunctionalForm} />
                <Route exact path="/about-us" component={AboutUs} />
                <Route exact path="/about-us/create" component={AboutUsForm} />
                <Route exact path="/about-us/edit/:id" component={AboutUsForm} />
                <Route exact path="/legal-info" component={LegalInfo} />
                <Route exact path="/legal-info/create" component={LegalInfoForm} />
                <Route exact path="/legal-info/edit/:id" component={LegalInfoForm} />
                <Route exact path="/billing" component={Billing} />
                <Route exact path="/billing/create" component={BillingForm} />
                {/* <Route exact path="/billing/edit/:id" component={BillingForm} /> */}
                <Route path="/" render={() => <NotFound />} />
              </Switch>
            </UikContainerVertical>
          </UikContainerHorizontal>
        ) : (
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              {token !== 'none' && (
                <Route exact path="/recover-password" component={ResetPassword} />
              )}
              <Route path="/" render={() => <NotFound />} />
            </Switch>
          )}
        {loading && <Loader />}
      </GeneralProvider>
    </Router >
  );
}

export default App;
