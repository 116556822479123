/* eslint-disable max-len */
import React, { useContext } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import classnames from "classnames";
import Cookie from "js-cookie";
import GeneralContext from "./../../context/general.context";
import { images } from "../../assets/images";

import * as uik from "./../../@uik";

interface iNavigationProps extends RouteComponentProps {
  className?: string;
}

const Navigation: React.FC<iNavigationProps> = ({ className, history }) => {
  const {
    UikNavSection,
    UikNavSectionTitle,
    UikNavPanel,
    UikContainerVertical,
    UikDivider,
    UikNavLinkTwo,
    UikScrollArea,
    UikNavUser,
    UikNavLinkTwoContainer,
    UikNavLink,
  } = uik;

  const general = useContext(GeneralContext);

  const logout = () => {
    general.setIsLogged(false);
    Cookie.remove("accessToken");
    Cookie.remove("idUser");
    history.push("/");
  };

  const menuLinks: any[] = [
    {
      text: "Inicio",
      to: "/",
      icon: <img src={images.IconHome} />,
      exact: true,
    },
    {
      text: "Clientes",
      to: "/customers",
      icon: <img src={images.IconClients} />,
    },
    {
      text: "Facturación",
      to: "/billing",
      icon: <img src={images.IconBilling} />,
    },
    {
      text: "Tarifas",
      to: "/subscriptions",
      icon: <img src={images.IconTariffs} />,
    },
    {
      text: "Roles",
      to: "/users",
      icon: <img src={images.IconRoles} />,
    },
    {
      text: "Leads",
      to: "/mails",
      icon: <img src={images.IconLeads} />,
    },
    {
      text: "Datos Globales",
      to: "/global-data",
      icon: <img src={images.IconGlobalData} />,
    },
    {
      text: "Configuración",
      to: "/settings",
      icon: <img src={images.IconSettings} />,
    },
  ];

  const menuLinksCms: any[] = [
    {
      text: "Servicios",
      to: "/services",
      icon: <img src={images.IconServices} />,
      exact: true,
    },
    {
      text: "Testimonios",
      to: "/testimonials",
      icon: <img src={images.IconTestimonials} />,
    },
    {
      text: "Funcionalidades",
      to: "/functionalities",
      icon: <img src={images.IconFuncionalities} />,
    },
    {
      text: "Aviso Legal",
      to: "/legal-info",
      icon: <img src={images.IconLegal} />,
    },
    {
      text: "About Us",
      to: "/about-us",
      icon: <img src={images.IconAboutUs} />,
    },
  ];

  return (
    <UikNavPanel className={classnames("navigation-wrapper", className)}>
      <UikContainerVertical>
        <UikScrollArea>
          <UikNavUser
            imgUrl={""}
            name={general.user.username}
          // textTop="Art Director"
          />
          <UikDivider />
          <UikNavLinkTwoContainer>
            {menuLinks.map(({ text, icon, to, ...rest }) => (
              <UikNavLinkTwo key={text} Component={NavLink} icon={icon} to={`${to}`} {...rest}>
                {text}
              </UikNavLinkTwo>
            ))}
          </UikNavLinkTwoContainer>

          <UikNavLinkTwoContainer>
            <UikNavSectionTitle>CMS</UikNavSectionTitle>
            {menuLinksCms.map(({ text, icon, to, ...rest }) => (
              <UikNavLinkTwo key={text} Component={NavLink} icon={icon} to={`${to}`} {...rest}>
                {text}
              </UikNavLinkTwo>
            ))}
          </UikNavLinkTwoContainer>
          <UikNavSection>
            <UikNavLink onClick={() => logout()}>Salir</UikNavLink>
          </UikNavSection>
        </UikScrollArea>
      </UikContainerVertical>
    </UikNavPanel>
  );
};

export default withRouter(Navigation);
