import React, { useState, useContext, useEffect } from 'react'

import * as uik from '../../../@uik';

import GeneralContext from '../../../context/general.context'
import { RouteComponentProps, withRouter } from 'react-router';
import { iCustomers } from '../../../models/customers.models';
import { getOneCustomer, editCustomer, changeSubscription } from '../../../services/customer.services';
import { images } from '../../../assets/images';
import CardDelete from '../../../components/CardDelete/CardDelete';
import InfoModal from '../../../components/InfoModal/InfoModal';
import { iModal } from '../../../models/general.model';
import CardAlert from '../../../components/CardAlert/CardAlert';
import { getDateFormat } from '../../../utils/time';

interface RouteParams {
  id: string
}

interface iCustomerDetailProps extends RouteComponentProps<RouteParams> { }

const CustomerDetail: React.FC<iCustomerDetailProps> = ({ match, history }) => {

  const [item, setItem] = useState<iCustomers>({
    username: '',
    town: {},
    profile: {},
    contact: {},
    subscription: {},
    description: '',
    history: '',
    visits: [],
    places: [],
    reportMails: [],
    multimedia: [],
    id: 0,
    updatedAt: ''
  });

  const [reloadItem, setReloadItem] = useState<boolean>(true);
  const [data, setData] = useState<any>({});

  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: '',
    inputs: []
  });

  const general = useContext(GeneralContext);


  useEffect(() => {

    if (reloadItem && match.params.id) {
      getItem();
      setReloadItem(false);
    }
  }, [reloadItem]);

  const getItem = async () => {
    const res: any = await getOneCustomer({
      id: match.params.id,
      loader: true,
      setLoading: general.setLoading,
    });

    setItem({ ...res });
  }

  const handleChangeData = (value: any, key: string) => {
    data[key] = typeof value === 'object' ? value.value : value;
    setData({ ...data });
  }

  const handleDeleteItem = async () => {
    try {
      await changeSubscription({
        id: match.params.id,
        data: { subscriptionId: null },
        loader: true,
        setLoading: general.setLoading
      });
      history.push('/customers');
    } catch (err) {

    }
  }

  const handleAcceptStatus = async () => {

    try {
      let id = match.params.id;
      await editCustomer({
        id,
        data,
        loader: true,
        setLoading: general.setLoading
      });
      handleModalCorrect('Estado');
      setReloadItem(true);
    } catch (err) {
      setModal({
        code: (err.response && err.response.status) ? err.response.status : 'err-local',
        text: (err.response && err.response.description) ? err.response.description : 'Error inesperado, inténtelo más tarde.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Error',
        alert: '',
        className: 'error',
        img: images.IconXCircle
      });
    }
  }

  const handleModalCorrect = (title: string) => {
    setModal({
      title,
      code: 'success',
      button: '',
      handleAccept: () => { },
      text: 'Se ha guardado correctamente los cambios.',
      show: true,
      alert: '',
      className: '',
      img: images.IconCheckCircle
    });
  }

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: '',
      inputs: [],
      img: ''
    })
  }

  const { UikButton,
    UikLayoutMain, UikWidget,
    UikContentItem, UikWidgetContent } = uik;

  return (
    <UikLayoutMain className={"wrapper"}>
      <UikContentItem>
        <div className="grid">
          <div className="col-3">
            <div className="container-picture">
              <img
                src={(item.profile && item.profile.src) ? item.profile.src : images.picture}
                alt=""
                onError={(e: any) => { e.target.onerror = null; e.target.src = images.picture }}
              />
              <p>Foto de perfil</p>
              <UikButton success onClick={() => history.push(`/customers/edit/${item.id}`)}>
                Editar
              </UikButton>
            </div>
            <div className="container-card-alert">
              <div className="last-update">
                <p><span>Última modificación:</span> {item.updatedAt ? getDateFormat(new Date(item.updatedAt), 'dd/MM/yyyy') : '-'} </p>
              </div>
            </div>
            <div className="container-card-alert">
              <CardAlert
                handleClick={() => {
                  setData({});
                  setModal({
                    code: '',
                    button: 'Guardar',
                    handleAccept: () => handleAcceptStatus(),
                    text: '¿Qué estado quiere poner?',
                    show: true,
                    title: 'Cambiar estado',
                    alert: '',
                    className: 'success',
                    img: '',
                    inputs: [{
                      value: '',
                      defaultValue: [item.status],
                      error: false,
                      errorCode: '',
                      required: true,
                      type: 'text',
                      render: 'select',
                      label: 'Estado',
                      placeholder: 'estado...',
                      className: 'col-12',
                      options: [{ label: 'Activo', value: 'active' }, { label: 'Desactivado', value: 'inactive' },
                      { label: 'Pendiente', value: 'pending' }],
                      key: 'status',
                      handleChange: (value: any, key: string) => handleChangeData(value, key)
                    }],
                  })
                }
                }
                text={<p>
                  La cuenta está {" "}
                  <span>
                    {item.status === 'active' ? 'activada' : (item.status === 'inactive' || item.status === null) ? 'desactivada' : 'en pendiente'}
                  </span>
                </p>
                }
                textFooter={"Cambiar estado"}
                className={item.status}
              />
            </div>
          </div>

          <div className="col-9">
            <UikWidget margin>
              <UikWidgetContent>
                <h4>Correo</h4>
                <p>{item.username}</p>
              </UikWidgetContent>
            </UikWidget>
            <UikWidget margin>
              <UikWidgetContent>
                <h4>Información del pueblo</h4>
                <p>Nombre: {item.town.name}</p>
                <p>Código: {item.town.code}</p>
                <p>Provincia: {item.town.province}</p>
              </UikWidgetContent>
            </UikWidget>
            <UikWidget margin>
              <UikWidgetContent>
                <h4>Contacto</h4>
                <p>Nombre: {item.contact ? item.contact.name : '-'}</p>
                <p>Crendeciales: {item.contact ? item.contact.credential : '-'}</p>
                <p>Télefono: {item.contact ? item.contact.phoneNumber : ''}</p>
              </UikWidgetContent>
            </UikWidget>
            <UikWidget margin>
              <UikWidgetContent>
                <h4>Descripción del pueblo</h4>
                {item.description ? (
                  <p>{item.description}</p>
                ) : (
                    <p>No hay datos</p>
                  )}
              </UikWidgetContent>
            </UikWidget>
            <UikWidget margin>
              <UikWidgetContent>
                <h4>Historia del pueblo</h4>
                {item.history ? (
                  <p>{item.history}</p>
                ) : (
                    <p>No hay datos</p>
                  )}
              </UikWidgetContent>
            </UikWidget>
            <UikWidget margin>
              <UikWidgetContent>
                <h4>Lugares de interés</h4>
                {item.places && item.places.length > 0 ? (
                  item.places.map((place: string, index: number) => (
                    <p key={`place-${index}`}>{place}</p>
                  ))) : (
                    <p>No hay datos</p>
                  )}
              </UikWidgetContent>
            </UikWidget>
            <UikWidget margin>
              <UikWidgetContent>
                <h4>Páginas qué visitar</h4>
                {item.visits && item.visits.length > 0 ? (
                  item.visits.map((visit: string, index: number) => (
                    <p key={`visit-${index}`}>{visit}</p>
                  ))) : (
                    <p>No hay datos</p>
                  )}
              </UikWidgetContent>
            </UikWidget>
            <UikWidget margin>
              <UikWidgetContent>
                <h4>Imágenes</h4>
                <div className="grid">
                  {item.multimedia && item.multimedia.length > 0 ? (
                    item.multimedia.map((img: string, index: number) => (
                      <div key={`multimedia-${index}`} className="col-4 image-item">
                        <a target="_blank" rel="noopener noreferrer" href={img}>
                        <div className="image-download">
                          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.75 10V13C14.75 13.3978 14.592 13.7794 14.3107 14.0607C14.0294 14.342 13.6478 14.5 13.25 14.5H2.75C2.35218 14.5 1.97064 14.342 1.68934 14.0607C1.40804 13.7794 1.25 13.3978 1.25 13V10" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M4.25 6.25L8 10L11.75 6.25" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 10V1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                          <img src={img} alt="multimedia" />
                        </a>
                      </div>
                    ))) : (
                      <div className="col-12">
                        <p>No hay fotos</p>
                      </div>
                    )}
                </div>
              </UikWidgetContent>
            </UikWidget>
          </div>
          <div className="col-3"></div>
          {(item.subscription !== null) && (
            <div className="col-9">
              <CardDelete
                title="Dar de baja la suscripción"
                textLeft="Quitarás la suscripción de este cliente y al final de mes ya no podrá usar la web."
                buttonText="Eliminar"
                handleClick={() => setModal({
                  code: 'delete',
                  button: 'Si, dar de baja',
                  handleAccept: () => handleDeleteItem(),
                  text: '¿Estás seguro de dar de baja a este cliente?',
                  show: true,
                  title: 'Dar de baja',
                  alert: 'Esta acción no se puede deshacer.',
                  className: 'error'
                })}
              />
            </div>
          )}
        </div>
        {/* <div className="container-buttons">
          <UikButton onClick={() => history.push('/customers')}>
            Atrás
            </UikButton>
        </div> */}
      </UikContentItem>
      <InfoModal
        className={modal.className}
        titleText={modal.title}
        alertText={modal.alert}
        mainText={modal.text}
        buttonText={modal.button}
        active={modal.show}
        inputs={modal.inputs}
        onClickAccept={() => modal.handleAccept()}
        close={() => closeModal()}
        img={modal.img}
      />
    </UikLayoutMain>
  );
}

export default withRouter(CustomerDetail);


