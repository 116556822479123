import React from "react";
import { components } from "react-select";
import * as uik from './../../@uik';
import { DropdownContainer } from "./dropdown-style";

const DropdownIndicator: React.FC<any> = (props: any) => {

  return (
    <DropdownContainer>
      <components.DropdownIndicator {...props}>
        <div className="drop-down-arrow" />
      </components.DropdownIndicator>
    </DropdownContainer>
  );
};

export default DropdownIndicator;