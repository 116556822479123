import React, { useState, useContext, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";

import { getUsers, deleteUser } from "./../../services/user.services";

import GeneralContext from "./../../context/general.context";

import TopBar from "../../components/TopBar/TopBar";
import Table from "../../components/Table/Table";
import InfoModal from "../../components/InfoModal/InfoModal";
import Pagination from "../../components/Pagination/Pagination";
import IconComponent from "../../components/Icon/Icon";

import * as uik from "./../../@uik";

import { iDelete, iModal, iKeyRows } from "../../models/general.model";
import { iUsers } from "../../models/users.models";

import { TOTAL_ITEMS } from "../../constants/general";
import { images } from "../../assets/images";

export interface iUsersProps extends RouteComponentProps { }

const Users: React.FC<iUsersProps> = ({ history }) => {
  const [items, setItems] = useState<iUsers[]>([]);

  const [deleteItem, setDeleteItem] = useState<iDelete>({ modal: false, id: "" });
  const [reloadItems, setReloadItems] = useState<boolean>(true);
  const [order, setOrder] = useState<any>({ order: "", key: "" });
  const [filter, setFilter] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [modal, setModal] = useState<iModal>({
    code: "",
    text: "",
    button: "",
    handleAccept: () => { },
    show: false,
    alert: "",
    title: "",
    className: "",
  });

  const general = useContext(GeneralContext);

  useEffect(() => {
    if (reloadItems) {
      getItems();
      setReloadItems(false);
    }
  }, [reloadItems]);

  const getItems = async () => {
    const params = [`limit=${TOTAL_ITEMS}`];
    const offset = TOTAL_ITEMS * (page - 1);
    params.push(`offset=${offset}`);

    if (filter) {
      params.push(`filter=${filter}`);
    }

    if (order.key !== "" && order.order) {
      params.push(`orderBy=${order.key}`);
      params.push(`order=${order.order}`);
    } else {
      params.push(`orderBy=user.updated_at`);
      params.push(`order=desc`);
    }

    const res = await getUsers({
      params,
      loader: true,
      setLoading: general.setLoading,
    });

    setItems(res.results);
    setTotalPage(Math.ceil(res.total / TOTAL_ITEMS) || 1);
  };

  const handleOrder = (order: any) => {
    setOrder({ ...order });
    setReloadItems(true);
  };

  const handleDeleteItem = async () => {
    try {
      await deleteUser({ id: deleteItem.id, loader: true, setLoading: general.setLoading });
      setDeleteItem({ modal: false, id: "" });
      setModal({
        code: "success",
        button: "",
        handleAccept: () => { },
        text: "Se ha eliminado correctamente el usuario seleccionado.",
        show: true,
        title: "Eliminar usuario",
        alert: "",
        className: "",
        img: images.IconCheckCircle,
      });
      setReloadItems(true);
    } catch (err) {
      setModal({
        code: "error",
        button: "",
        handleAccept: () => { },
        alert: "Error inesperado.",
        show: true,
        title: "Eliminar usuario",
        text: "Intente eliminar el usuario más tarde.",
        className: "error",
        img: images.IconXCircle,
      });
    }
  };

  const getRows = () => {
    return !items
      ? []
      : items.map((item: any) => ({
        username: {
          value: item.username,
          type: "text",
        },
        buttons: {
          value: "botones",
          actions: [
            // {
            //   label: "Editar",
            //   icon: <img src={images.IconEdit}/>,
            //   onClick: () => {
            //     history.push('users/edit/1');
            //   },
            // },
            {
              label: "Borrar",
              icon: <img src={images.IconDelete} />,
              onClick: () => {
                setDeleteItem({ modal: true, id: item.id });
              },
            },
          ],
          type: "buttons",
        },
      }));
  };

  const changePage = (page: number) => {
    setPage(page);
    setReloadItems(true);
  };

  const handleChangeSearch = (value: string) => {
    setFilter(value);
  };

  const closeModal = () => {
    setModal({
      code: "",
      button: "",
      handleAccept: () => { },
      text: "",
      show: false,
      title: "",
      alert: "",
      className: "",
      img: "",
    });
  };

  const {
    UikTopBarSection,
    UikTopBarTitle,
    UikContainerVertical,
    UikLayoutMain,
    UikContentItem,
    UikWidget,
    UikInput,
  } = uik;

  const columns = ["Correo", "Acciones"];
  const keyRows: iKeyRows[] = [
    { key: "username", column: "username" },
    { key: "buttons", column: "" },
  ];

  return (
    <UikContainerVertical className={"page"}>
      <TopBar handleButton={() => history.push("users/create")} textButton="Crear">
        <UikTopBarSection>
          <UikTopBarTitle>
            <IconComponent name="IconRoles" className={"svg-black"} />
            Usuarios
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <div className="container-search">
          <UikInput
            placeholder={"Buscar..."}
            type={"text"}
            errorMessage={""}
            value={filter}
            onChange={(ev: any) => handleChangeSearch(ev.target.value)}
            onKeyDown={(ev: any) => {
              if (ev.key === "Enter") {
                setPage(1);
                setReloadItems(true);
              }
            }}
            icon={
              <IconComponent
                name="Search"
                className={"svg-search pointer"}
                onClick={() => {
                  setPage(1);
                  setReloadItems(true);
                }}
              />
            }
            iconPosition="right"
          />
        </div>
        <UikWidget margin>
          <UikContentItem>
            <Table
              rows={getRows()}
              columns={columns}
              keyRows={keyRows}
              handleOrder={(order: any) => handleOrder(order)}
            />
            <div className="container-pagination">
              <Pagination page={page} limit={totalPage} callback={(page: number) => changePage(page)} />
            </div>
          </UikContentItem>
        </UikWidget>
      </UikLayoutMain>
      <InfoModal
        className={"error"}
        titleText={"Eliminar usuario"}
        alertText={"Esta acción no se puede deshacer."}
        mainText={"Se eliminará de forma permanente y con ello todos los datos asociados."}
        buttonText={"Borrar"}
        active={deleteItem.modal}
        onClickAccept={() => handleDeleteItem()}
        close={() => setDeleteItem({ modal: false, id: "" })}
      />
      <InfoModal
        className={modal.className}
        titleText={modal.title}
        alertText={modal.alert}
        mainText={modal.text}
        buttonText={modal.button}
        active={modal.show}
        onClickAccept={() => modal.handleAccept()}
        close={() => closeModal()}
        img={modal.img}
      />
    </UikContainerVertical>
  );
};

export default withRouter(Users);
