import styled from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  color: black;
  background: #EAECEE;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  width: 100%;
  height: 223px;
  border-radius: 4px;
  display: block;
  .card-header {
    margin-bottom: 22px;
    p {
      padding: 30px 0px 0px 30px;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #3E3F42;
    }
  }
  .card-divider {
    margin-right: 30px;
    margin-left: 30px;
    width: auto;
    background: #8A8A8A;
  }
  .card-body {
    p{
      color: #4A4B4E;
    }
    bottom: 0;
    margin-top: 30px;
    padding: 0px 30px 0px 30px;
    
    width:100%;
    
    .card-inline {  
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .container-input {
        width: 100%;
        margin-right: 8px;
      }
    }
  }  
`;
