import React, { useState, useContext, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router';

import GeneralContext from '../../context/general.context'

import TopBar from '../../components/TopBar/TopBar';
import Table from '../../components/Table/Table';
import InfoModal from '../../components/InfoModal/InfoModal';
import Pagination from '../../components/Pagination/Pagination';
import IconComponent from '../../components/Icon/Icon';

import * as uik from '../../@uik';

import { iModal, iKeyRows } from '../../models/general.model';
import { iUsers } from '../../models/users.models';

import { TOTAL_ITEMS } from '../../constants/general';
import { getMails, getMailsCsv } from '../../services/mail.services';
import { images } from '../../assets/images';

export interface iMailsProps extends RouteComponentProps { }

const Mails: React.FC<iMailsProps> = ({ history }) => {

  const [items, setItems] = useState<iUsers[]>([]);

  const [reloadItems, setReloadItems] = useState<boolean>(true);
  const [order, setOrder] = useState<any>({ order: '', key: '' });
  const [filter, setFilter] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });

  const general = useContext(GeneralContext);


  useEffect(() => {
    if (reloadItems) {
      getItems();
      setReloadItems(false);
    }
  }, [reloadItems]);

  const getItems = async () => {
    const params = [`limit=${TOTAL_ITEMS}`];
    const offset = TOTAL_ITEMS * (page - 1);
    params.push(`offset=${offset}`);

    if (filter) {
      params.push(`filter=${filter}`);
    }

    if (order.key !== '' && order.order) {
      params.push(`orderBy=${order.key}`);
      params.push(`order=${order.order}`);
    } else {
      params.push(`orderBy=form.created_at`);
      params.push(`order=desc`);
    }

    const res = await getMails({
      params,
      loader: true,
      setLoading: general.setLoading
    });

    setItems(res.results);
    setTotalPage(Math.ceil(res.total / TOTAL_ITEMS) || 1);
    if (totalItems === 0) {
      setTotalItems(res.total);
    }
  }

  const handleDownloadCsv = async () => {
    try {
      const res: any = await getMailsCsv({
        loader: true,
        setLoading: general.setLoading
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement('a');

      link.href = downloadUrl;

      link.setAttribute('download', 'file.csv'); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
      closeModal();

    } catch (err) {
      setModal({
        code: 'error',
        button: '',
        handleAccept: () => { },
        alert: 'Error inesperado.',
        show: true,
        title: 'Descargar factura',
        text: 'Intente descargar la factura más tarde.',
        className: 'error',
        img: images.IconXCircle
      });
    }
  }

  const handleOrder = (order: any) => {
    setOrder({ ...order });
    setReloadItems(true);
  }


  const getRows = () => {
    return !items ? [] : items.map((item: any) => ({
      email: {
        value: item.email,
        type: 'text'
      },
      town: {
        value: item.town,
        type: 'text'
      },
      subscription: {
        value: item.subscription,
        type: 'text'
      },
      message: {
        value: item.message,
        type: 'text'
      }
    }))
  }

  const changePage = (page: number) => {
    setPage(page);
    setReloadItems(true);
  }

  const handleChangeSearch = (value: string) => {
    setFilter(value);

  }

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: '',
      img: ''
    })
  }

  const { UikTopBarSection, UikTopBarTitle, UikContainerVertical, UikLayoutMain,
    UikContentItem, UikWidget, UikInput } = uik;

  const columns = ['Email', 'Pueblo', 'Suscripción', 'Mensaje'];
  const keyRows: iKeyRows[] = [
    { key: 'email', column: 'email' },
    { key: 'town', column: 'town' },
    { key: 'subscription', column: 'subscription' },
    { key: 'message', column: 'message' },
  ];

  return (
    <UikContainerVertical className={"page"}>
      <TopBar
        handleButton2={() => setModal({
          code: 'download',
          button: 'Descargar',
          handleAccept: () => handleDownloadCsv(),
          text: `Se van a descargar ${totalItems} correos, ¿estás seguro?`,
          show: true,
          title: 'Descargar correos',
          alert: '',
          className: 'success'
        })}
        textButton2="Descargar"
      >
        <UikTopBarSection>
          <UikTopBarTitle>
            <IconComponent name="IconLeads" className={"svg-black"} />
            Leads
        </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <div className="container-search">
          <UikInput
            placeholder={"Buscar..."}
            type={"text"}
            errorMessage={""}
            value={filter}
            onChange={(ev: any) => handleChangeSearch(ev.target.value)}
            onKeyDown={(ev: any) => {
              if (ev.key === 'Enter') {
                setPage(1);
                setReloadItems(true)
              }
            }}
            icon={(
              <IconComponent
                name="Search"
                className={"svg-search pointer"}
                onClick={() => {
                  setPage(1);
                  setReloadItems(true)
                }}
              />
            )}
            iconPosition="right"
          />
        </div>
        <UikWidget margin>
          <UikContentItem>
            <Table
              rows={getRows()}
              columns={columns}
              keyRows={keyRows}
              handleOrder={(order: any) => handleOrder(order)}
            />
            <div className="container-pagination">
              <Pagination page={page} limit={totalPage} callback={(page: number) => changePage(page)} />
            </div>
          </UikContentItem>
        </UikWidget>
      </UikLayoutMain>
      <InfoModal
        className={modal.className}
        titleText={modal.title}
        alertText={modal.alert}
        mainText={modal.text}
        buttonText={modal.button}
        active={modal.show}
        onClickAccept={() => modal.handleAccept()}
        close={() => closeModal()}
      />
    </UikContainerVertical>
  );
}




export default withRouter(Mails);

