export const MONTHS: any[] = [
  { label: "Enero", value: "01" },
  { label: "Febrero", value: "02" },
  { label: "Marzo", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Mayo", value: "05" },
  { label: "Junio", value: "06" },
  { label: "Julio", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Septiembre", value: "09" },
  { label: "Octubre", value: "10" },
  { label: "Noviembre", value: "11" },
  { label: "Diciembre", value: "12" }
];

export const TOTAL_ITEMS: number = 10;
export const TOTAL_ITEMS_COMPARATIVE: number = 6;

export const ANALYTICS_TOKEN = 8;

export const CHANNELS_TYPES_CHART: any = {
  Direct: "Directo",
  "Organic Search": "Búsqueda Orgánica",
  Social: "Social",
  Email: "Email",
  Affiliates: "Afiliados",
  Referral: "Remisión",
  "Paid Search": "Busqueda paga",
  "Other Advertising": "Otra públicidad",
  Display: "Monitor",
  other: "Otros",
  unavailable: "Otros"
};

export const SELECT_PERIODICITY: any = {
  "": "Datos semanales *",
  week: "Datos mensuales *",
  month: "Datos anuales *"
};

export const SELECT_OPT_PERIODICITY: any = {
  daily: "Diario",
  weekly: "Semanal",
  monthly: "Mensual"
};

export const TOOLTIPS_TEXT: any = {
  "trafico-de-usuarios":
    "Información sobre el número total de usuarios, nuevos usuarios y usuarios recurrentes que acceden a la web de su pueblo.",
  "desde-donde-te-visitan":
    "Representación gráfica de los países y cantidad de usuarios que visitan tu pueblo, mostrados en diferentes tonalidades de color según su afluencia.",
  "tipo-de-usuario":
    "Porcentaje de usuarios nuevos y recurrentes en la web de su pueblo.",
  "grafico-idioma":
    "Número de visitas en la web de su pueblo segmentada por el idioma de preferencia del navegador.",
  "analisis-de-cohorte":
    "Es un conjunto de usuarios que comparten una característica común. Esta tabla, permite aislar y analizar el comportamiento de los grupos especificados.",
  "que-paginas-visitan":
    "Información sobre las páginas visitadas de su pueblo y el número de visitas totales a dichas páginas.",
  "url-been-clicked":
    "Información sobre los urls  que han sido visitadas de su pueblo.",
  "grafico-edad":
    "Comparativa del número de usuarios totales segmentada por los diferentes rangos de edad.",
  "grafico-sexo":
    "Proporción de usuarios de sexo femenino y masculino que acceden a la web.",
  "canales-principales":
    "Segmentos cuyos usuarios suelen tener interés en obtener información sobre la categoría especificada. Además se muestran los usuarios que han iniciado al menos una sesión durante el periodo especificado y el número de sesiones.",
  "canales-adquisicion":
    "Ordenación de las fuentes de tráfico, medios, campañas, etc. según nos interese agrupar el tráfico o fuentes de adquisición.",
  "paginas-de-busqueda":
    "Listado de las páginas más visitadas/buscadas de su web en orden de popularidad y con porcentaje de búsqueda.",
  "que-intereses-tienen":
    "Intereses de los usuarios que visitan la web de su pueblo mostrados por números de usuario, números de sesiones y duración media de cada sesión.",
  "segmentos-de-compra":
    "Usuarios con mayor predisposición a comprar productos o servicios en la categoría especificada, mostrados por  números de usuario, números de sesiones y duración media de cada sesión.",
  "comparador-ranking":
    "Consulte y compare los datos más relevantes de otros pueblos respecto al suyo.",
  "trafico-de-usaurios-2":
    "Comparativa del número total de usuarios que acceden a las distintas páginas webs.",
  "tiempo-en-pagina":
    "Comparativa del tiempo total que pasan los usuarios en las distintas página web.",
  "links-visitados":
    "Comparativa del numero de usuarios que visitan una página concreta y su ranking en relación a otras páginas de la web.",
  "historial-informes":
    "Listado de todos los informes generados hasta la fecha.",
  "traffic-session-data":
    "Cuántos usuarios han venido a tu página (Rango de sesiones), cuántas veces ha venido cada uno (sesiones) y cuántas páginas ha visto cada uno de ellos (páginas vistas)",

  "informacion-basica": "Modifica el correo con el que se accede a tu cuenta",
  "informacion-minicipio": "Datos del municipio sobre el que trata la página",
  "informacion-contacto":
    "Datos de la persona encargada de administrar Linx por la corporación municpal",
  "envio-informes":
    "Cuentas de correo electrónico a las que se enviarán los informes mensuales",
  "descripcion-municipio":
    "Breve descripción de los hitos más importantes del municipio, con foco en lo que más interesa que sea conocido",
  "lugares-de-interes":
    "Pon en cada casilla monumentos y espacios de interés de tu municipio",
  "links-interes": " Introducir links para insertar en el texto",
  "informacion-practica":
    "Pon datos de interés para quienes visiten tu municipio",
  "subida-multiimagen":
    "Fotografías sobre monumentos y espacios más representativos de tu municipio"
};
