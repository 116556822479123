import React from "react";

import { ReactComponent as IconTrash } from "../../assets/images/icon-trash.svg";
import { ReactComponent as IconDoubleFill } from "../../assets/images/icon-double-fill.svg";
import { ReactComponent as IconWarningYellow } from "../../assets/images/icon-warning-yellow.svg";
import { ReactComponent as IconInfoBlue } from "../../assets/images/icon-info-blue.svg";
import { ReactComponent as IconCloseGrey } from "../../assets/images/icon-close-grey.svg";
import { ReactComponent as IconDownload } from "../../assets/images/icon-download.svg";
import { ReactComponent as IconMask } from "../../assets/images/icon-mask.svg";
import { ReactComponent as IconUp } from "../../assets/images/icon-up.svg";
import { ReactComponent as IconDown } from "../../assets/images/icon-down.svg";
import { ReactComponent as IconArrowLeft } from "../../assets/images/icon-arrow-left.svg";
import { ReactComponent as IconArrowRight } from "../../assets/images/icon-arrow-right.svg";
import { ReactComponent as IconInfoGray } from "../../assets/images/icon-info-gray.svg";
import { ReactComponent as IconCross } from "../../assets/images/icon-cross.svg";
import { ReactComponent as IconExclamation } from "../../assets/images/icon-exclamation.svg";
import { ReactComponent as IconMoney } from "../../assets/images/icon-money.svg";
import { ReactComponent as IconCheckCircle } from "../../assets/images/icon-check-circle.svg";
import { ReactComponent as IconXCircle } from "../../assets/images/icon-x-circle.svg";
import { ReactComponent as IconCheck } from "../../assets/images/icon-check.svg";
import { ReactComponent as ImageNotFound } from "../../assets/images/not-found-background.svg";
import { ReactComponent as IconReportGeneral } from "../../assets/images/icon-general.svg";
import { ReactComponent as IconCardReportDecorative } from "../../assets/images/icon-card-report-decorative.svg";
import { ReactComponent as IconHome } from "../../assets/images/icon-home.svg";
import { ReactComponent as IconClients } from "../../assets/images/icon-clients.svg";
import { ReactComponent as IconBilling } from "../../assets/images/icon-billing.svg";
import { ReactComponent as IconTariffs } from "../../assets/images/icon-tariffs.svg";
import { ReactComponent as IconRoles } from "../../assets/images/icon-roles.svg";
import { ReactComponent as IconLeads } from "../../assets/images/icon-leads.svg";
import { ReactComponent as IconGlobalData } from "../../assets/images/icon-global-data.svg";
import { ReactComponent as IconSettings } from "../../assets/images/icon-settings.svg";
import { ReactComponent as IconServices } from "../../assets/images/icon-services.svg";
import { ReactComponent as IconTestimonials } from "../../assets/images/icon-testimonials.svg";
import { ReactComponent as IconFuncionalities } from "../../assets/images/icon-funcionalities.svg";
import { ReactComponent as IconLegal } from "../../assets/images/icon-legal.svg";
import { ReactComponent as IconAboutUs } from "../../assets/images/icon-about-us.svg";
import { ReactComponent as IconEyeGray } from "../../assets/images/eye-gray.svg";
import { ReactComponent as Search } from "../../assets/images/search.svg";
export interface iIconProps {
  name: string;
  [key: string]: any;
}
const IMAGES: any = {
  IconTrash,
  IconDoubleFill,
  IconWarningYellow,
  IconInfoBlue,
  IconCloseGrey,
  IconDownload,
  IconMask,
  IconUp,
  IconArrowLeft,
  IconArrowRight,
  IconInfoGray,
  IconCross,
  IconExclamation,
  IconMoney,
  IconDown,
  IconCheckCircle,
  IconXCircle,
  IconCheck,
  ImageNotFound,
  IconReportGeneral,
  IconCardReportDecorative,
  IconHome,
  IconClients,
  IconBilling,
  IconTariffs,
  IconRoles,
  IconLeads,
  IconGlobalData,
  IconSettings,
  IconServices,
  IconTestimonials,
  IconFuncionalities,
  IconLegal,
  IconAboutUs,
  IconEyeGray,
  Search
};


const IconComponent: React.FC<iIconProps> = ({ name, ...props }) => {
  let Icon = IMAGES[name];
  return <Icon {...props} />;
};

export default IconComponent;