import axios from "axios";
import Cookie from "js-cookie";
import { SERVICES_URL } from "./url";

export const get = async (args: any) => {
  args.setLoading(args.loader);
  try {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: Cookie.get("accessToken")
        ? `Bearer ${Cookie.get("accessToken")}`
        : ""
    };
    const response = await axios({
      method: "GET",
      url: SERVICES_URL + args.endpoint,
      headers
    });
    args.setLoading(false);
    return response;
  } catch (err) {
    args.setLoading(false);
    handleError(err.response);
    throw err;
  }
};

export const post = async (args: any) => {
  args.setLoading(args.loader);
  try {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: Cookie.get("accessToken")
        ? `Bearer ${Cookie.get("accessToken")}`
        : ""
    };
    const response = await axios({
      method: "POST",
      url: SERVICES_URL + args.endpoint,
      headers,
      data: JSON.stringify(args.data)
    });
    args.setLoading(false);
    return response;
  } catch (err) {
    args.setLoading(false);
    handleError(err.response, args.endpoint.indexOf("login") > -1);
    throw err;
  }
};

export const postFormData = async (args: any) => {
  args.setLoading(args.loader);
  try {
    const headers = {
      Accept: "multipart/form-data",
      "Content-Type": "multipart/form-data",
      Authorization: Cookie.get("accessToken")
        ? `Bearer ${Cookie.get("accessToken")}`
        : ""
    };
    const response = await axios({
      method: "POST",
      url: SERVICES_URL + args.endpoint,
      headers,
      data: args.data
    });
    args.setLoading(false);
    return response;
  } catch (err) {
    args.setLoading(false);
    handleError(err.response);
    throw err;
  }
};

export const putFormData = async (args: any) => {
  args.setLoading(args.loader);
  try {
    const headers = {
      Accept: "multipart/form-data",
      "Content-Type": "multipart/form-data",
      Authorization: Cookie.get("accessToken")
        ? `Bearer ${Cookie.get("accessToken")}`
        : ""
    };
    const response = await axios({
      method: "PUT",
      url: SERVICES_URL + args.endpoint,
      headers,
      data: args.data
    });
    args.setLoading(false);
    return response;
  } catch (err) {
    args.setLoading(false);
    handleError(err.response);
    throw err;
  }
};

export const patchFormData = async (args: any) => {
  args.setLoading(args.loader);
  try {
    const headers = {
      Accept: "multipart/form-data",
      "Content-Type": "multipart/form-data",
      Authorization: Cookie.get("accessToken")
        ? `Bearer ${Cookie.get("accessToken")}`
        : ""
    };
    const response = await axios({
      method: "PATCH",
      url: SERVICES_URL + args.endpoint,
      headers,
      data: args.data
    });
    args.setLoading(false);
    return response;
  } catch (err) {
    args.setLoading(false);
    handleError(err.response);
    throw err;
  }
};

export const patch = async (args: any) => {
  args.setLoading(args.loader);
  try {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: Cookie.get("accessToken")
        ? `Bearer ${Cookie.get("accessToken")}`
        : ""
    };
    const response = await axios({
      method: "PATCH",
      url: SERVICES_URL + args.endpoint,
      headers,
      data: JSON.stringify(args.data)
    });
    args.setLoading(false);
    return response;
  } catch (err) {
    args.setLoading(false);
    handleError(err.response);
    throw err;
  }
};

export const put = async (args: any) => {
  args.setLoading(args.loader);
  try {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: Cookie.get("accessToken")
        ? `Bearer ${Cookie.get("accessToken")}`
        : ""
    };
    const response = await axios({
      method: "PUT",
      url: SERVICES_URL + args.endpoint,
      headers,
      data: JSON.stringify(args.data)
    });
    args.setLoading(false);
    return response;
  } catch (err) {
    args.setLoading(false);
    handleError(err.response);
    throw err;
  }
};

export const deleteReq = async (args: any) => {
  args.setLoading(args.loader);
  try {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: Cookie.get("accessToken")
        ? `Bearer ${Cookie.get("accessToken")}`
        : ""
    };
    const response = await axios({
      method: "DELETE",
      url: SERVICES_URL + args.endpoint,
      headers,
      data: args.data
    });
    args.setLoading(false);
    return response;
  } catch (err) {
    args.setLoading(false);
    handleError(err.response);
    throw err;
  }
};

const handleError = (err: any, login?: boolean) => {
  if (!Cookie.get("accessToken")) {
    return;
  }

  if (err.status === 403 || (err.status === 401 && !login)) {
    Cookie.remove("accessToken");
    Cookie.remove("idUser");
    window.location.href = window.location.origin;
  }
};
