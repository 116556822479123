import React from 'react';

//Type
import {IMapCardIndicator} from './MapBarIndicator-type';

//Style
import MapBarIndicatorContainer from './MapBarIndicator-style';

const MapBarIndicator: React.FC<IMapCardIndicator> = (props: IMapCardIndicator) => {
  const { color } = props;

  return (
    <MapBarIndicatorContainer color={color}>

      <div id='map-indicator-bar'>
        <div id='bar-indicator'>
          <p className='indicator-top-text'>100.000</p>
          <p className='indicator-bottom-text'>0</p>
        </div>
      </div>

    </MapBarIndicatorContainer>
  )
  
}

export default MapBarIndicator