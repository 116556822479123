import * as dateFns from 'date-fns';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import ca from 'date-fns/locale/ca';
import moment from 'moment';

const locales = { es, en, ca };

export const formatDateToHHMMSS = (date) => {
  return moment().startOf('day')
    .seconds(Number(date))
    .format('HH:mm:ss');
}

export const getDateFormat = (time, format, lang = 'es') => {
  return dateFns.format(time, format, { locale: locales[lang] });
}


export const getYearsSelect = (year) => {
  const currentYear = new Date().getFullYear(), years = [];
  year = year || 1980;
  while (year <= currentYear) {
    const value = year++;
    years.push({ label: value.toString(), value: value.toString() });
  }
  return years;
}

export const startOfYear = (time) => dateFns.startOfYear(time);

export const endOfYear = (time) => dateFns.endOfYear(time);

export const startOfMonth = (time) => dateFns.startOfMonth(time);

export const endOfMonth = (time) => dateFns.endOfMonth(time);

export const startOfWeek = (time) => dateFns.startOfWeek(time);

export const endOfWeek = (time) => dateFns.endOfWeek(time);


export const addWeeks = (time, amount) => dateFns.addWeeks(time, amount);
export const addYears = (time, amount) => dateFns.addYears(time, amount);
export const addDays = (time, amount) => dateFns.addDays(time, amount);
export const addMonths = (time, amount) => dateFns.addMonths(time, amount);

export const compareDate = (time, time2) => dateFns.compareAsc(time, time2);

export const differenceDate = (time, time2) => dateFns.differenceInCalendarDays(time, time2);
export const differenceWeek = (time, time2) => dateFns.differenceInCalendarWeeks(time, time2);
export const differenceMonth = (time, time2) => dateFns.differenceInCalendarMonths(time, time2);
