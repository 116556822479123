import React from 'react';

import { CardContainer } from './card-style';

interface iCardsProps {
  text: any;
  textFooter: string;
  handleClick: Function;
  width?: string;
  className?: string;
}

const CardAlert: React.FC<iCardsProps> = ({ text, textFooter, handleClick, width, className }) => {

  return (
    <CardContainer className={className} width={width}>
      <div className="card-body">
        {text}
      </div>
      <div className="card-footer" onClick={() => handleClick()}>
        <p>{textFooter}</p>
      </div>
    </CardContainer>
  );
}

export default CardAlert;
