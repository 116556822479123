import React, { useState, useContext, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router';

import { getSubscriptions } from '../../services/subscription.services';

import GeneralContext from '../../context/general.context'

import TopBar from '../../components/TopBar/TopBar';
import IconComponent from '../../components/Icon/Icon';
import CardRate from '../../components/CardRate/CardRate';

import * as uik from '../../@uik';

import { iSubscriptions } from '../../models/subscriptions.models';
import { numberFormat } from '../../utils/text';

export interface iSubscriptionsProps extends RouteComponentProps { }

const Subscriptions: React.FC<iSubscriptionsProps> = ({ history }) => {

  const [items, setItems] = useState<iSubscriptions[]>([]);
  const [section, setSection] = useState<string>('mensual');

  const [reloadItems, setReloadItems] = useState<boolean>(true);

  const general = useContext(GeneralContext);


  useEffect(() => {
    if (reloadItems) {
      getItems();
      setReloadItems(false);
    }
  }, [reloadItems]);

  const getItems = async () => {
    const items = await getSubscriptions({
      loader: true,
      setLoading: general.setLoading
    });
    items.sort((a: any, b: any) => {
      return a.minPoblation - b.minPoblation;
    });
    setItems(items);
  }

  const { UikTopBarSection, UikTopBarTitle, UikContainerVertical, UikLayoutMain,
    UikContentItem } = uik;

  return (
    <UikContainerVertical className={"page"}>
      <TopBar
        handleButton={items.length < 4 ? () => history.push('/subscriptions/create') : undefined}
        textButton={items.length < 4 ? "Crear" : undefined}
      >
        <UikTopBarSection>
          <UikTopBarTitle>
            <IconComponent name="IconTariffs" className={"svg-black"} />
            Tarifas
        </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <UikContentItem>
          <div className="grid mt-20">
            <div className="col-12">
              <div className="navigation">
                <div
                  className={`item${section === 'mensual' ? ' active' : ''}`}
                  onClick={() => setSection('mensual')}
                >
                  Mensual
              </div>
                <div
                  className={`item${section === 'anual' ? ' active' : ''}`}
                  onClick={() => setSection('anual')}
                >
                  Anual
              </div>
              </div>
            </div>
            {items.map((item: iSubscriptions) => (
              <div className="col-3" key={`rate-${item.id}`}>
                <CardRate
                  title={item.name}
                  text={`Perfecta para pueblos entre ${numberFormat(item.minPoblation)} y ${numberFormat(item.maxPoblation)} habitantes`}
                  prices={'anual' === section ? [item.priceAnual] : [item.priceMonthly]}
                  type={section === 'mensual' ? 'mes' : 'año'}
                  active={false}
                  data={item.tags.map((tag: string) => ({ value: tag, active: true }))}
                  // buttonText={"Actualizar"}
                  handleClick={() => history.push(`/subscriptions/edit/${item.id}`)}
                />
              </div>

            ))}
          </div>
        </UikContentItem>

      </UikLayoutMain>
    </UikContainerVertical>
  );
}




export default withRouter(Subscriptions);

